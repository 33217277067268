import Test from './test';
import EditorDraftStore from './editorDraft'
import HotDataStore from './hotDataStore'
import SearchStore from './searchStore'
import HomeStore from './homeStore'
import userStore from './userStore'
import EditorStore from './editorStore'
import OverSeaStore from './overSeaStore'
import ArticleStore from './articleStore'
import DataSourcesStore from './dataSourcesStore'
import SendStore from './sendStore'
import asStore from './audioSplitStore'
export default {
    testStore: Test,
    EditorDraftStore,
    HotDataStore,
    SearchStore,
    HomeStore,
    userStore,
    EditorStore,
    OverSeaStore,
    ArticleStore,
    DataSourcesStore,
    SendStore,
    asStore
}