import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    render() {
        return(
            <div className="animation_txt_main">
                  
                    {
                        this.props.txt && this.props.txt.split('').map((item, index) => {
                            let delay = Math.floor((Math.random() * 1000) + 1);
                            return <span
                                style={{
                                    animationDelay:`${delay}ms`
                                }}
                                className="fancy_span"
                                key={index}
                            >
                                {item}
                            </span>
                        })
                    }
               
            </div>
        )
    }
}
export default Index