import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    render() {
        return(
            <div 
                className="img_txt_title"
            >
               <img src={this.props.imgTitle}/>
               {this.props.imgTxt}
            </div>
        )
    }
}
export default Index