import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
import index from '../loading';
@inject("store")
@observer
class Index extends React.Component{
    state = {
        words: [1,1,1,1,1,11,1,1,1,1],
        filters: ['文章', '段落'],
        activeFilterIndex: 0,
        activeKeyWordIndex: 0
    }
    render() {
        const {SearchStore} = this.props.store
        return(
            <div 
            className="filter_keys_box"
            >
               <div className="keys_box_main">
                    {/* 筛选条件 */}
                    {/* <div className="main_filter_condition">
                        {
                           this.props.wordsFilters.map((item, index) => {
                                return <div
                                    className="condition_box"
                                    key={`condition_box${index}`}
                                    style={
                                        index == 0 ?
                                        {borderBottom: 'solid 1.8px red'} :
                                        {}
                                    }
                                >
                                    {item.tab}
                                </div>
                            })
                        }
                    </div> */}
                    {/* 筛选后的结果 */}
                    <div className="main_filter_result">
                        {
                            this.props.wordsFilters && 
                            this.props.wordsFilters[this.state.activeFilterIndex] &&
                            this.props.wordsFilters[this.state.activeFilterIndex]['keywords'] && 
                            this.props.wordsFilters[this.state.activeFilterIndex]['keywords'].map((item, index) => {
                                return (
                                    <div 
                                        className="result_word_box"
                                        key={`result_word${index}`}
                                        style={
                                            this.props.isShowAllBtn ?
                                            (
                                                index == 0
                                                ? {border:'solid 1px #ED6146', color:'#ED6146'} : {}
                                            ) :
                                            (
                                                this.state.activeKeyWordIndex == index ?
                                                {border:'solid 1px #ED6146', color:'#ED6146'} :
                                                {}
                                            )
                                            
                                        }
                                        onClick={() => {
                                            SearchStore.getFilterDatas(item)
                                            this.setState({
                                                activeKeyWordIndex: index
                                            })
                                        }}
                                    >
                                       {item}
                                    </div>
                                )
                            })
                        }
                    </div>
               </div>
            </div>
        )
    }
}
export default Index