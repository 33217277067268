import {observable, action} from 'mobx';
import api from '../utils/Api'
// 热点发现
class HotData{
    @observable hotList = []// 热门事件list
    @observable hotTypeList = []// 分类推荐list
    @observable hotTypePageAll = 0
    @observable hotTypeFilterName = ''
    @observable hotPageNum = 1
    @observable hotLoading = false
    @observable hotListLoading = false
    @observable hotTypes = [
        '国际','体育','娱乐', '社会','财经','时事', '科技', '情感', '汽车', '教育', '时尚', '游戏', '军事', '旅游', '美食', '文化', '健康养生', '搞笑', '家居', '动漫', '宠物', '母婴育儿', '星座运势', '历史', '音乐', '综合'
    ]
    @observable hotTypesUser = [
        '动漫','体育','娱乐', '社会'
    ]
    // 热门事件
    @action getHotList(){
        this.hotLoading = true
        api.getHotList('https://www.vulcandata.cn/data/v/rank').then((data) => {
            if(data.code == 200){
                this.hotList = data.data
                this.hotLoading = false
                this.compareDatas(data.data, 'hot')
            }
        })
    }
    // 分类推荐_指定页码
    @action getHotTypeList(pageNum = 1, typeName = '全部'){
        this.hotListLoading = true
        
        api.getHotList(`https://www.vulcandata.cn/data/v/article/${pageNum}/6?category=${typeName}`).then((data) => {
            if(data.code == 200){
                this.hotTypeFilterName = typeName || '全部'
                this.hotPageNum = pageNum
               this.hotTypeList = data.data.list
               this.hotListLoading = false
            //    callback && callback()
                var allPage =  data.data.total && parseInt(data.data.total/6)
                this.hotTypePageAll = allPage > 30 ? 30 : allPage
                // console.log(this.hotTypePageAll)
            }
        })
    }
    // 热点数据-标题点击事件
    @action clickHotTitle(word = ''){
        sessionStorage.setItem("title_to", 'true')
        sessionStorage.setItem("title_word", word)
        // window.location.href = '/editor.html'
    }
    compareDatas(data, value){
        data = data.sort((a, b) => {
            return  b[value] - a[value]
        })
        this.hotList = data
        // console.log(this.hotList)
        
    }
    // 7*24h——财经新闻
    @observable news_24_list = [];
    @observable news_24_page = 1;//当前第几页
    @observable news_24_pageCount = 30;//每夜数据个数
    @observable news_24_type = "";//当前选择分类
    @observable news_24_typesData = [];
    //加载分类
    @action async news_24_loadTypes(token){
        let result = await api.channels7_24(token);
        if(result && 200 == result.code){
            this.news_24_typesData = result.data.sort((i, y)=>{
                return i.sort - y.sort > 0 ? 0 : -1
            });
            if(this.news_24_typesData.length > 0){
                this.news_24_typesData.map(item=>{
                    if(item.def){
                        this.news_24_type = item.id;
                        this.news_24_loadData(token)
                    } 
                }) 
            }
        }
        
    }
    //首次加载数据
    @action news_24_newTypeData(item, token){
        this.news_24_type = item.id;
        this.news_24_page = 1;
        this.news_24_loadData(token)
    }
    @action async news_24_loadData(token){
        let result = await api.list7_24(this.news_24_page, this.news_24_pageCount, this.news_24_type, token);
        if(result && 200 == result.code){
            this.news_24_list = result.data;
        }
    }
    //加载更多
    @action async news_24_moreData(token){
       
        let result = await api.list7_24(this.news_24_page + 1, this.news_24_pageCount, this.news_24_type, token);
        if(result && 200 == result.code){
            if(result.data.length > 0) this.news_24_page += 1;
            this.news_24_list = this.news_24_list.concat(result.data);   
        }
    }
}
const HotDataStore = new HotData();
export default HotDataStore