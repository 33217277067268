import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
import './audiosplit.css'
import './animation.css'
import Peaks from 'peaks.js'
import TimeLineCom from '../timeLineBox'
import LoadingFixedCom from '../loadingFixedBox'
import { millToSecond } from '../../utils/utils'
import AudioSplitVideoCom from '../audioSplitVideo'
@inject("store")
@observer

/**
 *  @description 拆条功能组件
 *  audioSplit = {
        id: '',//服务器 id 
        name: '', //文件名字
        src: '', //文件本地路径或服务器端路径
        segments: [], //用于记录本地 拆条 
    }
 */
class Index extends React.Component{
    state = {
        showLoading: false,
        peakLoading: false,
        segments: [],
        segmentsStatus: 0, //0球形态 1展开形态
        audioToTxtStatus: 0, // 0不展示文本弹窗 1-展开
        audioTxts: [],
        audioSrcUrl: null,
        activeAudioTabs: '音频片段'// 音频显示的tab
    }
    adapter = this.props.adapter
    
    audio = `audio${this.props.index}`
    zoomview_container = `zoomview-container${this.props.index}`
    overview_container = `overview-container${this.props.index}`
    instance = null
    myAudioContext = null
    render(){
        let file = this.adapter.audioData
        let fileID  = this.adapter.fileId
        let tagsListSplit = this.adapter.tagsList
        let name = this.adapter.audiofile.name
        return (
            <div className="audio_split_layout">
                <LoadingFixedCom show={this.state.showLoading || this.state.peakLoading}/>
                <div id="peak-container" style={{flex: 7, height:'170px'}}>
                    <div id={this.zoomview_container}></div>
                    <div id={this.overview_container}></div>
                    {
                        file ? 
                        <audio id={this.audio} className="allAudios">
                            <source src={file} type="audio/mp3" />
                        </audio> : ""
                    }
                </div>
                <div className="audio_split_controller">
                    <div style={{flex: 2}}>
                        文件名: {name}
                    </div>
                    <div>
                        当前状态: 正常
                    </div>
                    <div></div><div></div><div></div><div></div>
                    <div>
                        <span onClick={()=>this.addSegment()} style={{padding: 4}}>添加分段</span>
                    </div>
                </div>
                {/* 音频内容 */}
                <div className="split_com_audio_translate_txt">
                    <div className="txt_title">
                        <div className="title_left">添加音频</div>
                        <div className="title_center">
                            <div 
                                // className="title_content_active"
                                className={
                                    this.state.activeAudioTabs == '音频内容' ? 
                                    'title_content_active' : ''
                                }
                                onClick={() => {
                                    this.setState({
                                        activeAudioTabs: '音频内容'
                                    })
                                }}
                            >
                                音频内容
                            </div>
                            <div
                                className={
                                    this.state.activeAudioTabs == '音频片段' ? 
                                    'title_content_active' : ''
                                }
                                onClick={() => {
                                    this.setState({
                                        activeAudioTabs: '音频片段'
                                    })
                                }}
                            >
                                音频片段

                            </div>
                        </div>
                    </div>
                    {/* 音频内容 */}
                    <div className="txt_all_main">
                        {
                            this.state.activeAudioTabs == '音频片段' ?
                            <div className="txt_all_split_main">
                                {
                                    tagsListSplit.map((item, index) => {
                                        return <div
                                            className="txt_all_split_list"
                                            key={`txt_all_split_list${index}`}
                                        >
                                          {item.tag.labelText}
                                        </div>
                                    })
                                }
                            </div> :
                            this.state.audioTxts.map((item, index) => {
                                return <div
                                    className="txt_all_list"
                                    key={`txt_all_list${index}`}
                                >
                                    <TimeLineCom
                                        item={item.text}
                                        txtId = {`addTxt${fileID}${index}`}
                                        tagList={this.adapter.tagsList}
                                        beginTime={millToSecond(Math.round(item.begin_time/1000))}
                                        endTime={millToSecond(Math.round(item.end_time/1000))}
                                        addTagTxt={
                                            () => {
                                                this.adapter.splitAddAudioTxt(item, fileID, index);// 单个
                                                this.props.addTagTxts && this.props.addTagTxts(item, fileID);// 总
                                            }
                                        }
                                        deleteTagTxt={
                                            () => {
                                                this.adapter.splitDeletaTag(`addTxt${fileID}${index}`);
                                                this.props.deleteSegment && this.props.deleteSegment(item);
                                            }
                                        }
                                    />
                                </div>
                            })
                        }
                    </div>

                    {/* 音频片段 */}

                </div>

                {/* 播放按钮 */}
                <div className="split_com_audio_play_btn">
                        {/* <audio  
                        className="play_audio_mp3" 
                        src={this.state.audioSrcUrl}
                        controls
                        // ref={ref => this.audioSplit = ref}
                        /> */}
                        <AudioSplitVideoCom
                            srcSplit={this.state.audioSrcUrl}
                        />
                    </div>
                {/* 添加分段之后的显示的球状 */}
                {
                    this.state.segments.length > 0 ? this.renderSegmentsView() : ""
                }
            </div>
        )
    }
    renderSegmentsView(){
        return this.state.segmentsStatus == 0 ? this.renderSegmentsMenuView() : this.renderSegmentsShowView();
    }

    renderSegmentsMenuView(){
        return <div className="segmentsMenuView" onClick={()=>this.setState({segmentsStatus: 1})}>
            {this.state.segments.length}
        </div>
    }
    renderSegmentsShowView(){
        return <div className="audio_split_segments_layout">
            <div onClick={()=>this.setState({
                segmentsStatus: 0,
            })}>关闭</div>
            <div className="audio_split_segments_content">
                {
                    this.renderSegmentsRowView(0, '开始时间', '结束时间', '文字内容', '操作', false)
                }
                {
                    this.state.segments.map((item, index) => {
                        let start = parseFloat(item.startTime).toFixed(2)
                        let end = parseFloat(item.endTime).toFixed(2)
                        return this.renderSegmentsRowView(index + 1, start, end, item.labelText , this.renderSegmentsRowViewAction(item, index))
                    })
                }
            </div>
        </div>
    }
    
    renderSegmentsRowView(index, index0, index1, index2, Component, flag = true){
        return <div key={`999` + index} className="segmentsRowView" style={{padding: '4px 0px'}}>                    
                    <div style={{flex: 2}}>{flag?parseFloat(index0).toFixed(2):index0}</div>
                    <div style={{flex: 2}}>{flag?parseFloat(index1).toFixed(2):index1}</div>
                    <div style={{flex: 6}}>{index2?index2:""}</div>
                    <div style={{flex: 6}}>{Component}</div>
                </div>
    }
    renderSegmentsRowViewAction(item, index){
        return <div key={`999989` + index} className="segmentsRowView">
            <div onClick={()=>this.playSegment(item)} style={{flex: 1}}>播放</div>
            <div 
                onClick={()=>{
                    item.update({editable: false});
                    this.props.segments && this.props.segments(item, this.adapter.fileId);
                    this.adapter.splitAddTag(item, this.adapter.fileId, index)
                }} 
                style={{flex: 1}}
            >
                添加到合成列表
            </div>
            <div 
                onClick={()=>{
                    this.deleteSegment(item);
                    this.props.deleteSegment && this.props.deleteSegment(item);
                    this.adapter.splitDeletaTag(`splitTag${this.adapter.fileId + index}`)
                }} 
                style={{flex: 1}}
            >
                删除
            </div>
        </div>
    }
    componentDidMount() {
        
        const that = this
        if(this.adapter){
            if(this.adapter.instance != null){
                // 第二次进入页面
                that.setState({
                    audioTxts: this.adapter.audioToTxts,
                    // segments: this.adapter.instance.segments.getSegments(),
                    peakLoading: true,
                    audioSrcUrl: this.adapter.fileUrl
                })
                // that.instance = this.adapter.instance
            } else {
                // 第一次进入页面
                that.setState({
                    showLoading: true
                })
            }

            setTimeout(() => {
                that.initPeak()
                // 成功上传文件，文件转为汉字回调函数
                that.adapter.getStatus = ()=>{
                    that.setState({
                        audioTxts: this.adapter.audioToTxts,
                        audioSrcUrl: this.adapter.fileUrl,
                        showLoading: false
                    })
        
                }
            }, 500)
            
        }
    }
    // 第二次进入初始化
    // initPeakTwo(){
    //     this.instance = this.adapter.instance
    // }
    initPeak(){
        if (this.instance) this.instance.destroy();
        this.instance = Peaks.init(this.initOptions(), () => {
            this.adapter.setPeakInstance(this.instance)
            this.setState({
                peakLoading: false
            })
        });
        
    }
    initOptions() {
        let AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
        this.myAudioContext = new AudioContext();
        const options = {
            containers: {
                overview: document.getElementById(this.overview_container),
                zoomview: document.getElementById(this.zoomview_container)
            },
            mediaElement: document.getElementById(this.audio),
            audioContext: this.myAudioContext,
            height: 80,
        };
        // console.log(options)
        return options;
    }
    // 添加分段
    addSegment(startTime = 5.0, endTime = 15.0, labelText = null, editable = true){
        this.instance.segments.add({
            startTime: startTime,
            endTime: endTime,
            editable: editable,
            labelText: labelText ? labelText : `${this.adapter.audiofile.name}第${this.adapter.getSegments().length}片段`
        });

        this.setState({
            segments: this.instance.segments.getSegments()
        })
        // 同比
        this.adapter.setPeakInstance(this.instance)
    }
    // 播放
    playSegment(segment){
        this.instance.player.playSegment(segment)
    }
    // 删除
    deleteSegment(segment){
        this.instance.segments.removeById(segment.id);
        const segments = this.instance.segments.getSegments();
        
        //如果集合里面没有了，那么重置状态
        if(segments.length == 0){
            this.setState({
                segments: segments,
                segmentsStatus: 0
            })
        }else{
            this.setState({
                segments: segments,
            })
        }
    }
    // 播放器的快进后退
}
 
export default Index