import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import MaskCom from '../maskBox'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state={
        isOpenFankui: false,
        feedbackImg: '',
        feedbackTxt: '',
        feedbackTitle: '',
        isOpenUserServer: false,
        userServerTxt: ''
    }
    render() {
        return(
            <div 
                className="vulcan_bottom"
            >
                {
                    this.renderCustomerXieYi()
                }
                {
                    this.renderCustomerFeedback(
                        this.state.feedbackImg,
                        this.state.feedbackTitle,
                        this.state.feedbackTxt,

                    )
                }
                {/* 引入动画 */}
                <div className="header">
                    <div className="inner-header flex">
                        {
                            this.renderLiContent()
                        }
                    </div>

                    <div>
                        <svg className="waves"
                        viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                        </g>
                        </svg>
                    </div>


                </div>
            </div>
        )
    }
    renderLiContent(){
        const _that = this
        return(
            <div className="render_li_content">
                <div className="li_main">
                    <div className="main_left">
                            <div className="top_two">
                                <div
                                    
                                >联系我们</div>
                                <div>
                                    <img 
                                        src={ImageSet.wxLight}
                                        onClick={() => {
                                            _that.setState({
                                                isOpenFankui: true,
                                                feedbackImg: ImageSet.erWei,
                                                feedbackTitle: '俯瞰公众账号',
                                                feedbackTxt: ' 您可以扫描二维码关注俯瞰服务号，来对我们的产品进行反馈！'
                                            })
                                        }}
                                    />
                                    <img 
                                        src={ImageSet.qq}
                                        onClick={() => {
                                            _that.setState({
                                                isOpenFankui: true,
                                                feedbackImg: ImageSet.qqEr,
                                                feedbackTitle: '俯瞰QQ账号',
                                                feedbackTxt: ' 您可以扫描二维码添加俯瞰QQ，来对我们的产品进行反馈！'
                                            })
                                        }}
                                    />
                                    <img 
                                        src={ImageSet.weibo}
                                        onClick={() => {
                                            _that.setState({
                                                isOpenFankui: true,
                                                feedbackImg: ImageSet.weiboEr,
                                                feedbackTitle: '俯瞰微博账号',
                                                feedbackTxt: ' 您可以扫描二维码关注俯瞰微博，来对我们的产品进行反馈！'
                                            })
                                        }}
                                    />
                                </div>
                                <div>
                                邮箱：contact@crgood.com
                                </div>
                                <div>
                                电话：+86-10-67294760
                                </div>
                            </div>
                    </div>
                    <div className="main_mid">
                            <div className="top_one">
                                <div>使用教程</div>
                                <div>产品说明</div>
                                <div
                                onClick={() => {
                                    _that.setState({
                                        isOpenUserServer: true,
                                        userServerTxt: 'Vulcan俯瞰'
                                    })
                                }}
                                >关于我们</div>
                                <div
                                     onClick={() => {
                                        _that.setState({
                                            isOpenUserServer: true,
                                            userServerTxt: 'Vulcan 用户服务协议'
                                        })
                                    }}
                                >用户协议</div>
                                <div>会员协议</div>
                                <div
                                    onClick={() => {
                                        _that.setState({
                                            isOpenFankui: true,
                                            feedbackImg: ImageSet.erWei,
                                            feedbackTitle: '俯瞰公众账号',
                                            feedbackTxt: ' 您可以扫描二维码关注俯瞰服务号，来对我们的产品进行反馈！'
                                        })
                                    }}
                                >联系我们</div>
                            </div>
                            <div className="info_mid">
                                京ICP备16061934号-2 COPYRIGHT 2019 优权互联 版权所有。
                            </div>
                            <div className="info_bottom">
                                <div style={{fontSize:'14px'}}>友情链接：</div>
                                <div>经济之声</div>
                                <div>中译语通</div>
                                <div>郑州商品交易所</div>
                                <div>永安期货</div>
                            </div>

                    </div>
                    <div className="main_right">
                            <div className="top_three">
                                <img src={ImageSet.erWei}/>
                                <div style={{marginTop:'10px'}}>
                                俯瞰Vulcan微信公众号
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
    renderContent(){
        return (
            <div className="vulcan_bottom">
                    <div className="vulcan_main">
                    <div className="bottom_vulcan_logo">
                        <img src={ImageSet.logo}/>
                    </div>
                    <div style={{width:'20%'}}></div>
                    <div className="bottom_vulcan_infos">
                        <div className="info_top">
                            <div className="top_one">
                                <div>使用教程</div>
                                <div>产品说明</div>
                                <div>关于我们</div>
                                <div>用户协议</div>
                                <div>会员协议</div>
                                <div>联系我们</div>
                            </div>
                            <div className="top_two">
                                <div>联系我们</div>
                                <div>
                                    <img src={ImageSet.wx}/>
                                    <img src={ImageSet.wx}/>
                                    <img src={ImageSet.wx}/>
                                </div>
                                <div>
                                邮箱：contact@crgood.com
                                </div>
                                <div>
                                电话：+86-10-67294760
                                </div>
                            </div>
                            <div className="top_three">
                                <img src={ImageSet.type10}/>
                                <div>
                                俯瞰Vulcan微信公众号
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vulcan_others">
                        <div className="info_mid">
                        京ICP备16061934号-2 COPYRIGHT 2019 优权互联 版权所有.
                        </div>
                        <div className="info_bottom">
                            <div style={{fontSize:'14px'}}>友情链接：</div>
                            <div>经济之声</div>
                            <div>中译语通</div>
                            <div>郑州商品交易所</div>
                            <div>永安期货</div>
                        </div>
                </div>
            </div>
        )
    }
    renderCustomerFeedback(img, title, txt){
        const that = this
        return <MaskCom show={this.state.isOpenFankui}>
                <div className="render_customer_feedback">
                <div className="customer_feedback_close">
                    <img 
                            src={ImageSet.closeBlack} 
                            style={{width:'10px'}}
                            onClick={() => {
                                that.setState({
                                    isOpenFankui: false
                                })
                            }}
                    />
                </div>
                <div className="customer_feedback_main">
                    <img src={img} className="feedback_main_er"/>
                    <div className="feedback_main_content">
                        <div className="feedback_main_title">
                        
                        {title}
                        </div>
                        <div className="feedback_main_txt">
                            {txt}
                       
                                
                        </div>
                    </div>
                </div>
                </div>
            </MaskCom>
    }
    renderCustomerXieYi(){
        const that = this
        return <MaskCom show={that.state.isOpenUserServer}>
                <div className="user_info_main">
                        <div className="user_infos_title">
                        <div className="title_txt">{that.state.userServerTxt}</div>
                            <div 
                            className="title_close_btn"
                            onClick={() => {
                                that.setState({
                                    isOpenUserServer: false
                                })
                            }}
                            >关闭</div>
                        </div>
                        <div className="user_infos_content">
                            {
                                that.state.userServerTxt == 'Vulcan隐私条款' ?
                                that.rednerPriv() :
                                (
                                    that.state.userServerTxt == 'Vulcan俯瞰'?
                                     that.renderVulcan():
                                     that.renderUser()
                                )
                            }
                        </div>
                    </div>
        </MaskCom>
    }
    rednerPriv(){
        return(
            <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: 'center', fontSize: 16, color:'#333'}}>
               {/* <div style={{fontSize: 20}}>隐私条款</div> */}
               <p >
               北京优权互联网络科技有限公司(以下简称“优权互联”)尊重并保护所有使用其产品与服务的用户个人隐私权。为了给您提供更准确、更有个性化的服务，优权互联会按照本隐私权政策的规定使用和披露您的个人信息。但优权互联将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，优权互联不会将这些信息对外披露或向第三方提供。 优权互联会不时更新本隐私权政策。 您在同意优权互联服务协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于优权互联服务协议不可分割的一部分。
                <br/>
                适用范围
                <br/> <br/>
                在您注册Vulcan账户时，您根据优权互联要求提供的个人或组织注册信息；
                <br/> <br/>
                在您使用本网站服务，或访问本网站页时，优权互联自动接收并记录有利于保护您账号安全的安全风控的相关信息，包括但不限于您的使用记录、行为数据等；
                <br/> <br/>
                您了解并同意，以下信息不适用本隐私权政策：
                <br/> <br/>
                您在使用本网站提供的服务时，对外公布的信息；
                <br/> <br/>
                信用评价、违反法律规定或违反优权互联规则行为及优权互联已对您采取的措施。
                <br/> <br/>
                信息使用
                <br/> <br/>
                优权互联不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和优权互联（含优权互联关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料；
                <br/> <br/>
                优权互联亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何用户如从事上述活动，一经发现，优权互联有权立即终止与该用户的服务协议；
                <br/> <br/>
                该用户的服务协议；
                <br/> <br/>
                为服务用户的目的，优权互联可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与优权互联合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
                <br/> <br/>
                信息披露
                <br/> <br/>
                在如下情况下，优权互联将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
                经您事先同意，向第三方披露；
                <br/> <br/>
                如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
                <br/> <br/>
                根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
                如您出现违反中国有关法律、法规或者优权互联服务协议或相关规则的情况，需要向第三方披露；
                <br/> <br/>
                为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；
                <br/> <br/>
                在本网站上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，优权互联有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决；
                <br/> <br/>
                其它优权互联根据法律、法规或者网站政策认为合适的披露。
                <br/> <br/>
                信息存储和交换
                <br/> <br/>
                优权互联收集的有关您的信息和资料将保存在优权互联及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或优权互联收集信息和资料所在地的境外并在境外被访问、存储和展示。
                <br/> <br/>
                Cookie的使用
                <br/> <br/>
                在您未拒绝接受Cookies的情况下，优权互联会在您的计算机上设定或取用Cookies，以便您能登录或使用依赖于Cookies的本网站服务或功能。优权互联使用Cookies可为您提供更加周到的个性化服务，包括推广服务；
                <br/> <br/>
                您有权选择接受或拒绝接受Cookies。您可以通过修改浏览器设置的方式拒绝接受Cookies。但如果您选择拒绝接受Cookies，则您可能无法登录或使用依赖于Cookies的本网站服务或功能；
                <br/> <br/>
                通过优权互联所设Cookies所取得的有关信息，将适用本政策。
                <br/> <br/>
                信息安全
                <br/> <br/>
                Vulcan账户有安全保护功能，请妥善保管您的账户及密码信息。优权互联将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在绝对完善的安全措施；
                <br/> <br/>
                在使用本网站服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是Vulcan账户及密码发生泄露，请您立即联络优权互联客服，以便优权互联采取相应措施。
                <br/> <br/>
                数据安全
                <br/> <br/>
                优权互联不对您在本产品或服务中相关数据的删除或因个人原因导致的储存失败负责；
                <br/> <br/>
                优权互联有权根据实际情况自行决定您在本产品或服务中数据的最长储存期限、服务器中数据的最大存储空间等，您可根据自己的需要自行备份本产品或服务中的相关数据。双方另有约定的按相应约定执行；
                <br/> <br/>
                如果您停止使用本产品或服务被终止或取消，优权互联可以从服务器上永久地删除您的数据。您的产品或服务停止、终止或取消后，优权互联没有义务向您返还任何数据。
                <br/> <br/>
                未成年人的特别注意事项
                <br/> <br/>
                如果您不是具备完全民事权利能力和完全民事行为能力的自然人，您无权使用本网站服务，因此优权互联希望您不要向我们提供任何个人信息。
               </p>
            </div>
        )
    }

    renderUser(){
        return(
            <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: 'center',fontSize: 14, color:'#333'}}>
               {/* <div style={{fontSize: 20}}>Vulcan 用户服务器协议</div> */}
               <p>
               【导言】<br/>
               <br/>
                欢迎您使用优权互联产品及服务！<br/>
                <br/>
                本服务条款是Vulcan网站（vulcandata.cn以下称为“本网站”）的经营者北京优权互联网络科技有限公司（以下简称为“优权互联”），与用户（下称为“您”），共同缔结的对双方具有约束力的有效契约。 优权互联向用户提供本网站上所展示的产品与服务，并将不断更新服务内容。请您务必审慎阅读、充分理解以下各条款内容，并选择接受或不接受。<br/>
                <br/>
                如果您未满18周岁，请在法定监护人的陪同下阅读本协议。<br/>
                <br/>
                一、【总则】<br/>
                <br/>
                1.1 您确认：您在使用本产品或服务之前，已经充分阅读、理解并接受本服务条款的全部内容（特别是以加粗及/或下划线标注的内容），一旦您选择“同意”并完成注册流程或使用本服务，即表示您同意遵循本服务条款之所有约定。<br/>
                <br/>
                1.2 您同意：优权互联有权随时对本服务条款及相应的服务规则内容进行单方面的变更，并有权以消息推送、网页公告等方式予以公布，而无需另行单独通知您；若您在本服务条款内容公告变更后继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的条款内容使用本服务；若您不同意修改后的服务条款，您应立即停止使用本服务。 <br/>
                <br/>
                二、【账户】 <br/>
                <br/>
                2.1 注册<br/>
                <br/>
                2.1.1 注册者资格<br/>
                <br/>
                2.1.1.1 您确认，在您完成注册程序或以其他优权互联允许的方式实际使用本服务时，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织（以下统称为“法律主体”）。
                <br/><br/>
                2.1.1.2 若您是未成年人或限制民事行为能力人，则您不具备前述主体资格，您及您的监护人应承担因您的不当注册行为而导致的一切后果，且优权互联有权注销（永久冻结）您的账户，并向您及您的监护人索偿。
                <br/><br/>
                2.1.2 注册、账户和实名认证
                <br/><br/>
                在您按照注册页面提示填写信息、阅读并同意本服务条款且完成全部注册程序后，或在您按照激活页面提示填写信息、阅读并同意本服务条款且完成全部激活程序后，或您以其他优权互联允许的方式实际使用本网站服务时，您即受本服务条款约束。您可以使用您提供或确认的手机号码或者优权互联允许的其它方式作为登录手段进入本网站。
                <br/><br/>
                2.1.2.2 您了解并同意，您在本网站完成的注册程序并注册成功后，您即已获得Vulcan账户。
                <br/><br/>
                2.1.2.3 您了解并同意，为保证账户和交易安全，优权互联有权随时要求您完成优权互联账户的实名认证；同时，优权互联可能会就某些产品或服务的开通，要求您提供更多的身份资料和信息，做进一步的身份认证或资格验证，您的账户只有在通过这些认证和验证之后，方可获得使用相关产品或服务的资格。
                <br/><br/>
                2.1.2.4 通常情况下，您的Vulcan账户是您在本网站进行一切活动的唯一身份识别依据，每一个Vulcan账户都可以在本网站独立开展活动。但在下列情形下，优权互联有权根据自己的判断，对您的账户进行统一处理，包括但不限于：多个Vulcan账户之间存在一项或多项注册信息相同，并存在恶意欠费及/或违反法律法规、本服务条款、优权互联各产品条款或其他优权互联规则的行为，且优权互联通过结合其他相关证据足以判断上述账户实际属于同一法律主体或同一团体的；其他优权互联有充足理由需要对多个Vulcan账户进行统一处理的情形。
                <br/><br/>
                2.1.3 信息
                <br/><br/>
                2.1.3.1 在完成注册或激活流程时，您应当按照法律法规要求，按相应页面的提示准确提供并及时更新您的资料，以使之真实、及时，完整和准确。如有合理理由怀疑您提供的资料错误、不实、过时或不完整的，优权互联有权向您发出询问及/或要求改正的通知，并有权直接做出删除相应资料的处理，直至中止、终止对您提供部分或全部服务。优权互联对此不承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。
                <br/><br/>
                2.2 账户安全
                <br/><br/>
                2.2.1 您须自行负责对您的Vulcan账户和密码保密，且须对您在该登录名和密码下发生的所有活动（包括但不限于信息披露、发布信息、网上点击同意或提交各类规则协议、网上续签协议或购买服务等）承担责任。您同意：(a)如发现任何人未经授权使用您的Vulcan账户和密码，或发生违反保密规定的任何其他情况，您会立即通知优权互联；及(b)确保您在每个上网时段结束时，以正确步骤离开网站。优权互联不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。您理解优权互联对您的请求采取行动需要合理时间，优权互联对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。
                <br/><br/>
                2.2.2 您理解并同意，优权互联有权了解您使用本网站产品及服务的真实背景及目的，有权要求您如实提供真实、全面、准确的信息；如果优权互联有合理理由怀疑您提供的信息不真实、您进行虚假交易，或您的行为违反Vulcan的网站规则的，优权互联有权暂时或永久限制您账户下所使用的所有产品及/或服务的部分或全部功能。
                <br/><br/>
                2.2.3 您理解并同意，基于运行和交易安全的需要，优权互联有权暂时停止或者限制您账号下部分或全部的资金支付功能，将通过站内信、短信或电话等方式通知您，您应及时予以关注并按照程序进行申诉等后续操作。
                <br/><br/>
                2.2.4 您理解并同意，优权互联有权按照国家司法、行政、军事、安全等机关（包括但不限于公安机关、检察机关、法院、海关、税务机关、安全部门等）的要求对您的个人信息及在本网站的资金、交易及账户等进行查询、冻结或扣划。
                <br/><br/>
                3、产品与服务使用守则
                为有效保障您使用本公司提供的产品与服务的合法权益，您理解并同意接受以下规则：
                <br/><br/>
                3.1 您通过包括但不限于以下方式向Vulcan发出的指令，均视为您本人的指令，不可撤回或撤销，您应自行对Vulcan执行前述指令所产生的任何结果承担责任。
                <br/><br/>
                3.1.1 通过您的Vulcan账户和密码进行的所有操作；
                <br/><br/>
                3.1.2 通过与您的账号绑定的手机号码向Vulcan发送的全部信息；
                <br/><br/>
                3.1.3 通过与您的账号绑定的其他硬件、终端、软件、代号、编码、代码、其他账户名等有形体或无形体向Vulcan发送的信息；
                <br/><br/>
                3.1.4 其他优权互联与您约定或优权互联认可的其他方式。
                <br/><br/>
                3.2 您在使用本服务过程中，本服务条款内容、页面上出现的关于交易操作的提示或优权互联发送到您手机的信息（短信或电话等）内容是您使用本服务的相关规则，您使用本服务即表示您同意接受本服务的相关规则。您了解并同意优权互联有权单方修改服务的相关规则，而无须征得您的同意，服务规则应以您使用服务时的页面提示（或发送到该手机的短信或电话等）为准，您同意并遵照服务规则是您使用本服务的前提。
                <br/><br/>
                3.3  优权互联可能会以短信或电话等方式通知您服务进展情况以及提示您进行下一步的操作，但优权互联不保证您能够收到或者及时收到该邮件（或发送到该手机的短信或电话等），且不对此承担任何后果。因此，在服务过程中您应当及时登录到本网站查看和进行交易操作。因您没有及时查看和对服务状态进行修改或确认或未能提交相关申请而导致的任何纠纷或损失，优权互联不负任何责任。
                <br/><br/>
                3.4 您授权优权互联可以通过向第三方审核您的身份和资格，取得您使用本服务的相关资料。
                <br/><br/>
                3.5 在您开始使用优权互联的某一产品或服务前，可能需要和优权互联就这一产品或服务签订单独的服务协议。您只有在接受该服务协议的全部内容后方可使用该产品或服务；如您不同意该服务协议的部分或者全部的，请您不要进行后续操作。
                <br/><br/>
                3.6 在您使用优权互联服务时，优权互联有权依照相应的产品/及或服务收费介绍、订单及/或相关协议向您收取服务费用。优权互联拥有制订及调整服务费之权利，具体服务费用以您使用本服务时页面上所列之收费方式公告或您与优权互联达成的其他书面协议为准。
                <br/><br/>
                3.7 特别提示
                <br/><br/>
                3.7.1 您可以通过电脑、手机等终端以客户端、网页等形式使用本产品或服务，具体以优权互联提供的为准，同时，优权互联会不断丰富您使用本产品或服务的终端、形式等。当您使用时，您应选择与您的终端、App等相匹配的版本，否则，您可能无法正常使用本产品与服务。
                <br/><br/>
                3.7.2 为了改善用户体验，并保证本产品与服务的安全性和功能的一致性，优权互联有权不经向您特别通知而对产品及服务进行更新，或者对其部分功能效果进行改变或限制。
                <br/><br/>
                3.7.3 对于优权互联的客户端产品，再产品或服务新版本发布后，旧版本可能无法使用。优权互联不保证旧版本产品与服务可继续使用，请您随时核对并下载最新版本。
                <br/><br/>
                3.8 申诉及处理
                <br/><br/>
                3.8.1 在您使用优权互联服务的过程中，有可能因为存在本服务条款第5.3条所列情形之一，而被优权互联采取了包括但不限于停止全部或部分服务、限制服务的全部或部分功能、冻结全部或部分账户资金等措施，优权互联将通过站内信、短信或电话等方式通知您按照相应的程序进行申诉。
                <br/><br/>
                3.8.2 您通过申诉程序，向优权互联申请解除上述限制或冻结或恢复服务的，应按照优权互联的要求，如实提供身份证明及相关资料，以及优权互联要求的其他信息或文件，以便优权互联进行核实。您应充分理解您的申诉并不必然被允许，优权互联有权依照自行判断来决定是否同意您的申诉请求。
                <br/><br/>
                3.8.3 您理解并同意，如果您拒绝如实提供身份证明及相关资料的，或未能通过Vulcan审核的，优权互联有权长期冻结该等账户且长期限制该等产品或者服务的部分或全部功能。
                <br/><br/>
                3.9 关于第三方
                <br/><br/>
                3.9.1 如果您通过使用本服务，将获取使用来自第三方的任何产品或服务，您还可能受限于该等第三方的相关条款和条件，优权互联对此不予过问亦不承担任何责任，本服务条款不影响您与该第三方的法律关系。
                <br/><br/>
                4、 您的权利和义务
                <br/><br/>
                4.1 您有权利享受优权互联提供的互联网技术和信息服务，并有权利在接受优权互联提供的服务时获得优权互联的技术支持、咨询等服务，服务内容详见本网站相关产品介绍。
                <br/><br/>
                4.2 您保证不会利用技术或其他手段破坏或扰乱本网站及优权互联其他客户的网站。
                <br/><br/>
                4.3 您应尊重优权互联及其他第三方的知识产权和其他合法权利，并保证在发生侵犯上述权益的违法事件时尽力保护优权互联及其股东、雇员、合作伙伴等免于因该等事件受到影响或损失；优权互联保留您侵犯优权互联合法权益时终止向您提供服务并不退还任何款项的权利。
                <br/><br/>
                4.4 对由于您向优权互联提供的联络方式有误以及您用于接受优权互联通知的电话号码安全性、稳定性不佳而导致的一切后果，您应自行承担责任，包括但不限于因您未能及时收到优权互联的相关通知而导致的后果和损失。
                <br/><br/>
                4.5 您保证：
                <br/><br/>
                4.5.1 您使用Vulcan产品或服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用Vulcan提供的服务进行发布、传播如下信息和内容：违反国家法律法规政策的任何内容（信息）；违反国家规定的政治宣传和/或新闻信息；涉及国家秘密和/或安全的信息；封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；妨碍互联网运行安全的信息；侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容;您同时承诺不得为他人发布上述不符合国家规定和/或本服务条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接、语音内容等;
                <br/><br/>
                4.5.2 使用Vulcan产品或服务时，应遵守您与优权互联签订的服务条款、优权互联在www.vulcandata.cn相关页面上的管理规范、流程、服务说明、价格说明和您确认同意的订购页面的内容，包括但不限于：
                <br/><br/>
                4.5.2.1 您应按时付款；
                <br/><br/>
                4.5.2.2 不应出现任何破坏或试图破坏网络安全的行为等；
                <br/><br/>
                4.5.3 您以及您的关联方不是任何国家，国际组织或者地域实施的贸易限制，制裁或者其他法律限制的对象。
                <br/><br/>
                如您违反上述保证，优权互联除有权根据相关服务条款采取删除信息、中止服务、终止服务的措施，并有权限制您账户如新购产品或服务、续费等部分或全部功能，如因您上述行为给优权互联造成损失的，您应予赔偿。
                <br/><br/>
                4.6 若您使用的某项服务中包含可下载的Vulcan软件，则优权互联仅授予您非独占性的、不可转让的、非商业运营目的的个人使用许可。除非优权互联另有明示或与您另有约定外，您不得复制、修改、发布、出售或出租服务或所含软件的任何部分，也不得进行反向工程或试图提取该软件的源代码。
                <br/><br/>
                5、 优权互联的权利和义务
                <br/><br/>
                5.1 优权互联应根据您选择的服务以及交纳款项的情况向您提供合格的网络技术和信息服务。
                <br/><br/>
                5.2 优权互联承诺对您资料采取对外保密措施，不向第三方披露您资料，不授权第三方使用您资料，除非：
                <br/><br/>
                5.2.1 依据本服务条款或者您与优权互联之间其他服务协议、合同、在线条款等规定可以提供；
                <br/><br/>
                5.2.2 依据法律法规的规定应当提供；
                <br/><br/>
                5.2.3 行政、司法等职权部门要求优权互联提供；
                <br/><br/>
                5.2.4 您同意优权互联向第三方提供；
                <br/><br/>
                5.2.5 优权互联解决举报事件、提起诉讼而提交的；
                <br/><br/>
                5.2.6优权互联为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提交的；
                <br/><br/>
                5.2.7 优权互联为向您提供产品、服务、信息而向第三方提供的，包括优权互联通过第三方的技术及服务向您提供产品、服务、信息的情况。
                <br/><br/>
                5.3 优权互联有权根据本服务条款的约定，根据您的服务使用情况，自您的账户余额中直接扣除相关服务费用。
                <br/><br/>
                5.4 当存在如下情形之一时，优权互联有权对您名下账户资金的部分或全部进行冻结：
                <br/><br/>
                5.4.1 国家法律、法规、政策、法律文书的规定：
                <br/><br/>
                5.4.2 国家有权机关要求进行冻结的：
                <br/><br/>
                5.4.3 您使用优权互联服务的行为涉嫌违反国家法律法规及行政规定的；
                <br/><br/>
                5.4.4 优权互联基于单方面合理判断，认为您的账户操作、资金进出等存在异常时；
                <br/><br/>
                5.4.5 他人向您账户错误汇入资金等导致您可能存在不当得利的：
                <br/><br/>
                5.4.6 您遭到他人投诉， 且对方已经提供了一定证据的：
                <br/><br/>
                5.4.7 优权互联根据自行合理判断，认为可能产生风险的其他情况。
                <br/><br/>
                优权互联按照前述规定对您的账户资金进行冻结后，将通过站内信、短信或电话等方式通知您按照相应的程序进行申诉。如果您按照本服务条款第3.8条的要求进行申诉并经Vulcan审核通过的，Vulcan将立即解除资金冻结措施；如果您未在规定时间内进行申请，或您的申诉未能通过Vulcan审核的，则您确认Vulcan有权长期冻结该资金，或将相关冻结款项按原路退回该笔资金的充值来源账户（包括但不限于银行账户、支付宝账户等）。
                 <br/><br/>
                6、 隐私及其他个人信息的保护
                <br/><br/>
                一旦您同意本服务条款或使用本服务，您即同意优权互联按照以下条款来使用和披露您的个人信息。
                <br/><br/>
                6.1 登录名和密码
                <br/><br/>
                在您注册帐户时，优权互联会要求您设置Vulcan账户登录名和密码来识别您的身份，您仅可通过您设置的密码来使用该账户，如果您泄漏了密码，您可能会丢失您的个人识别信息，并可能导致对您不利的法律后果。该账户和密码因任何原因受到潜在或现实危险时，您应该立即和优权互联取得联系，在优权互联采取行动前，优权互联对此不负任何责任。
                <br/><br/>
                6.2 银行账户信息
                <br/><br/>
                若优权互联所提供的服务需要您提供您的银行账户信息，在您提供相应信息后，优权互联云将严格履行相关保密约定。
                <br/><br/>
                6.3 登录记录
                <br/><br/>
                为了保障您使用本服务的安全以及不断改进服务质量，Vulcan将记录并保存您登录和使用本服务的相关信息，但优权互联承诺不将此类信息提供给任何第三方（除双方另有约定或法律法规另有规定及优权互联关联公司外）。
                <br/><br/>
                6.4 广告
                <br/><br/>
                优权互联会对用户的身份数据进行综合统计，并出于销售和奖励的需要使用或披露。
                <br/><br/>
                6.5 外部链接
                <br/><br/>
                本网站含有到其他网站的链接，但Vulcan对其他网站的隐私保护措施不负任何责任。Vulcan可能在任何需要的时候增加商业伙伴或共用品牌的网站。
                <br/><br/>
                6.6 安全
                <br/><br/>
                优权互联仅按现有技术提供相应的安全措施来使Vulcan掌握的信息不丢失，不被滥用和变造。这些安全措施包括向其他服务器备份数据和对用户密码加密。尽管有这些安全措施，但Vulcan不保证这些信息的绝对安全。
                <br/><br/>
                6.7 您进一步同意Vulcan：
                <br/><br/>
                6.7.1 向您发送定制化的信息或新产品/服务信息，通过电邮、电话（包括语音电话、传真或信息，如果您表明我们可以以此方式联系您）；
                <br/><br/>
                6.7.2 出于为您提供增强或分析的统计功能使用您的个人信息，或者出于销售或鼓励的目的使用或披露个人信息。
                <br/><br/>
                6.7.3 您充分理解并同意：您在使用本产品服务某一特定服务时，本服务可以使用您终端设备的相关权限、接口及相关信息等以实现相应的功能。
                <br/><br/>
                7、 系统中断或故障
                <br/><br/>
                系统可能因下列状况无法正常运作，使您无法使用各项互联网服务或服务质量下降时，优权互联不承担损害赔偿责任，该状况包括但不限于：
                <br/><br/>
                7.1 Vulcan在系统停机维护期间。
                <br/><br/>
                7.2 电信设备出现故障不能进行数据传输的。
                <br/><br/>
                7.3 因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成Vulcan系统障碍不能执行业务的。
                <br/><br/>
                7.4 由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。
                 <br/><br/>
                7.5 由于公网网络不稳定导致传输数据丢失等；
                <br/><br/>
                8、 责任范围及责任限制
                <br/><br/>
                8.1 优权互联仅对本服务条款中列明的责任承担范围负责。
                <br/><br/>
                8.2 本服务之合作单位，所提供之服务品质及内容由该合作单位自行负责。
                <br/><br/>
                8.3 您了解并同意，因您使用本服务、违反本服务条款或在您的账户下采取的任何行动，而导致的任何第三方索赔，应且仅应由您本人承担。如果由此引起优权互联及其关联公司、员工、客户和合作伙伴被第三方索赔的，您应负责处理，并承担由此造成的全部责任。
                <br/><br/>
                8.4 在法律允许的情况下，优权互联对于与本服务条款有关或由本服务条款引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据、商誉或其他经济利益的损失），不论是如何产生的，也不论是由对本服务条款的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。另外即使本服务条款规定的排他性救济没有达到其基本目的，也应排除优权互联对上述损失的责任。
                <br/><br/>
                8.5 除本服务条款另有规定或优权互联与您就某一具体产品及/或服务另有约定外，在任何情况下，您同意优权互联对本服务条款所承担的赔偿责任总额不超过向您收取的当次服务费用总额。
                 <br/><br/>
                9、 完整协议
                <br/><br/>
                9.1 本服务条款由本服务条款与本网站公示的各项规则组成，相关名词可互相引用参照，如有不同理解，以本服务条款为准。
                <br/><br/>
                9.2 本服务条款的章节标题仅为行文方便而设，不具有法律或合同效力。
                <br/><br/>
                9.3 您对本服务条款理解和认同，您即对本服务条款所有组成部分的内容理解并认同，一旦您使用本服务，您和优权互联即受本服务条款所有组成部分的约束。
                <br/><br/>
                9.4 本服务条款部分内容被有管辖权的法院认定为违法的，不因此影响其他内容的效力。
                 <br/><br/>
                10、商标、知识产权的保护
                <br/><br/>
                10.1 除第三方产品或服务外，本网站上所有内容，包括但不限于著作、图片、档案、资讯、资料、架构、页面设计，均由优权互联或关联企业依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
                <br/><br/>
                10.2 非经优权互联或关联企业书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本网站上程序或内容。
                <br/><br/>
                10.3 尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任。
                 <br/><br/>
                11、通知送达
                <br/><br/>
                11.1 您理解并同意，Vulcan可依据自行判断，通过网页公告、手机短信或常规的信件传送等方式向您发出通知，且Vulcan可以信赖您所提供的联系信息是完整、准确且当前有效的；上述通知于发送之日视为已送达收件人。
                <br/><br/>
                11.2 除非本服务条款另有约定或优权互联与您另行签订的协议明确规定了通知方式，您发送给Vulcan的通知，应当通过Vulcan对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。
                 <br/><br/>
                12、 法律适用与管辖
                <br/><br/>
                本服务条款之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本服务条款产生之争议，均应依照中华人民共和国法律予以处理，并提交北京市朝阳区人民法院审理。
               </p>
            </div>
        )
    }
    renderVulcan(){
        return  <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: 'center',fontSize: '14px', color:'#333'}}>
            <p>
             跨语言数据处理与分发专家<br/>
            <span style={{color:"#ED6146"}}> 品牌释义</span>
             <br/>
             Vulcan，伏尔甘（拉丁语：Vulcānus、英语：Vulcan），是罗马神话中的火与工匠之神，罗马十二主神之一，朱庇特之子。伏尔甘其貌不扬甚至又丑又瘸，但他智慧过人，用匠心征服宇宙大神，用能力娶得美神维纳斯。他的灵魂和才智十分卓越，而且天生具有控制火的能力，因此能够轻而易举地冶炼出各式各样威力无穷的武器。Vulcan象征了经过千锤百炼之后获得的成功。他给诸神打造神器，充当着一位幕后的权力分配者，这相当于现代经济活动中的生产要素分配者。<br/>
             俯瞰是Vulcan的中文音译，取义站在更高的视角俯视大千世界，目光远大、凌厉、坚毅、聚焦，可以获取到其他视角无法获取的信息。<br/>
             <span style={{color:"#ED6146"}}>平台简介</span>
             <br/>
             Vulcan俯瞰是优权互联创立于2018年的服务品牌，Vulcan俯瞰致力于：将数据业务化（将数据应用融入用户业务当中去）、业务数据化（将用户业务处理进程以数据化的方式实现），数据匹配化（将外来数据与客户行业标准匹配，形成实际应用价值）。<br/>
             Vulcan俯瞰独有的全球自然语言翻译与大数据分析引擎可以为行业用户快速处理全球65种语言的数据，同时可以为大数据机构提供数据任意语言方向转换处理等服务模式。优权互联以自身创新发展和服务媒体转型并举，坚持人工智能+大数据服务的发展战略，自主研发和建设了集合给予客户行业需要的精准数据检索、多语种神经网络翻译、多形态数据存储、拆条、标注与分发，以及基于用户具体业务场景的实际数据落地应用服务平台。<br/>
             目前，Vulcan俯瞰 已为郑州商品交易所、中央广播电视总台经济之声、中国科技会馆、等机构用户提供内容大数据技术服务，同时也与中国期货业协会、永安期货、投中信息、36kr等头部机构建立了良好的合作关系。2016年，作为唯一被邀请的非国资数据应用服务机构，优权互联加入由中央广播电视总台经济之声牵头成立的金融指数研究联盟（Financial Index Research Alliance，FIRA），联盟成员包括了中译语通、永安期货等国内全球头部大数据公司和金融机构，参与基于另类数据为基础的金融指数研究课题工作。<br/>
             未来，Vulcan俯瞰将继续致力于先进的数据分析挖掘和应用研究，通过服务头部企业、机构与媒体，与头部数据服务机构合作，共同面向社会各行业客户，提供高质量的大数据技术及服务产品，让数据真正变得有用。<br/>
             <span style={{color:"#ED6146"}}>服务人群</span>
             <br/>
             Vulcan俯瞰 的目标用户为中小型以上企业或机构，主要包括以下几类人群：涉及国际间业务或产业链 的企业或机构、数据公司与咨询机构、金融机构，媒体、自媒体、公关公司及企业传播部门。用户既可根据各自的功能需求选择相应的功能模块进行使用，也可以根据企业业务的个性化需求申请定制开发版本。<br/>
             <span style={{color:"#ED6146"}}> 公司背景</span>
             <br/>
             北京优权互联网络科技有限公司（以下简称“优权互联”）成立于2015年，是全球领先的跨语言数据处理与分发服务商。公司创立以来，以大数据和人工智能驱动，通过连接数据使用方与数据生产方，帮助各个行业用户快速处理全球不同语言类型、不同形态的信息数据。<br/>
             <span style={{color:"#ED6146"}}> 联系我们</span>
             <br/>
             北京优权互联网络科技有限公司<br/>
            Beijing Superior CopyRight Network Technology Co., Ltd. <br/>
            电话：+86-10-67294760<br/>
            邮箱：contact@crgood.com<br/>

            客服QQ：1798632623<br/>
            微信：Vulcan俯瞰<br/>
            </p>
        </div>
    }
}
export default Index