import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import {Button} from 'react-bootstrap';
import Cropper from 'react-cropper';
import LoadingFixedCom from '../loadingFixedBox'
import 'cropperjs/dist/cropper.css';
import './index.scss';
@inject("store")
@observer
class Index extends React.Component {
      constructor(props) {
        super(props)
        this.state = {
            src: null,
            modalVisible: false,// 模态框
            confirmLoading: false,
            flieName: 'newfile.jpg',
            submitting: false,
            isCompleteCropper: false
        }
      }
      componentDidMount() {
        let file = this.props.uploadedImageFile;
        let that = this;
        this.convertImgToBase64(file, function(data) {
            that.setState({ src: data })
        })
    }

    convertImgToBase64(url, callback, outputFormat) {
        var canvas = document.createElement('CANVAS'),
    　　ctx = canvas.getContext('2d'),
    　　img = new Image;
    　　img.crossOrigin = 'Anonymous';
    　　img.onload = function(){
        　　canvas.height = img.height;
        　　canvas.width = img.width;
        　　ctx.drawImage(img,0,0);
        　　var dataURL = canvas.toDataURL(outputFormat || 'image/png');
        　　callback.call(this, dataURL);
        　　canvas = null; 
        };
    　　img.src = url;
    }
    handleSubmit = () => {
        this.setState({
            isCompleteCropper: true
        })
        if (!this.state.submitting) {
            console.log('正在上传图片')
            // TODO: 这里可以尝试修改上传图片的尺寸
            this.cropper.getCroppedCanvas().toBlob(async blob => {
                console.log(blob)
              //把选中裁切好的的图片传出去
              this.props.onSubmit(blob);
                this.setState({
                    isCompleteCropper: false
                })
              // 关闭弹窗
              this.props.onClose()
            })
          }
    }

    render() {
        return (
            <div className="class-cropper-modal">
                
                <div className="modal-panel">
               
                <div className="cropper-container-container">
                    <LoadingFixedCom show={this.state.isCompleteCropper}/>
                    <div className="cropper-container">
                    <Cropper
                        src={this.state.src}
                        className="cropper"
                        ref={cropper => (this.cropper = cropper)}
                        viewMode={1}
                        zoomable={false}
                        // aspectRatio={1} // 固定为1:1  可以自己设置比例, 默认情况为自由比例
                        guides={false}
                        preview=".cropper-preview"
                    />
                    </div>
                    <div className="preview-container">
                    <div className="cropper-preview" />
                    </div>
                </div>
                <div className="button-row">
                    <div className="remove-button" 
                    onClick={(e) => {
                        e.stopPropagation()
                        this.props.onClose()
                    }}>
                        取消
                       {/* <div onClick={this.props.onClose}>取消</div> */}
                    </div>
                    <div 
                        className="submit-button"
                        onMouseOver={() =>{}}
                        onClick={(e) => {
                            e.stopPropagation()
                            this.handleSubmit()
                        }}
                    >
                        确定
                       {/* <div onClick={this.handleSubmit}>确定</div> */}
                    </div>
                </div>
                </div>
            </div>
        )
    }
}
export default  Index

