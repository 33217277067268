import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    links = ['21世纪经济报道', '经济观察报', '第一财经', '财经网', '财新网', '中国经济网']
    render() {
        return(
            <div className="cooperation_links">
                    <div className="other_links_tip">
                        友情链接：
                    </div>
                    <div className="other_links_all">
                        {
                            this.links.map((item, index) => {
                                return (
                                    <div 
                                        className="links_all_txt"
                                        key={`links${index}`}
                                    >
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>

            </div>
        )
    }
}
export default Index