import React from 'react'
import {Editor, EditorState, RichUtils, AtomicBlockUtils, Modifier, ContentBlock, convertFromRaw, convertToRaw, ContentState, Entity} from 'draft-js';
import {convertToHTML} from 'draft-convert';
import draftToHtml from 'draftjs-to-html'
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import MediaBlock from './mediaBlock';
import {observer, inject} from 'mobx-react';
import './editorContent.scss'
const addImage = (editorState, data) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', data);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
  return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
}

const mediaBlockRenderer = (block) => {
  if (block.getType() === 'atomic') {
    return {
      component: MediaBlock,
      editable: false,
    };
  }
  return null;
}
// 自定义设置
const colorStyleMap = {
  // 颜色设置
  red: {
    color: 'rgba(255, 0, 0, 1.0)',
    fontFamily: 'arial'
  },
  orange: {
    color: 'rgba(255, 127, 0, 1.0)',
  },
  yellow: {
    color: 'rgba(180, 180, 0, 1.0)',
  },
  green: {
    color: 'rgba(0, 180, 0, 1.0)',
  },
  blue: {
    color: 'rgba(0, 0, 255, 1.0)',
  },
  indigo: {
    color: 'rgba(75, 0, 130, 1.0)',
  },
  violet: {
    color: 'rgba(127, 0, 255, 1.0)',
  },
  // 字体设置
  'SimSun': {
    fontFamily: 'SimSun'
  },
  'SimHei': {
    fontFamily: 'SimHei'
  },
  'KaiTi': {
    fontFamily: 'KaiTi'
  },
  'arial':{
    fontFamily: 'arial'
  },
  // 字号设置
  '9px':{
    fontSize:'9px',
  },
  '10px':{
    fontSize:'10px',
  },
  '11px':{
    fontSize:'12px',
  },
  '12px':{
    fontSize:'12px',
  },
  '14px':{
    fontSize:'14px',
  },
  '16px':{
    fontSize:'16px',
  },
  '18px':{
    fontSize:'18px',
  },
  '20px':{
    fontSize:'20px',
  },
  '24px':{
    fontSize:'24px',
  },
  '30px':{
    fontSize:'30px',
  },
  '36px':{
    fontSize:'36px',
  },



};
@inject("store")
@observer
class T_Content extends React.Component {
    constructor(props) {
        super(props)
        const {EditorDraftStore} = this.props.store
        this.state = {
            editorState: EditorDraftStore.editorState
        }

        this.onChange = (editorState) => {
            this.setState({editorState})
            // 收集选中样式
            var resArr = editorState.getCurrentInlineStyle()
            EditorDraftStore.editorSelectStyles = []// 每次清空
           if(resArr.size > 0){// 存在
              const res1 = resArr._map._list._tail
              const res2 = res1.array
              res2.map((item, index) => {
                if(item && item[0] && EditorDraftStore.editorSelectStyles.indexOf(item[0]) == -1){
                    EditorDraftStore.editorSelectStyles.push(item[0])
                }
              })
           }
           // 处理styles
          EditorDraftStore.handleStyles()
          // text-————————html
          // const ssss = convertToHTML({
          //   styleToHTML: (style) => {
          //     if (style === 'BOLD') {
          //       return <span style={{color: 'rgb(122,122,122)'}} />;
          //     }
          //   },
          //   blockToHTML: (block) => {
          //     if (block.type === 'PARAGRAPH') {
          //       return <p />;
          //     }
          //   },
          //   entityToHTML: (entity, originalText) => {
          //     if (entity.type === 'LINK') {
          //       return <a href={entity.data.url}>{originalText}</a>;
          //     }
          //     return originalText;
          //   }
          // })(editorState.getCurrentContent())
          // console.log(ssss + '------------')
          let str = editorState.getCurrentContent().getPlainText()
          const rawContentState = convertToRaw(editorState.getCurrentContent());
          // console.log(rawContentState)
          let options = {
            inlineStyles: {
                CODE: {
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
                        fontSize: 16,
                        padding: 2,
                    }
                },
                '9px':{
                  style:{
                    fontSize:'9px',
                  }
                },
                '10px':{
                  style:{
                    fontSize:'10px',
                  }
                },'11px':{
                  style:{
                    fontSize:'11px',
                  }
                },'12px':{
                  style:{
                    fontSize:'12px',
                  }
                },'14px':{
                  style:{
                    fontSize:'14px',
                  }
                },'16px':{
                  style:{
                    fontSize:'16px',
                  }
                },'18px':{
                  style:{
                    fontSize:'18px',
                  }
                },'20px':{
                  style:{
                    fontSize:'20px',
                  }
                },'24px':{
                  style:{
                    fontSize:'24px',
                  }
                },'30px':{
                  style:{
                    fontSize:'30px',
                  }
                },'36px':{
                  style:{
                    fontSize:'36px',
                  }
                },
                'red':{
                  style: {
                      color: 'red'
                  }
                },
                'orange':{
                  style: {
                      color: 'orange'
                  }
                },
                'yellow':{
                  style: {
                      color: 'yellow'
                  }
                },
                'green':{
                  style: {
                      color: 'green'
                  }
                },
                'blue':{
                  style: {
                      color: 'blue'
                  }
                },
                'indigo':{
                  style: {
                      color: 'indigo'
                  }
                },'violet':{
                  style: {
                      color: 'violet'
                  }
                },

            },
            blockStyleFn: (block) => {
                switch (block.getType()) {
                    case 'blockquote':
                        return {
                            style: {
                                'border-left': '5px solid red',
                                color: '#666',
                                'font-family': "'Hoefler Text', 'Georgia', serif",
                                'font-style': 'italic',
                                'margin': '16px 0',
                                'padding': '10px 20px'
                            }
                        };
                    case 'left':
                        return { style: { 'text-align': 'left' } };
                    case 'center':
                        return { style: { 'text-align': 'center' } };
                    case 'right':
                        return { style: { 'text-align': 'right' } };
                    case 'suibian':
                        return { style: { color: 'indianred' } };
                    default: return null;
                }
            },
            // blockRenderers:{
            //   atomic: (block) => {
            //     let data = block.getData();
            //     // console.log(block.key)
            //     let index = -1;
            //     rawContentState.blocks.map(item=>{
            //       if(item.key === block.key){
            //         // console.log(item)
            //         index = item.entityRanges[0].key
            //         // console.log(index)
            //       }
            //     })
            //     if(index >= 0){
            //       let data = rawContentState.entityMap[index].data
            //       console.log(data)
            //       return `< img src="${data.src}" style="${JSON.stringify(data.style)}"></img>`
            //     }
            //     return ``;
            //   }
            // }
        }
          let htmlStr = stateToHTML(editorState.getCurrentContent(), options);
          // let htmlStr = draftToHtml(rawContentState);
          // console.log(htmlStr + '====')
          // 实时翻译
          EditorDraftStore.handleEditorContent(htmlStr)
          // 图片裁剪
          EditorDraftStore.hoverEditorImgInit()
          // 编辑器内容变化的时候，保存或者更新文章
          const {userStore, ArticleStore} = this.props.store
          var token = userStore.token
          ArticleStore.setToken(token)
          ArticleStore.activeContent = htmlStr
          if(str.length > 0){
            ArticleStore.saveOrUpdateArticle()
          }
        }

        // 
    }
    componentDidMount(){

    }

    render() {
        return(
            <Editor
                ref="editor"
                blockStyleFn={getBlockStyle}
                editorState = {this.state.editorState}
                onChange= {this.onChange.bind(this)}
                blockRendererFn={mediaBlockRenderer} // 自定义渲染组件
                stripPastedStyles={true} //设置是否删除粘贴内容之外的除明文之外的所有信息。如果您的编辑器不支持丰富的样式，则应该使用它。默认为 false;
                customStyleMap={colorStyleMap}
                // placeholder="Write something colorful..."
            ></Editor>
        )
    }
    _imgIndexEntity(obj){
      const { editorState} = this.state;
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const contentState = this.state.editorState.getCurrentContent()  
      let newBlock = rawContentState.blocks.filter(item=>{
        return item.type === 'atomic'
      })
      let activeKey = newBlock[0]['key']
      // console.log(activeKey)
      const contentBlock = contentState.getBlockForKey(activeKey)
      // console.log(contentBlock)
      // console.log( contentBlock.getEntityAt(0))
      Entity.replaceData(
        contentBlock.getEntityAt(0),//替换新的
        {
          "src": "https://img-blog.csdnimg.cn/20190918135101160.png",
          'style': `width:'100px', height:'200px'`
        }
      )
    }

    // 插入html
    _insertHtml(html){
      console.log(html + 'htmlBe')
      const blocksFromHtml = htmlToDraft(html);
      // console.log(blocksFromHtml)
      const { contentBlocks, entityMap } = blocksFromHtml;
      // if(contentBlock){
      //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      //   const editorState = EditorState.createWithContent(contentState);
      //   console.log(editorState)
      //   this.onChange(editorState)
      // }
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      // console.log('editorState')
      // console.log(editorState)
      this.onChange(editorState);
    }
    _undo () {
      // this._imgIndexEntity()
        const { editorState} = this.state;
        this.onChange(
          EditorState.undo(editorState)
        );
    }
    
    _redo () {
        const { editorState } = this.state;
        this.onChange(
          EditorState.redo(editorState)
        );
    }

    focus(){
        this.refs.editor.focus()
        // this.editor.focus()
    }

    _toggleBlockType(blockType) {
      this.onChange(
        RichUtils.toggleBlockType(
          this.state.editorState,
          blockType
        )
      );
    }
    
    _toggleInlineStyle(inlineStyle) {
      this.onChange(
        RichUtils.toggleInlineStyle(
          this.state.editorState,
          inlineStyle
        )
      );
    }

    _clear(){
      this.onChange(EditorState.createEmpty())
    }

    /**
     * 添加图片 
     * @param {*} src 图片网络地址 
     * @param {*} description 图片表述
     */
    _insertImage(src, description){
      const data = {
        src: src,
        description: description,
      }
      this.onChange(addImage(this.state.editorState, data)) 
    }
    /**
     * 添加图片-新增
     */
    addImage2 = (src, style = '') => {
      const editorState = this.state.editorState;
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'image',
        'IMMUTABLE',
        { src, style }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(
        editorState,
        { currentContent: contentStateWithEntity },
        'create-entity'
      );
  
      this.setState({
        editorState: AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
      });
    }

    /**
     * 添加引用
     * 未实现
     */
    _insertLink(){

    }
    /**
     * 插入文字
     * 
     */
    _insertText(txt) {
      const contentState = this.state.editorState.getCurrentContent()
      const selection = this.state.editorState.getSelection()

      const nextContentState = Modifier.insertText(contentState, selection, txt);
      let nextEditorState = EditorState.createEmpty();
      nextEditorState = EditorState.push(
        this.state.editorState,
        nextContentState,
        'insert-characters'
      );
      this.onChange(nextEditorState);
    }
    // 设置颜色
    _toggleColor(toggledColor) {
      const {editorState} = this.state;
      const selection = editorState.getSelection();
      const nextContentState = Object.keys(colorStyleMap)
        .reduce((contentState, color) => {
          return Modifier.removeInlineStyle(contentState, selection, color)
        }, editorState.getCurrentContent());

      let nextEditorState = EditorState.push(
        editorState,
        nextContentState,
        'change-inline-style'
      );

      const currentStyle = editorState.getCurrentInlineStyle();

      // Unset style override for current color.
      if (selection.isCollapsed()) {
        nextEditorState = currentStyle.reduce((state, color) => {
          return RichUtils.toggleInlineStyle(state, color);
        }, nextEditorState);
      }

      // If the color is being toggled on, apply it.
      if (!currentStyle.has(toggledColor)) {
        nextEditorState = RichUtils.toggleInlineStyle(
          nextEditorState,
          toggledColor
        );
      }
      this.onChange(nextEditorState);
    }

}
function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';// 标题
    case 'blockleft': return 'RichEditor-blockleft';// 左对齐
    case 'blockright': return 'RichEditor-blockright';// 右对齐
    case 'blockcenter': return 'RichEditor-blockcenter';// 居中对齐
  
    default: return null;
  }
}
export default T_Content

