import {observable, action} from 'mobx';
import api from '../utils/Api';

class AudioSplitStore{
    @observable token = null;
    @action setToken(token){
        this.token = token
    }
    @observable files = [];
    @observable loading = false;
    @observable tagsList = [];
    @observable adapterList = [];
    @observable fusionAudiosMp3 = "";

    @action addAdapter(file, data) {
        let adapter = new AsplitAdapter(file, data, this.token);
        //添加一个新适配器
        this.adapterList.push(adapter);
        //更新mobx观察对象 以便让UI更新
        this.adapterList = [... this.adapterList];
        console.log(this.adapterList)
    }

    @action addAudioTxt(item, fileId){
        let nTag = {
            id: `addAllTxt${this.tagsList.length}`,
            tag: {
                'labelText': item.text,
                'startTime': Math.round(item.begin_time/1000),
                'endTime': Math.round(item.end_time/1000)
            },
            isTxtTime: true,
            fileId: fileId
        }
        let flag = true

        this.tagsList.map(item=>{
            if(item.tag.labelText == item){
                flag = false
            }
        })

        if (flag) {
            this.tagsList.push(nTag)
            this.tagsList = [...this.tagsList]
        }
        console.log(this.tagsList);
    }
    @action addTag(tag, fileId){
        let nTag = {
            id: `addAllTags${this.tagsList.length}`,
            tag: tag,
            // tag: {
            //     'labelText': tag.labelText,
            //     'startTime': parseFloat(tag.startTime),
            //     'endTime': parseFloat(tag.endTime)
            // },
            fileId: fileId
        }
        let flag = true

        this.tagsList.map(item=>{
            if(item.tag == tag){
                flag = false
            }
        })

        if (flag) {
            this.tagsList.push(nTag)
            this.tagsList = [...this.tagsList]
        }
        console.log(this.tagsList)
    }

    @action deletaTag(tag){
        // console.log(tag)
        this.tagsList = this.tagsList.filter(item=>{
            console.log(item.tag == tag)
            return item.tag != tag
        })
        // console.log(this.tagsList)
    }

    @action addTags(tags){
        this.tagsList = [...tags]
    }

    @action selectFile(e) {
        e.persist()
        if(e.target) {
            this.loading = true;
            let that = this;
            const file = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = et => {
                that.addAdapter(file, et.target.result);
                that.loading = false;
            }
        }
    }
    @observable fusionLoading = false
    @observable fusionAudiosMp3Arr = []
    @action fusionAudiosFile(list){
        this.fusionLoading = true 
        let result = api.fusionAudios(list, this.token);
        let that = this;
        result.then((data) => {
            if(data && data.code == 200){
                that.fusionAudiosMp3 = data.data;
                that.fusionAudiosMp3Arr.push(data.data);
                this.fusionLoading = false
            }
        })
    }

}

/**
 * 实现对象——组件适配器
 */
class AsplitAdapter{
    /**
     * 
     * @param {*} audioData 字符串对象
     * @param {*} file 文件对象
     */
    constructor(file, audioData, token) {
        this.audiofile = file;
        this.audioData = audioData  
        this.getFileId(token) 
    }
    timer = null
    fileId = null
    fileUrl = null
    audiofile = null
    audioData = null
    audioToTxts = []
    instance = null
    isUploadFile = false
    isClickToTxt = false
    setPeakInstance(peak) {
        this.instance = peak;
    }
    getSegments() {
        return this.instance.segments.getSegments()
    }
    getStatus(){}
    //获取上传文件ID
    async getFileId(token){
        console.log('获取上传文件ID')
        const eachSize = 2 * 1024 * 1024; // 每个chunks的大小
        const blockCount = Math.ceil(this.audiofile.size / eachSize); // 分片总数
        let ext = this.audiofile.name.split('.');
        ext = ext[ext.length-1]; // 获取文件后缀名
        let preResult = await api.preSliceUploadFile(this.audiofile.name, blockCount, this.audiofile, ext, token);
        console.log(preResult)
        if (preResult && 200 == preResult.code) {
            this.fileId = preResult.data.id
            // 直接调用上传文件
            this.upLoadFile(token)
        }
        return this.fileId
    }
    //上传文件到服务器
    upLoadFile(token){
        api.fileSliceUpload(this.fileId, this.audiofile, token, scuess => {
            this.timer = setInterval(()=>{
                this.checkFileState(token)
            }, 5000)
        })
    }
    //检查音频文件状态
    async checkFileState(token){
        let result = await api.selectUpLoadFileStatus(this.fileId, token);
        console.log(result)
        if(result && 200 == result.code) {
            result = result.data;
            this.fileUrl = result.fileUrl
            console.log(`检查文件状态：${result.dataTag}`)
            if(result.dataTag == 8 || result.dataTag == 9){
                this.isUploadFile = true;
                // console.log(this.fileUrl)
                // console.log(result.fileUrl)
                //停止定时器
                if(this.timer){
                    clearInterval(this.timer)
                    this.timer = null
                }
                // 上传文件完成之后，直接转为汉字
                this.getAudioToText(token)
            }
        }
    }
    //获取音频文件文字信息
    async getAudioToText(token){
        let result = await api.getUpLoadFileContentById(this.fileId, token);
        console.log(result)
        if(result && 200 == result.code) {
            if(result.data != ""){
                this.audioToTxts= JSON.parse(result.data)['result'];
            }
            this.getStatus()
            
            
        }
    }

    // 新增
    @observable tagsList = []
    // 添加到合成列表
    @action splitAddTag(tag, fileId, index){
        let nTag = {
            id: `splitTag${fileId}${index}`,
            tag: tag,
            fileId: fileId
        }
        let flag = true

        this.tagsList.map(item=>{
            if(item.tag == tag){
                flag = false
            }
        })

        if (flag) {
            this.tagsList.push(nTag)
            this.tagsList = [...this.tagsList]
        }
    }
    @action splitAddAudioTxt(item, fileId, index){
        let nTag = {
            id: `addTxt${fileId}${index}`,
            tag: {
                'labelText': item.text,
                'startTime': Math.round(item.begin_time/1000),
                'endTime': Math.round(item.end_time/1000)
            },
            isTxtTime: true,
            fileId: fileId
        }
        let flag = true

        this.tagsList.map(item=>{
            if(item.tag.labelText == item){
                flag = false
            }
        })

        if (flag) {
            this.tagsList.push(nTag)
            this.tagsList = [...this.tagsList]
        }
    }
    @action splitDeletaTag(id){
        this.tagsList = this.tagsList.filter(item=>{
            return item.id != id
        })
    }
}

const asStore = new AudioSplitStore();
export default asStore;