import {observable, action} from 'mobx';
import api from '../utils/Api'
import {Editor, EditorState, RichUtils, AtomicBlockUtils, Modifier} from 'draft-js';
import index from '../component/loading';
class EditorDraft{
    @observable editorState = EditorState.createEmpty()
    @observable editorSelectStyles = []// 鼠标样式收集
    @observable editorHandleStyles = {}// 处理后styles
    @observable editorClassExport = ''
    @action handleStyles(){
        var _that = this
        var resOb = {
            'bold': false,
            'color': "#000",
            'size': '14px',
            'italic': false,
            'underline': false
        }
        // 加粗-斜体-下划线
        if(this.editorSelectStyles.indexOf('BOLD') != -1){
            resOb.bold = true
        }
        if(this.editorSelectStyles.indexOf('ITALIC') != -1){
            resOb.italic = true
        }
        if(this.editorSelectStyles.indexOf('UNDERLINE') != -1){
            resOb.underline = true
        }
        // 字体
        const fontS = this.editorSelectStyles.filter(
            item => item.indexOf('px') != -1
        );
        resOb.size =  fontS[0] || '14px'
        // 颜色
        let colorS = ['red','orange','yellow', 'green','blue','indigo','violet']
        for(let i=0; i<colorS.length; i++){
            if(_that.editorSelectStyles.indexOf(colorS[i]) != -1){
                resOb.color = colorS[i]
            }
        }
        _that.editorHandleStyles = resOb
    }
    @observable editorImgCount = 0// 图片数量
    @observable editorTextCount = 0// 文本字数
    @observable editorText = ''// 文本内容
    // 设置编辑器内容
    @action handleEditorContent(txt = '', html = ''){
       this.editorTextCount = txt.length
       this.editorText = txt
       this.transHtml = []
       this.transHtml = html
       if(this.isTimingTrans && this.editorText.length > 0){
        //    this.translateTxt()
        //  一段一段翻译
        var newhtml = html.replace(/&nbsp;/g, "")
        let txt = newhtml == null ? html.replace(/<[^>]+>/g, "&nbsp;") : newhtml.replace(/<[^>]+>/g, "&nbsp;");
        let texts = txt.split("&nbsp;");
        // console.log(texts) 
        texts.filter(item=>{return item != ""}).map((item, index)=>{
           this.translateTxt(item)
        })
       }
    }
    // 翻译
    @observable transTxt = ''// 翻译后的文本
    @observable transHtml = ''
    @observable transPurpose = 'nen'
    @observable transLoading = false
    @observable isTimingTrans = false // 是否——时刻调用翻译接口
    @action async translateTxt(data){
        this.transLoading = true
        let result = await  api.txtTran2Txt(data, 'nzh', this.transPurpose, '');
        if(result && result.code == 200){
            this.transTxt = result.data
            this.transLoading = false
            this.transHtml = this.transHtml.replace(data, result.data)
        }
    }
    // 文本转音频
    @observable articleMp3 = ''
    /**
     * 
     * @param {*} content
    */
    @action async articleToAudio(){
        let result = await api.text2Voice(this.editorText, this.token);
        if(result && 200 == result.code){
            this.articleMp3 = result.data;
        }
    }
    // 设置编辑器中鼠标经过——移除事件
    @observable isCropperDiv = false// 裁剪txt
    @observable isCropperModal = false// 裁剪工具modal
    @observable isCoverCropper = false// 是否覆盖裁剪框
    @observable cropperTxtLeft = ''
    @observable cropperTxtTop = ''
    @observable cropperImgUrl = ''
    @observable cropperImgIndex = -1
    @action hoverEditorImgInit(){
        var _iframe = document.getElementById('editor-container');
        var iframe_imgs = _iframe.getElementsByClassName('editor_work_img');
        const that = this
        if(iframe_imgs) {
            for(let i=0; i<iframe_imgs.length; i++){
                iframe_imgs[i].onclick = (e) => {
                    that.isCropperDiv = true
                    that.cropperTxtLeft = _iframe.offsetLeft + iframe_imgs[i].offsetLeft -75 + iframe_imgs[i].width/2+ 'px';
                    that.cropperTxtTop = _iframe.offsetTop + iframe_imgs[i].offsetTop + iframe_imgs[i].height/2 - 40 + 'px';
                    that.cropperImgUrl = iframe_imgs[i].src
                    that.cropperImgIndex = i
                }
                // iframe_imgs[i].onmouseout = () => {
                //     if(!that.isCoverCropper){
                //         that.isCropperDiv = false
                //     }
                // }
            }
                
        }
    }
    @action fangDaOrXiaoImg(isBig = false){
        var _iframe = document.getElementById('editor-container');
        var iframe_imgs = _iframe.getElementsByClassName('editor_work_img');
        const that = this
        var w=parseInt(iframe_imgs[that.cropperImgIndex].offsetWidth);
        var h=parseInt(iframe_imgs[that.cropperImgIndex].offsetHeight);
        // 按比例大小
        var w_2 = (w/(h+w)) * 60
        var h_2 = (w/(h+w)) * 60
        iframe_imgs[that.cropperImgIndex].style.width=isBig ? (w+w_2)+"px" : (w-w_2)+"px"
        iframe_imgs[that.cropperImgIndex].style.height=isBig ? (h+h_2)+"px" : (h-h_2)+"px"
        
    }
}
const EditorDraftStore = new EditorDraft();
export default EditorDraftStore