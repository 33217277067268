import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    render() {
        return(
            <div 
                className="user_assess_box"
            >
               <div className="assess_box_top">
                   <img src={this.props.iconUrl || 'https://www.vulcandata.cn/_image_1_1/1.jpg'}/>
                   <div className="top_source">
                       <div className="source_txt" style={{fontSize:'14px',fontWeight:'600'}}>
                           {this.props.source}
                       </div>
                       <div className="source_user" style={{fontSize:'9px'}}>
                           {this.props.user}
                       </div>
                   </div>
               </div>
               <div className="assess_box_bottom">
               {this.props.content}
               </div>
            </div>
        )
    }
}
export default Index