import React,{Component} from 'react';
import { observer , inject } from 'mobx-react';
import './index.scss';
import Select from '../selectOne';
import ImageSet from '../../utils/image'
@inject("store")
@observer
class Index extends React.Component{
    state={
        selectIndex: -1,
        isShow: false,
        isShowSource: false,
    }
    render(){
        const {DataSourcesStore, SearchStore} = this.props.store
        return(
            <div className="select_group_layout" >
                <div className="layout_left">
                    {
                        this.props.titles.map((item, index)=>{
                            return <div
                                    key={`selectone${index}`}
                                    className="select_group_layout_main"
                                >
                                <Select 
                                    componentView={item.components} 
                                    left={item.left ? true : false} 
                                    style={item.style} 
                                    action={item.action} 
                                    num={index} 
                                    show={this.state.isShow && index == this.state.selectIndex} 
                                    key={`select_one${index}`} 
                                    title={item.title} 
                                    data={item.data} 
                                    titleClick={() => {
                                        this.setState({
                                            selectIndex: index,
                                            isShow: !this.state.isShow
                                        })
                                    }}
                                    listClick={() => {
                                        this.setState({
                                            isShow: false
                                        })
                                    }} 
                                />
                            </div>
                        })
                    }
                </div>

                <div className="layout_right">
                    <div 
                        className="right_select"
                        onClick={() => {
                            this.setState({
                                isShowSource: !this.state.isShowSource
                            })
                        }}
                    >
                        <div
                            style={
                                SearchStore.checkIsInformation() == 1 ?
                                {color:'rgba(237,97,70,1)'}:
                                {}
                            }
                        >
                            数据来源
                        </div>
                        <img src={ImageSet.dddSelect}/>
                    </div>
                    <div 
                        className="right_modals"
                        style={
                            this.state.isShowSource ?
                            {display:'block'}:
                            {display:'none'}
                        }
                    >
                        {/* {
                            this.source.map((item, index) => {
                                return <div
                                    className="modals_source"
                                    key={`modal_source${index}`}
                                    onClick={() => {
                                        this.setState({
                                            isShowSource: false,
                                            showSourceTxt: item
                                        })
                                    }}
                                >
                                    {item}
                                </div>
                            })
                        } */}
                        {/* 数据来源 */}
                        {
                            this.renderDataSource()
                        }
                    </div>
                </div>
            </div>
        )
    }
    componentDidMount(){
        const {DataSourcesStore, userStore} = this.props.store
        DataSourcesStore.setToken(userStore.token)
        DataSourcesStore.getSourceData()
    }
    renderDataSource(){
        const {DataSourcesStore, SearchStore} = this.props.store
        const {
            sourceList,
            allSelectTypes,
            hangYeList,
            guoJiaList,
            jiBieList,
            quDaoList,
            isOpenSelect,
            selectedSourceList
        } 
        = DataSourcesStore
        const _that = this
        return (
            <div className="render_data_source">
                <div className="data_source_main">
                    <div className="source_main_input_box">
                        <input
                            type="txt"
                            className="box_input"
                            placeholder="请输入关键词"
                            onKeyUp={(e) => {
                                if(e.keyCode == 13){
                                    DataSourcesStore.searchSource(e.target.value)
                                }
                            }}
                            onChange={(e) => {
                                if(e.target.value == ''){
                                    DataSourcesStore.getSourceData()
                                }
                            }}
                        />
                        <img src={ImageSet.searchSource}/>
                    </div>
                    <div className="source_main_selects">
                        {
                            allSelectTypes.map((item, index) => {
                                return (
                                    <div 
                                        className="selects_box_main"
                                        key={`selects_box_main${index}`}
                                    >
                                        {/* 选中 */}
                                        <div 
                                            className="box_main_selected"
                                            onClick={() => {
                                                if(DataSourcesStore.isOpenSelect == -1){
                                                    DataSourcesStore.isOpenSelect = index
                                                } else {
                                                    DataSourcesStore.isOpenSelect = -1
                                                }
                                            }}
                                        >
                                           {item}
                                            <img src={ImageSet.dddSelect}/>
                                            
                                        </div>
                                        {/* 选项 */}
                                        <div 
                                            className="box_main_selects"
                                            style={
                                                isOpenSelect == index ?
                                                {} :
                                                {display:"none"}
                                            }
                                        >
                                            {
                                                (index == 0 ?
                                                hangYeList :
                                                (
                                                    index == 1 ?
                                                    guoJiaList :
                                                    (
                                                        index == 2 ?
                                                        jiBieList :
                                                        quDaoList
                                                    )
                                                )).map((it,idn) => {
                                                    return <div
                                                        className="main_selects_list"
                                                        key={`main_selects_list${it}${idn}`}
                                                        style={
                                                            it == item ?
                                                            {color:"#ED6146"} :
                                                            {}
                                                        }
                                                        onClick={() => {
                                                            if(it != '全部'){
                                                                DataSourcesStore.allSelectTypes[index] = it
                                                            } else {
                                                                var nameS = DataSourcesStore.IndexName(index)
                                                                DataSourcesStore.allSelectTypes[index] = nameS
                                                            }
                                                            this.fliterSourceType(index, it)
                                                            DataSourcesStore.isOpenSelect = -1
                                                        }}
                                                    >
                                                        {it}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div 
                            className="selects_all_selected"
                            onClick={() => {
                                if(sourceList.length > 0){
                                    DataSourcesStore.allSelected()
                                }
                            }}
                            style={
                                sourceList.length == 0 ?
                                {cursor:'not-allowed', opacity:'.3'} :
                                {}
                            }
                        >
                            <img src={
                                (sourceList.length > 0 &&
                                selectedSourceList.length == sourceList.length) ?
                                ImageSet.loginYes :
                                ImageSet.loginNo
                            }/>
                            全选
                        </div>
                    </div>
                    <div className="source_main_content">
                        {
                            sourceList &&
                            sourceList.length > 0 &&
                            sourceList.map((item, index) => {
                                return <div
                                    className="source_list"
                                    key={`source_list${index}`}
                                    onClick={() => {
                                        DataSourcesStore.addOrRemoveSource(item, item.informationSourcesName)
                                    }}
                                >
                                    <img 
                                        src={
                                            selectedSourceList.indexOf(item.informationSourcesName) != -1 ?
                                            ImageSet.loginYes :
                                            ImageSet.loginNo
                                        }
                                    />
                                    {item.informationSourcesName}
                                </div>
                            })
                        }
                    </div>
                    <div className="source_main_confirm">
                        <div 
                            className="confirm_btn"
                            onClick={() => {
                                SearchStore.searchAction()
                                _that.setState({
                                    isShowSource: false
                                })
                            }}
                        >确定</div>
                    </div>
                </div>
            </div>
        )
    }
    fliterSourceType(index, name){
        const {DataSourcesStore} = this.props.store
        DataSourcesStore.filterSource()
        // if(index == 0){
        //     DataSourcesStore.filterSource('informationSourcesType', name)
        // } else if(index == 1){
        //     DataSourcesStore.filterSource('countyName', name)
        // } else if(index == 2){
        //     DataSourcesStore.filterSource('levelName', name)
        // } else if(index == 3){
        //     DataSourcesStore.filterSource('webChannel', name)
        // }
    }
}
export default Index