import React from 'react';
import { Editor } from 'react-draft-wysiwyg'
import {observer, inject} from 'mobx-react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { convertToRaw, ContentState, EditorState, Modifier} from 'draft-js';
import './index.scss'
import ImageSet from '../../utils/image'
import api from '../../utils/Api'
@inject("store")
@observer
class Index extends React.Component{
    constructor(props) {
        super(props)
        const html = '';
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
          const inputEditorState = EditorState.createWithContent(contentState);
          this.state = {
            inputEditorState,
          };
        }
      }
    
      onInputEditorChange = (inputEditorState) => {
        const {EditorDraftStore, userStore, ArticleStore} = this.props.store
        const rawContent = convertToRaw(inputEditorState.getCurrentContent());
        const html = draftToHtml(rawContent);
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          // const outputEditorState = EditorState.createWithContent(contentState);
          this.setState({
            inputEditorState,
          });
        }
        // const rawContent = convertToRaw(inputEditorState.getCurrentContent());
        // const html = draftToHtml(rawContent);
        // this.setState({
        //     inputEditorState,
        // });
        const str = inputEditorState.getCurrentContent().getPlainText()
        // 实时翻译
        EditorDraftStore.handleEditorContent(str, html)   
        //  保存下来
        var token = userStore.token
        ArticleStore.setToken(token)
        ArticleStore.activeContent = html
          if(str.length > 0){
            ArticleStore.saveOrUpdateArticle()
        }
        
        // 查询多少个图片
        // var rootBox = document.getElementsByClassName('DraftEditor-root')[0];
        // var rootImgs = rootBox.getElementsByTagName('img')
        // var rootImgsLength = rootImgs ? rootImgs.length : 0
        // EditorDraftStore.editorImgCount = rootImgsLength

        // console.log(rootImgsLength + '+++++++++rootImgsLength')

        // html转draft
        // const contentBlock = htmlToDraft(html);
        // if (contentBlock) {
        //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        //   const outputEditorState = EditorState.createWithContent(contentState);
        //   this.setState({
        //     inputEditorState,
        //     outputEditorState,
        //   });
        // }
      }
     // 插入html
     _insertHtml(html){
         var htmlstr = html
        const blocksFromHtml = htmlToDraft(htmlstr);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        this.onInputEditorChange(editorState)
      }
    _insertText(txt) {
        const contentState = this.state.inputEditorState.getCurrentContent()
        const selection = this.state.inputEditorState.getSelection()
        const nextContentState = Modifier.insertText(contentState, selection, txt);
        let nextEditorState = EditorState.createEmpty();
        nextEditorState = EditorState.push(
          this.state.inputEditorState,
          nextContentState,
          'insert-characters'
        )
        this.onInputEditorChange(nextEditorState)
      }
    _imageUploadCallBack =  (file) => new Promise(
        (resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            let img = new Image();
            // let url = ''
            reader.onload = function (e) {
                img.src = this.result
            };
      
            img.onload = function () {
                //console.log(img); // 获取图片
                // console.log(img.src.length)
                // 缩放图片需要的canvas（也可以在DOM中直接定义canvas标签，这样就能把压缩完的图片不转base64也能直接显示出来）
                let canvas = document.createElement('canvas');
                let context = canvas.getContext('2d');
      
                // 图片原始尺寸
                let originWidth = this.width;
                let originHeight = this.height;
      
                // 最大尺寸限制，可通过设置宽高来实现图片压缩程度
                let maxWidth = 400,
                    maxHeight = 500;
                // 目标尺寸
                let targetWidth = originWidth,
                    targetHeight = originHeight;
                // 图片尺寸超过300x300的限制
                if(originWidth > maxWidth || originHeight > maxHeight) {
                    if(originWidth / originHeight > maxWidth / maxHeight) {
                        // 更宽，按照宽度限定尺寸
                        targetWidth = maxWidth;
                        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                    } else {
                        targetHeight = maxHeight;
                        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                    }
                }
                // canvas对图片进行缩放
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                // 清除画布
                context.clearRect(0, 0, targetWidth, targetHeight);
                // 图片压缩
                context.drawImage(img, 0, 0, targetWidth, targetHeight);
                /*第一个参数是创建的img对象；第二三个参数是左上角坐标，后面两个是画布区域宽高*/
      
                //压缩后的图片转base64 url
                /*canvas.toDataURL(mimeType, qualityArgument),mimeType 默认值是'image/png';
                  * qualityArgument表示导出的图片质量，只有导出为jpeg和webp格式的时候此参数才有效，默认值是0.92*/
                 let newUrl = canvas.toDataURL('image/jpeg', 0.92);//base64 格式
                 //  也可以把压缩后的图片转blob格式用于上传
                const tokenUser = sessionStorage.getItem("token")
                canvas.toBlob((blob)=>{
                    let newFile = new File([blob], 'newImg.jpg');
                    api.upLoadFile(newFile, tokenUser).then(data=>{
                      console.log(data)
                      data = data.data;
                      if(data && 200 == data.code){
                        console.log('上传图片成功')
                        newUrl = data.data.banner
                        resolve({
                            data: {
                                link: newUrl
                            }
                        })
                      } 
                  })
                    // let result =  api.upLoadFile(newFile, tokenUser);
                    // console.log(result)
                    // result = result.data;
                    
                    // if(result && 200 == result.code){
                    //     console.log('上传图片成功')
                    // }
                }, 'image/jpeg', 0.92)
                
      
                
            }
        }
      );
    render() {
      const that = this
      const toolbar={
        options: ['history', 'inline', 'colorPicker', 'image','blockType',  'fontSize','list', 'textAlign', 'remove'],
        inline: {
          options: ['bold', 'italic', 'underline'],
          bold: { className: 'bordered-option-classname' },
          italic: { className: 'bordered-option-classname' },
          underline: { className: 'bordered-option-classname' },
          strikethrough: { className: 'bordered-option-classname' },
          code: { className: 'bordered-option-classname' },
        },
        blockType: {
          inDropdown: true,
          options: ['Normal','Blockquote', 'Code'],
          component: undefined,
          dropdownClassName: undefined,
        },
        fontSize: {
          // icon: ImageSet.eZiTi,
          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        },
        list: {
          inDropdown: false,
          options: ['unordered', 'ordered'],
          unordered: { className: 'bordered-option-classname' },
          ordered: {className: 'bordered-option-classname' },
        },
        textAlign: {
          inDropdown: false,
          component: undefined,
          dropdownClassName: undefined,
          options: ['left', 'center', 'right'],
          left: {
            className: 'bordered-option-classname2'
          },
          center: {className: 'bordered-option-classname2' },
          right: {className: 'bordered-option-classname2' },
        },
        colorPicker: {
          className: 'bordered-option-classname',
          colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
        },
        image: {
          className: 'bordered-option-classname',
          component: undefined,
          popupClassName: undefined,
          urlEnabled: false,
          uploadEnabled: true,
          alignmentEnabled: true,
          uploadCallback: this._imageUploadCallBack,
          previewImage: true,
          inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
          alt: { present: false, mandatory: false },
          // alt: { present: true, mandatory: true },
          defaultSize: {
            height: 'auto',
            width: 'auto',
          },
        },
        remove: { 
          className: 'bordered-option-classname',
          component: undefined 
        },
        history: {
          inDropdown: false,
          options: ['undo', 'redo'],
          undo: { 
            className: 'bordered-option-classname',
          },
          redo: { 
            className: 'bordered-option-classname',
          },
        },
      }
        return(
            <div className="draft_wysiwyg">
                {/* <div onClick={() => this._insertText('0000000000000000')}>插入文字</div>
                <div onClick={() => this._insertHtml()}>插入html</div> */}
                <div>
                    <Editor
                        ref='editor_wys'
                        editorState={this.state.inputEditorState}
                        onEditorStateChange={this.onInputEditorChange}
                        wrapperClassName="editor_wys_wrapper"
                        editorClassName="editor_wys_editor"
                        toolbar={toolbar}
                        placeholder="开始书写、左侧搜索素材..."
                    />
                    </div>
            </div>
        )
    }
    
}
export default Index
