import React ,{Component} from 'react';
import './index.scss';
/**
 *  网络加载无序进度条
 */
export default class index extends Component{
    render(){
        return(
                <div className={this.props.show ? "loading_line_fix_layou" : "loading_line_fix_none"}>
                   <div className="loader_lines_fix_line">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                </div>
            
            )
                
        
    }
}