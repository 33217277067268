import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    source = ['微信公众号', '新浪微博', '头条号', '企鹅号']
    render() {
        return(
            <div 
                className="scroll_btn_box"
            >
                <div onClick={() => {
                    this.props.clickBtn && 
                    this.props.clickBtn()
                    console.log('aksjdhkjashd')
                }}>
                    <img src={ImageSet.homeScroll}/>
                </div>
                <div className="box_btn"></div>
                <div className="box_btn"></div>
                <div className="box_btn"></div>
                <div className="box_btn"></div>
                <div className="box_btn"></div>
            </div>
        )
    }
}
export default Index