import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import VulcanLoginCom from '../../component/vulcanLogin'
import {Prompt, Link} from 'react-router-dom';
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        data: ['首页', '热点', 'AI工具', '我的文章', '会员特权'],
        activeIndex: 0   
    }
    render() {
        const {SearchStore, HotDataStore, userStore, ArticleStore} = this.props.store
        console.log(userStore.isLogin() + 'isLogin')
        const {isHome, showTxt, isDeepColor} = this.props
        const styleHome = {
            boxShadow: 'none',
            background: 'rgba(0,0,0,.6)',
            color:'#fff'
        }
        const styleHomeDeep = {
            boxShadow: 'none',
            background: 'rgba(0,0,0,1)',
            color:'#fff'
        }
        return(
            <div 
                className="top_bar"
                style={isHome ? styleHome : {}}
                style={
                    isHome ?
                    (
                        isDeepColor ?
                        styleHomeDeep :
                        styleHome
                    ) : {}
                }
            >
                <div className="bar_reback">
                    {
                        isHome ?
                        <img 
                            src={ImageSet.logo}
                            style={{width:'60px',marginLeft:"4vw"}}
                        />:
                        <img 
                            src={ImageSet.Reback}
                            onClick={()=>{window.history.go(-1)}}
                            style={{width:'8px'}}
                        /> 

                    }
                </div>
                <div className="bar_box_main">
                    {
                        this.state.data.map((item, index) => {
                            return <div 
                            className="box_content"
                            key={`top${index}`}
                            style={
                                item == '会员特权' || (item == '我的文章' && !userStore.isLogin()) || item == 'AI工具'?
                                {cursor:'not-allowed',opacity:'.3'} :
                                {}
                            }
                            onClick={() => {
                                if(item == '热点'){
                                    window.open('/hot.html','_self')
                                } else if(item == '首页'){
                                    window.open('/','_self')
                                } else if(item == '我的文章' && userStore.isLogin()){
                                    window.open('/myArticle.html','_self')
                                } 
                                // else if(item == 'AI工具' && userStore.isLogin()){
                                //     window.open('/AI.html','_self')
                                // }
                                
                            }}
                        >
                            {/* <Link
                            //    to='/editor.html'
                               to={
                                item == '热点' ?
                                '/hot.html' :
                                (
                                    item == '首页' ?
                                    '/' :
                                    (
                                        item == '我的文章' ?
                                        '/myArticle.html' :
                                        (
                                            item == '会员特权' ?
                                            '/vip.html' : '/'
                                        )
                                    )
                                )
                               }
                            > */}
                                <div 
                                className="bar_box_txt"
                                style={
                                    showTxt == item ?
                                    {color: 'rgb(237, 97, 70)',fontWeight:'bold'} : {}
                                }
                            >
                                {item}
                            </div>
                            <div 
                                className="bar_box_active"
                                style={
                                    showTxt == item ?
                                    {opacity: '1'} : {opacity: '0'}
                                }
                            ></div>
                                
                            {/* </Link> */}
                            
                            
                        </div>
                        })
                    }
                    <div 
                        className="box_main_add"
                        style={
                            !userStore.isLogin() ?
                            {cursor:'not-allowed',opacity:"0.3"} :
                            {}
                        }
                        onClick={
                            () => {
                                userStore.isLogin() &&
                                window.open('/editor.html','_self')
                                // 新建文章——开启保存新文章
                                ArticleStore.articleSaveStatus = false
                                ArticleStore.saveNum = 0
                            }
                        }
                    >
                        <img src={ImageSet.addArticle}/>
                        新建文章
                    </div>
                    {
                        isHome ?
                        <div 
                            className="home_search_ani"
                            style={
                                !userStore.isLogin() ?
                                {cursor:'not-allowed',opacity:"0.3"} :
                                {}
                            }
                            onClick={() => {
                                userStore.isLogin() &&
                                this.props.clickSearch &&
                                this.props.clickSearch()
                            }}
                        >
                            <img src={ImageSet.searchAni}/>
                        </div> :
                        ''
                    }
                </div>
                <div className="bar_user">
                {/* <img src="http://thirdwx.qlogo.cn/mmopen/iahdQicCC5VBQrLNJ3ictmGoOMpPmjhlZYsvYgexnFlmdjf8icSvh9GAjnmibcS93Zasz9hCM2EdJiaMsQia9qJDtltUF4yOMpvg0DdZvnXzIHRtPY/132" className="header_user"/> */}
                    <VulcanLoginCom/>
                </div>
            </div>
        )
    }
}
export default Index