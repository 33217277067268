import React from 'react';
import {observer, inject} from 'mobx-react';
import {Prompt, Link} from 'react-router-dom';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    btns = ['编辑', '删除']
    state = {
        isShowOperate: false,
        operateIndex: -1
    }
    render() {
        return(
            <div 
                className="myArticle_box"
            >
                <Link 
                    to='/editor.html'
                    className="myArticle_box_title"                
                >
                    <div 
                        className="myArticle_box_title"
                        onClick={() => {
                                this.props.dropEditor &&
                                this.props.dropEditor()
                        }}
                    >
                        {this.props.title}
                    </div>
                </Link>
               
               <Link  to='/editor.html'>
                    <div 
                        className="myArticle_box_content"
                        dangerouslySetInnerHTML={{__html: this.props.content}}
                        onClick={() => {
                                this.props.dropEditor &&
                                this.props.dropEditor()
                        }}
                    >
                            {/* {this.props.content} */}
                    </div>
               </Link>
               
               <div className="myArticle_box_time_operate">
                    <div>{this.props.time}</div>
                    <div className="myArticle_operate_main">
                        <div
                            style={{color:'#000',fontWeight:'700'}}
                            onClick={(e) => {
                                e.stopPropagation()
                                this.setState({
                                    isShowOperate:!this.state.isShowOperate
                                })
                            }}
                            className="operate_main_img"
                        >
                            . . .
                        </div>
                        {
                            this.state.isShowOperate ?
                            <div 
                                className="operate_main_modal"
                            >
                                {
                                    this.btns.map((item, index) => {
                                        return (
                                            <div
                                                className="modal_btn"
                                                key={`operate_btn${index}`}
                                                onClick={() => {
                                                    this.setState({
                                                        isShowOperate: false
                                                    })
                                                    if(item == '删除'){
                                                        this.props.deleteProp &&
                                                        this.props.deleteProp()
                                                    }
                                                    if(item == '编辑'){
                                                        this.props.dropEditor &&
                                                        this.props.dropEditor()
                                                    }
                                                }}
                                            >
                                                {/* {item} */}
                                                {
                                                    item == '编辑'?
                                                    <Link  
                                                        to='/editor.html' 
                                                        // style={{color:'#AAAAAA'}}
                                                        className="modal_btn_link"
                                                    >编辑</Link> :
                                                    item
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div> : ""
                        }
                        
                    </div>
               </div>
            </div>
        )
    }
}
export default Index