import React from 'react';
import {observer, inject} from 'mobx-react';
import './index.scss'
import TweenMax from './TweenMax.js'
import TweenLite, { TweenPlugin, Ease, Power0, Power1, Power2, Power3, Power4, Linear } from "./TweenLite.js";
import TimelineMax from './TimelineMax.js'
import AnimationTxtCom from '../../component/animationTxt'
import ImageSet from '../../utils/image'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        viewBoxWidth: 0,
        viewBoxHeight: 0,
        arc1_startAngle: 10,
        arc1_endAngle: 240,
        arc2_startAngle: 190,
        arc2_endAngle: 420,
    }

    pathArc1 = ''
    pathArc2 = ''
    outerRad = ''
    // pathArc1 = describeArc(1920 / 2, 500 / 2, 100, this.state.arc1_startAngle, this.state.arc1_endAngle)
    // pathArc2 = describeArc(1920 / 2, 500 / 2, 140, this.state.arc2_startAngle, this.state.arc2_endAngle)
    // outerRad = 1920 / 1.4
    
    render() {
        return(
            <div className="test_page">
                <div className="page_svg">
                    <div className="screen-first">
                        <div 
                          id="bg" 
                          className="bg"
                        >
                          <img src={ImageSet.homeAni}/>
                        </div>
                        <svg id="frontMaskSvg" className="front" viewBox={`0 0 ${this.state.viewBoxWidth} ${this.state.viewBoxHeight}`} preserveAspectRatio="none">

                            <defs>
                                <mask id="frontMask" x="0" y="0" width="100%" height="100%">

                                    <rect x="0" y="0" width="100%" height="100%" fill="#fff"/>
                                    <path id="arc1" d={this.pathArc1} fill="none" stroke="#000" strokeWidth="28" />
                                    <path id="arc2" d={this.pathArc2} fill="none" stroke="#000" strokeWidth="18" />
                                    
                                    <circle id="c2" cx={this.state.viewBoxWidth/2} cy={this.state.viewBoxHeight/2} r="100" fill="#000"/>
                                    <circle id="c1" cx={this.state.viewBoxWidth/2} cy={this.state.viewBoxHeight/2} r="100" fill="#fff"/>

                                </mask>
                            </defs>

                            <rect 
                                mask="url(#frontMask)" 
                                x='0'
                                y='0' 
                                width="100%" 
                                height="100%" 
                                fill="#fff"
                                className="rect_mask_box"
                            />

                        </svg>
                    </div>

                    <div className="screen-second">
                    <div className="text">
                      <div id="title" className="title">
                          <AnimationTxtCom txt="智能匹配·只为内容生产者 "/>
                      </div>
                      <div id="text" className="descr">
                      <AnimationTxtCom txt="内容挖掘、撰写、管理、分发、追踪，助您高效有力"/>
                      </div>
                    </div>
                  </div>                   
                </div>
            </div>
        )
    }
    componentDidMount(){
        this.setViewbox()
        this.animateAll()
    }
    // 测试svg
    setViewbox() {
        const {HomeStore} = this.props.store
        HomeStore.getWindowWidth()
        HomeStore.getWindowHeight()
        this.setState({
            viewBoxWidth: HomeStore.windowWidth,
            viewBoxHeight: HomeStore.windowHeight
        })
        this.pathArc1 = describeArc(HomeStore.windowWidth / 2, HomeStore.windowHeight / 2, 100, this.state.arc1_startAngle, this.state.arc1_endAngle)
        this.pathArc2 = describeArc(HomeStore.windowWidth / 2, HomeStore.windowHeight / 2, 140, this.state.arc2_startAngle, this.state.arc2_endAngle)
        this.outerRad = HomeStore.windowWidth / 1.4
    }
  
    setViewboxDebounced() {
        return debounce(this.setViewbox, 200);
    }
    animateAll() {
        let vm = this;
       
        const tl = new TimelineMax();
  
        let arc1point = {
          start: vm.state.arc1_endAngle,
          finish: 360 + vm.state.arc1_startAngle - .01
        }
  
        let arc2point = {
          start: vm.state.arc2_endAngle,
          finish: vm.state.arc2_startAngle 
        }
        tl.set(['#c1', '#c2'], { autoAlpha: 0 }).
  
        set('#frontMaskSvg', { autoAlpha: 1 }).
  
        set({}, {}, .5)
        .
        add('startMotion').
  
        to(arc1point, 1, {
          start: arc1point.finish,
          ease: Power4.easeInOut,
          onUpdate() {
            vm.setState({
                arc1_endAngle: arc1point.start
            })
          } },
        'startMotion').
  
        to('#arc1', 0, { autoAlpha: 0 }).
        to('#c1', 0, { autoAlpha: 1 }).
  
        to(arc2point, 1, {
          start: arc2point.finish,
          ease: Power4.easeInOut,
          onUpdate() {
            vm.setState({
                arc2_endAngle: arc2point.start
            })
          } },
        'startMotion').
        to('#arc2', 0, { autoAlpha: 0 }).
        to('#c2', 0, { autoAlpha: 1 }).
  
        add('outMotion').
  
        to('#c1', .75, {
          ease: Power4.easeInOut,
          attr: {
            r: 0 } },
  
        'outMotion').
        to('#c2', .75, {
          ease: Power4.easeInOut,
          attr: {
            r: vm.outerRad } },
  
        'outMotion').
  
        add('bgMotion')
        .fromTo('#title', 1, {
          x: 150
        }, {
          ease: Power2.easeInOut,
          x: 0,
          color: '#fff'
        }, 'outMotion')
        .fromTo('#text', 1, {
          x: -15,
          autoAlpha: 0
        }, {
          ease: Power2.easeInOut,
          x: 0,
          autoAlpha: 1
        }, 'outMotion')
        // turn off the whole svg
        .to('#frontMaskSvg', 0, {
          autoAlpha: 0,
          onComplete() {
            window.removeEventListener('resize', vm.setViewboxDebounced);
          } }).
  
  
        to('#bg', 12, {
          scale: 1,
          ease: Power1.easeOut },
        'bgMotion-=.5');
  
      }
}
export default Index



function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
  
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians) };
  
}
  
function describeArc(x, y, radius, startAngle, endAngle) {
  
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);
  
    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  
    var d = [
    "M", start.x, start.y,
    "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].
    join(" ");
  
    return d;
  }
  
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
