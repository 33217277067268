import {observable, action} from 'mobx';
import api from '../utils/Api'
class articleS{
    @observable token = "";
    @action setToken(t){
        this.token = t;
    }
    @observable page = 1
    @observable pageCount = 11
    @observable myArticleDataList = []
    @observable articleSaveStatus = false// false-保存文章；true-更新文章
    @observable isCreateArticle = false// 是否创建新文章
    @observable activeTitle = ''
    @observable activeContent = ''
    @observable activeId = ''
    @observable acticleStatusTxt = ''
    @action async getMyArticleList(){
        let result = await api.getMyArticleList(this.token, this.page, this.pageCount);
        if(result && result.code == 200){
            this.myArticleDataList = result.data.list
        }
    }
    @action async getMoreMyArticleList(){
        const pageMore = this.page + 1
        let result = await api.getMyArticleList(this.token, pageMore, this.pageCount);
        if(result && result.code == 200){
            this.page++
            this.myArticleDataList = this.myArticleDataList.concat(result.data.list)
        }
    }
    @action async saveArticle(title, content){
        this.acticleStatusTxt = '正在保存...'
        let result = await api.saveDocument(title, content, this.token);
        if(result && result.code == 200){
            this.articleSaveStatus = true
            this.acticleStatusTxt = '已自动保存'
            this.activeId = result.data
        }
    }
    @action async editorMyArticle(title, content, id){
        this.acticleStatusTxt = '正在保存...'
        let res = await api.editorArticle(id, title, content, this.token)
        if(res && res.code == 200){
            this.acticleStatusTxt = '已自动保存'
        }
    }
    @action deleteMyArticle(id){
        api.deleteArticle(id, this.token)
    }
    // 保存或更新
    @observable saveNum = 0
    @action async saveOrUpdateArticle(){
        var title = this.activeTitle
        var id = this.activeId
        var content = this.activeContent
        if(this.articleSaveStatus){// 已经保存执行更新
            await this.editorMyArticle(title, content, id)
        } else if(this.saveNum != 1){// 开始保存_避免重复保存
            this.saveNum = 1
            await this.saveArticle(title, content);
        }

    }
}
const articleStore = new articleS();
export default articleStore