import React from 'react';
import './index.scss';
import ImageSet from '../../utils/image'
import { observer , inject } from 'mobx-react';
/**
 * 资讯列表组件
 */
@inject("store")
@observer
class Index extends React.Component{
    words = [1,1,1,1,1]
    state = {
        isOpenSummmary: false
    }
    render(){
        return(
            <div className="item_search_list_main">
               <div className="list_main">
                   <div 
                    className="list_main_title"
                    dangerouslySetInnerHTML={{__html: this.props.title}}
                   >
                   {/* {this.props.title || '无'} */}
                   </div>
                   <div className="list_main_others">
                       <div className="others_time">
                      {this.props.date || '无'}
                       </div>
                       <div className="others_source">
                          {this.props.source || '无'}
                       </div>
                   </div>
                   <div className="list_main_content">
                       <div className="main_content_img">
                           <img src={ImageSet.type1}/>
                       </div>
                       <div className="main_content_txts">
                            <div 
                                className="txts_content"
                                dangerouslySetInnerHTML={{__html: this.props.content}}
                            >
                                {/* {
                                    this.props.content && 
                                    this.props.content.length > 200 ?
                                    this.props.content.slice(0,200) :
                                    this.props.content
                                } */}
                                {/* <div className="txts_modal_btn">
                                    <div 
                                        className="modal_btn_txt"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            this.setState({
                                                isOpenSummmary: !this.state.isOpenSummmary
                                            })
                                        }}
                                    >
                                        {
                                            this.state.isOpenSummmary ?
                                            '收起' : 
                                            '摘要内容'
                                        }
                                    </div>
                                    <img src={
                                            this.state.isOpenSummmary ?
                                            ImageSet.orangeUp :
                                            ImageSet.orangeDown
                                    }/>
                                </div> */}
                            </div>
                       </div>
                   </div>
                   {/* 摘要 */}
                   {/* <div 
                    className="main_summary"
                    style={
                        this.state.isOpenSummmary ?
                        {display:'flex'} :
                        {display: 'none'}
                    }
                   >
                       <div className="summmary_modal">
                           <div className="summary_title">
                               核心内容
                               <span>由AI只能提取</span>
                           </div>
                           <div className="summary_list">
                               <ul>
                                   <li>
                                   成都26岁女教师与之容在成都闹市
                                   </li>
                                   <li>
                                   成都26岁女教师与之容在成都闹市
                                   </li>
                                   <li>
                                   成都26岁女教师与之容在成都闹市
                                   </li>
                                   <li>
                                   成都26岁女教师与之容在成都闹市
                                   </li>
                               </ul>
                           </div>
                       </div>
                   </div> */}
                   {/* 关键词 */}
                   <div className="main_keywords_box">
                        {
                            (this.props.keywords ? this.props.keywords : '["无"]').map((item, index) => {
                                return <div
                                    className="keyword_txt"
                                    key={`keyw${index}`}
                                >
                                    #{
                                        item && item.length > 12 ?
                                        item.slice(0,12) :
                                        item
                                    }
                                   
                                </div>
                            })
                        }
                   </div>
               </div>
            </div>
        )
    }

    
    
    

}
export default Index