import React from 'react';
import {observer, inject} from 'mobx-react';
import {DraggableArea} from 'react-draggable-tags';
import ImageSet from '../../utils/image'
// import LoadingCom from '../../component/loading'
import LoadingCom from '../../component/loadingLinesWin'
import AudioSplitVideoCom from '../../component/audioSplitVideo'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        isOpenAudioList: false,
        activeFusionAudioIndex: 0
    }
    arr = [1,1,1,1,11,11,1,1,1]
    tagsList = [
        {
            'text': '99999999'
        },
        {
            'text': '99999999'
        },
        {
            'text': '99999999'
        },
    ]
    render(){
        const { asStore } = this.props.store
        const { tagsList, fusionLoading } = asStore
       return  <div className="add_audio_split_two">
           <LoadingCom show={fusionLoading}/>
           {
               this.state.isOpenAudioList ?
               this.renderModalAudioLists() : ""
           }
           <div className="split_two_reback">
               <div 
                    className="reback_btn"
                    onClick={() => {
                        this.props.closeDrgg && this.props.closeDrgg()
                    }}
               >
                   上一步
                </div>
           </div>
           <div className="split_two_content">
                <div className="pslit_two_content_main">
                    <div className="content_main_title">
                        <div className="title_box">分段序列</div>
                        <div className="title_box">开始-结束</div>
                        <div className="list_box_txts">文字内容</div>
                    </div>
                    <div className="content_main_list">
                        {
                            tagsList.map((item, index) => {
                                return <div
                                    className="content_list_box"
                                    key={`content_list_box${index}`}
                                >
                                    <div className="title_box">{index + 1}</div>
                            <div className="title_box">{parseInt(item.tag.startTime)} - {parseInt(item.tag.endTime)}</div>
                                    <div className="list_box_txts">
                                        {item.tag.labelText}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
           </div>
           <div className="split_two_draggle">
                <DraggableArea
                    tags={tagsList}
                    render={({tag, index}) => (         
                        <div 
                            key={`tagList${index}`} 
                            className='drggle_list'
                        >
                            <img 
                            className="tags_delete"
                            src={ImageSet.deleteIcon}
                            onClick={() => {
                                this.handleClickDeleteTag(tag)
                            }}
                            />
                            {tag.tag.labelText}
                        </div>
                    )}
                    onChange={tags => asStore.addTags(tags)}
                />
           </div>
           <div className="split_two_synthesis">
               <div 
                    className="synthesis_btn"
                    onClick={() => {
                        this.setState({
                            isOpenAudioList: true
                        })
                        this.clickFusionAudios()
                    }}
               
               >合成</div>
           </div>
       </div>
    }
    renderModalAudioLists(){
        const that = this
        const { asStore } = this.props.store
        const { fusionAudiosMp3Arr } = asStore
        const { activeFusionAudioIndex }  = this.state
        return <div
            className="render_modal_audio_lists"
        >
            <div className="audio_lists_main">
                <div className="lists_main_close">
                    <div
                        onClick={() => {
                            that.setState({
                                isOpenAudioList: false
                            })
                        }}
                    >
                        <img src={ImageSet.closeBlack}/>
                    </div>
                </div>
                <div className="lists_main_main">
                    <div className="main_main_left">
                        <div className="left_audio_title">合成音频</div>
                        <div className="left_all_audio">
                            {
                                fusionAudiosMp3Arr &&
                                fusionAudiosMp3Arr.length > 0 &&
                                fusionAudiosMp3Arr.map((item, index) => {
                                    return <div
                                        className="all_audio_list"
                                        style={
                                            index == activeFusionAudioIndex ?
                                            {background:'#F7F7F7'} : {}
                                        }
                                        key={`all_audio_list2${index}`}
                                        onClick={() => {
                                            that.setState({
                                                activeFusionAudioIndex: index
                                            })
                                        }}
                                    >
                                        <div className="list_name">
                                            <div className="audio_name">
                                                合成{index + 1}
                                            </div>
                                            <div className="audio_time">
                                                5::00
                                            </div>
                                        </div>
                                        <div className="list_summary">      
                                            {item}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className="main_main_right">
                        <div className="right_content">
                        <div className="content_audio_name">合成音频{activeFusionAudioIndex + 1}</div>
                            <div className="right_main_content">
                                
                                <div className="content_title">文字内容</div>
                                <div className="content_txts">
                                    {fusionAudiosMp3Arr[activeFusionAudioIndex]}
                               </div>  
                            </div>
                        </div>

                        <div className="right_audio_playing">
                            {/* <audio  
                            className="play_audio_mp3" 
                            src={fusionAudiosMp3Arr[activeFusionAudioIndex]} 
                                controls
                            /> */}
                            <AudioSplitVideoCom
                                srcSplit={fusionAudiosMp3Arr[activeFusionAudioIndex]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    // 删除音频片段
    handleClickDeleteTag(tag){
        const {asStore, userStore} = this.props.store;
        // 合成列表里面删除
        asStore.tagsList = asStore.tagsList.filter(t => tag.id !== t.id);
        // 遍历生成器list ，删除里面的列表
        asStore.adapterList.map((item, index) => {
            item.tagsList = item.tagsList.filter(it=>{
                return it.tag.labelText != tag.tag.labelText && it.tag.startTime != tag.tag.startTime && it.tag.endTime != tag.tag.endTime
            })
        })
    }
    // 多个音频片段合成新的音频
    clickFusionAudios(){ 
        // this.allGoFileTime()
        this.getFusionData()// 音频导入的时候进行上传操作
    }
    allGoFileTime(){
        let {asStore, userStore} = this.props.store;
        let fileStatue = [];
        let _that = this;
        _that.sumbitNoUpFile()
        let all_timer = setInterval(function(){
            fileStatue = [];
            asStore.adapterList.map((item, index) => {
                if(!item['isUploadFile']){
                    fileStatue.push(['no'])
                }
            })
            if(fileStatue.length == 0){
                console.log('文件已经全部上传完成')
                clearInterval(all_timer)
                _that.getFusionData()
            }
        },1000)
        
    }
    async getFusionData(){
        // 获取合成音频所需参数
        let {asStore, userStore} = this.props.store;
        let fusionDatas = [];
        let that = this;
        asStore.tagsList.map((item, index) => {
            fusionDatas.push({
                'id': item.fileId,
                'startTime': that.handleFormatDate(item.tag.startTime),
                'endTime': that.handleFormatDate(item.tag.endTime)
            })
        })
        // 调接口
        console.log(fusionDatas)
        await asStore.fusionAudiosFile(fusionDatas);
        this.setState({
            isShowAudio: true,
            isAudioLoading: false
        })
    }
    // 先上传文件，如果文件以及上传，可以不用上传
    sumbitNoUpFile(){
        let {asStore, userStore} = this.props.store;
        let token = userStore.token;
        asStore.adapterList.map((item, index) => {
            if(!item['isUploadFile']){//未上传文件
                asStore.adapterList[index].upLoadFile(token);
            }
        })
    }
    handleFormatDate(mss){// fmt——秒单位
        var hours = parseInt((mss % (60 * 60 * 24)) / (60 * 60));
        if(hours < 10) {
            hours = "0" + hours;
        }
        var minutes = parseInt((mss % (60 * 60)) / (60));
        if(minutes < 10) {
            minutes = "0" + minutes;
        }
        var seconds = Math.round(mss % (60));
        if(seconds < 10) {
            seconds = "0" + seconds;
        }
        return hours + ':' + minutes + ':' + seconds;
        
    }
}
export default Index