import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        isShowModal: false,
        // 是否暂停状态
        isPause: false,
        // 当前音乐列表
        musicList: '',
        // 当前音乐
        currentMusic: {
            id: "1",
            title: "打上花火",
            info: "DAOKO,米津玄師",
            resource: "http://sc1.111ttt.cn/2018/1/03/13/396131229550.mp3",
            img:
              "http://singerimg.kugou.com/uploadpic/softhead/400/20160913/20160913140233132.jpg"
        },
        // 总时间
        totalTime: "00:00",
        // 当前播放时间
        currentTime: "00:00",
        // 进度条item是否可拖动
        processItemMove: false,
        // 进度条item是否可拖动
        volumeProcessItemMove: false,
        // 音量控制显示
        volumeControl: false,
    }
    componentDidMount(){
        const audio = this.audio;
        // 这里需要设置audio的canplay事件监听
        audio.addEventListener("canplay", () => {
        //获取总时间
        const totalTime = parseInt(audio.duration);
        this.setState({
            totalTime: this.getTime(totalTime)
        });
        });
        // 播放中添加时间变化监听
        audio.addEventListener("timeupdate", () => {
        const { processItemMove } = this.state;
        //获取当前播放时间
        const currentTime = parseInt(audio.currentTime);
        // 缓存对象
        const buffered = audio.buffered;
        // 当前缓存时间
        let bufferTime = 0;
        if (buffered.length !== 0) {
            bufferTime = buffered.end(buffered.length - 1);
        }
        // 当前缓存缓存宽度计算 500是进度条宽度
        const bufferWidth = 90 * (bufferTime / audio.duration);
        // 当前播放宽度计算 500是进度条宽度
        const playWidth = 90 * (audio.currentTime / audio.duration);
        // 如果正在拖动进度条的时候，不监听播放进度
        if (!processItemMove) {
            this.processPlayed.style.width = `${playWidth}px`;
            this.processItem.style.left = `${playWidth - 4}px`;
            // 未拖动时根据时间变化设置当前时间
            this.setState({
            currentTime: this.getTime(currentTime)
            });
        }
        this.processBuffered.style.width = `${bufferWidth}px`;
        });

        // 当前音乐播放完毕监听
        audio.addEventListener("ended", () => {
        this.endedPlayMusic();
        });
        // 初始化音量
        this.initVolumeProcess();
    }
    // 秒转换-分:秒的格式
    getTime = time => {
        if (time) {
        const minute = parseInt((time / 60) % 60);
        const second = parseInt(time % 60);
        let minuteText = `${minute}`;
        let secondText = `${second}`;
        if (minute < 10) {
            minuteText = `0${minute}`;
        }
        if (second < 10) {
            secondText = `0${second}`;
        }
        return `${minuteText}:${secondText}`;
        } else {
        return "00:00";
        }
    }
    // 播放
    onPlay = () => {
        const {EditorStore} = this.props.store
        const audio = this.audio;
        this.setState({ isPause: true });
        EditorStore.isPlayVideo = true
        audio.play();
    }

    // 暂停
    onPause = () => {
        const audio = this.audio;
        const {EditorStore} = this.props.store
        EditorStore.isPlayVideo = false
        this.setState({ isPause: false });
        audio.pause();
    }
    // 点击进度条
    onProcessClick = e => {
        this.setProcess(e, "click");
    }
    // 设置进度条进度
    setProcess = (e, key) => {
        // 获取当前点击偏移宽度
        let offsetWidth = e.pageX - this.processPlayed.getBoundingClientRect().left;
        // 需要限制拖动范围，不能超出左右边界
        if (offsetWidth < 0) {
        offsetWidth = 0;
        }
        if (offsetWidth > this.process.offsetWidth) {
        offsetWidth = this.process.offsetWidth;
        }
        // 计算偏移比例
        const offsetPercentage = offsetWidth / this.process.offsetWidth;
        // 计算当前时间
        const currentTime = this.audio.duration * offsetPercentage;
        if (key === "click" || key === "dragMove") {
        // 设置当前进度条偏移位置
        this.processPlayed.style.width = `${offsetWidth}px`;
        this.processItem.style.left = `${offsetWidth - 4}px`;
        this.setState({ currentTime: this.getTime(currentTime) });
        }
        // 设置当前音乐进度 拖拽不需要及时计算播放进度，会导致音乐像快进一样的效果，体验很差，点击进度条是需要及时设置当前播放进度的
        if (key === "dragEnd" || key === "click") {
        this.audio.currentTime = currentTime;
        }
    }
    //  进度条item MouseDown
    onProcessItemMouseDown = e => {
        // 阻止事件冒泡
        e.stopPropagation();
        // 按下后置item为可拖动状态
        this.setState({ processItemMove: true });
    }
    //  进度条item MouseMove
    onProcessItemMouseMove = e => {
        // 阻止事件冒泡
        e.stopPropagation();
        const { processItemMove } = this.state;
        if (processItemMove) {
        this.setProcess(e, "dragMove");
        }
    }
    //  进度条item MouseUp
    onProcessItemMouseUp = e => {
        const { processItemMove } = this.state;
        // 阻止事件冒泡
        e.stopPropagation();
        // 这里的判断是关键，一定要判断是处于processItemMove=true的状态，表示当前正在拖动进度条，不然会导致mouseUp和onClick事件的传播问题
        if (processItemMove) {
        this.setState({ processItemMove: false });
        // 松开后置item为禁止拖动的状态
        this.setProcess(e, "dragEnd");
        }
    }
    endedPlayMusic(){// 结束
        this.onPause()
    }
    // 音量控制条显示隐藏
    onVolumeControl = () => {
        const { volumeControl } = this.state;
        this.setState({ volumeControl: !volumeControl });
    }

    // 隐藏音量设置条
    onVolumeControlHide = () => {
        const { volumeControl } = this.state;
        if (volumeControl) {
        this.setState({ volumeControl: false });
        }
    }
    // 初始化音量
    initVolumeProcess = () => {
        // 获取当前音量条高度
        const processLength = this.volumeProcess.offsetHeight;
        // 设置进度条
        this.volumeProcessCurrent.style.height = `${processLength / 2}px`;
        // 设置进度条item
        this.volumeProcessItem.style.bottom = `${processLength / 2 - 6}px`;
        // 设置音量
        this.audio.volume = 0.5;
    }

    // 音量控制条点击
    onVolumeProcessSet = e => {
        // 获取当前音量条高度
        const processLength = this.volumeProcess.offsetHeight;
        // 获取当前点击偏移量
        let volumeOffsetHeight =
        processLength -
        (e.pageY - this.volumeProcess.getBoundingClientRect().top);
        // 当前音量进度比例
        let volumepercentage = 0;
        if (volumeOffsetHeight < 0) {
        volumeOffsetHeight = 0;
        }
        if (volumeOffsetHeight > processLength) {
        volumeOffsetHeight = processLength;
        }
        volumepercentage = volumeOffsetHeight / processLength;
        // 设置进度条
        this.volumeProcessCurrent.style.height = `${volumeOffsetHeight}px`;
        // 设置进度条item
        this.volumeProcessItem.style.bottom = `${volumeOffsetHeight - 6}px`;
        // 设置音量
        this.audio.volume = volumepercentage;
    }
    // 音量item鼠标按下方法监听
    onVolumeProcessItemMouseDown = () => {
        // 设置当前进入可拖动状态
        this.setState({ volumeProcessItemMove: true });
    };

    // 音量item鼠标抬起方法监听
    onVolumeProcessItemMouseUp = e => {
        const { volumeProcessItemMove } = this.state;
        if (volumeProcessItemMove) {
        this.setState({ volumeProcessItemMove: false });
        }
    };

    // 音量item鼠标拖拽方法监听
    onVolumeProcessItemMove = e => {
        const { volumeProcessItemMove } = this.state;
        if (volumeProcessItemMove) {
        this.onVolumeProcessSet(e);
        }
    };
    render() {
        const {
            currentMusic,
            isPause,
            totalTime,
            currentTime,
            volumeControl,
            playMode,
            isMusicListShow
          } = this.state;
          const { title, info, img, resource, id } = currentMusic
        return(
           <div className="audio_box_box">
                <div 
                    className="audio_box_main"
                >
                    {/* 播放按钮 */}
                    <div className="left_control">
                        {isPause ? (
                            <div className="playIcon" onClick={this.onPause}>
                                <img src={ImageSet.playing}/>
                            </div>
                        ) : (
                            <div className="playIcon" onClick={this.onPlay}>
                                <img src={ImageSet.pauseing}/>
                            </div>
                        )}
                    </div>
                    {/* 进度条和时间 */}
                    <div className="mid_control">
                        <div className="process-time">
                            <div className="time">
                            <span className="current-time">{currentTime}</span>
                            </div>
                            <div
                                className="process-wrapper"
                                onClick={this.onProcessClick}
                                ref={ref => (this.process = ref)}
                            >
                                <div className="process">
                                <div
                                    className="progress-buffered"
                                    ref={ref => (this.processBuffered = ref)}
                                />
                                <div
                                    className="progress-played"
                                    ref={ref => (this.processPlayed = ref)}
                                >
                                    <div
                                    className="process-item"
                                    ref={ref => (this.processItem = ref)}
                                    onMouseDown={this.onProcessItemMouseDown}
                                    onMouseUp={this.onProcessItemMouseUp}
                                    >
                                    <div className="process-item-inside" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="time">
                            <span className="total-time">{totalTime}</span>
                        </div>
                        </div>
                    </div>
                    {/* 音量 */}
                    {/* <div className="right_control">
                        <div
                            className="volume-controler"
                            style={{ visibility: volumeControl ? "visible" : "hidden" }}
                            onMouseMove={this.onVolumeProcessItemMove}
                            onMouseUp={this.onVolumeProcessItemMouseUp}
                        >
                            <div
                            className="volume-process"
                            onClick={this.onVolumeProcessSet}
                            ref={ref => (this.volumeProcess = ref)}
                            >
                            <div
                                className="volume-current"
                                ref={ref => (this.volumeProcessCurrent = ref)}
                            >
                                <div
                                className="volume-item"
                                ref={ref => (this.volumeProcessItem = ref)}
                                onMouseDown={this.onVolumeProcessItemMouseDown}
                                onMouseUp={this.onVolumeProcessItemMouseUp}
                                >
                                <div className="volume-item-inside" />
                                </div>
                                </div>
                            </div>

                            </div>
                        <div
                            className="volume"
                            onClick={this.onVolumeControl}
                        >
                            <img src={ImageSet.playVideo}/>
                        </div>
                    </div> */}
                    {/* 播放器基础组件 */}
                    <audio src={this.props.mp3Src} ref={ref => this.audio = ref} />
                </div>

                <div className="audio_box_others">
                    <div className="right_control">
                        <div
                            className="volume-controler"
                            style={{ visibility: volumeControl ? "visible" : "hidden" }}
                            onMouseMove={this.onVolumeProcessItemMove}
                            onMouseUp={this.onVolumeProcessItemMouseUp}
                        >
                            <div
                            className="volume-process"
                            onClick={this.onVolumeProcessSet}
                            ref={ref => (this.volumeProcess = ref)}
                            >
                            <div
                                className="volume-current"
                                ref={ref => (this.volumeProcessCurrent = ref)}
                            >
                                <div
                                className="volume-item"
                                ref={ref => (this.volumeProcessItem = ref)}
                                onMouseDown={this.onVolumeProcessItemMouseDown}
                                onMouseUp={this.onVolumeProcessItemMouseUp}
                                >
                                <div className="volume-item-inside" />
                                </div>
                                </div>
                            </div>

                            </div>
                        <div
                            className="volume"
                            onClick={this.onVolumeControl}
                        >
                            <img src={ImageSet.playVideo}/>
                        </div>
                    </div>
                </div>
           </div>
        )
    }
}
export default Index