import React from 'react';
import {observer, inject} from 'mobx-react';
import {Button, Modal, ModalFooter, ModalBody, ModalTitle, ModalHeader} from 'react-bootstrap'
import ImageSet from '../../utils/image'
import AudioBoxCom from '../audioBox'
import BtnTipsCom from '../btnTips'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
   state = {
       isShowModal: false,
       phoneSize: 0
   }
   size = ['5.5寸', '5.0寸', '4.5寸']
    render() {
        const {EditorDraftStore, ArticleStore} = this.props.store
        return(
            <div 
                className="preview_modal_main"
            >
                
                <div 
                    onClick={() => {
                            this.setState({
                                isShowModal: true
                            })
                        EditorDraftStore.articleToAudio()
                    }}
                    style={{fontSize:'14px'}}
                >
                  <BtnTipsCom txt="预览" imgUrl={this.props.imgUrl} isCircleTips={this.props.isCircleTips}/>
                </div>

                <Modal 
                    show={this.state.isShowModal}  
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >   
                    {this.renderPreviewModal()}
                </Modal>
            </div>
        )
    }
    renderPreviewModal(){
        const {EditorDraftStore, ArticleStore} = this.props.store
        return (
            <div className="render_preview_modal_main">
                <div className="preveiew_modal_box">
                    <div className="modal_box">
                        {/* <img src={ImageSet.prePhone}/> */}
                        <img src={
                            this.state.phoneSize == 1 ?
                            ImageSet.phone2 :
                            (
                                this.state.phoneSize == 2 ?
                                ImageSet.phone3 : ImageSet.prePhone
                            )
                        }/>
                    </div>
                    <div className="modal_box_main">
                        {
                            this.renderVideo()
                        }
                    </div>
                </div>
                <div className="preveiew_modal_box">
                    <div className="modal_box">
                        {/* <img src={ImageSet.prePhone}/> */}
                        <img src={
                            this.state.phoneSize == 0 ?
                            ImageSet.prePhone :
                            (
                                this.state.phoneSize == 1 ?
                                ImageSet.phone2 : 
                                (
                                    this.state.phoneSize == 2 ?
                                    ImageSet.phone3 :
                                    ''
                                )
                            )
                        }/>
                    </div>
                    <div className="modal_box_main">
                        {
                            this.renderArticle()
                        }
                    </div>
                </div>
                <div className="preview_modal_selects">
                    {
                        this.size.map((item, index) => {
                            return(
                                <div 
                                    className="selects_size"
                                    key={`phoneSize${index}`}
                                    style={
                                        this.state.phoneSize == index ?
                                        {background:'#fff', color:'#ED6146'} : {}
                                    }
                                    onClick={() => {
                                        this.setState({
                                            phoneSize: index
                                        })
                                    }}
                                >
                                    {item}
                                </div>
                            )
                        })
                    }
                    {/* <div className="selects_size">4.5寸</div>
                    <div className="selects_size">5.0寸</div>
                    <div className="selects_size">4.5寸</div> */}
                    <div 
                        className="selects_close"
                        onClick={() => {
                            this.setState({
                                isShowModal: false
                            })
                        }}
                    >
                        <img src={ImageSet.previewClose}/>
                        <div>退出</div>
                    </div>
                </div>
            </div>
        )
    }
    renderVideo(){
        const {EditorStore, ArticleStore, EditorDraftStore} = this.props.store
        return (
            <div className="render_video">
                {
                    this.renderTitle(ArticleStore.activeTitle)
                }
                <div className="video_circle">
                    <div className="circle_main">
                        <div className="main_img_cd">
                            <img 
                                src={ImageSet.playCD}
                                style={
                                    EditorStore.isPlayVideo ?
                                    {animation: 'que 4s infinite linear'} :
                                    {}
                                }
                            />
                        </div>
                        <div className="main_img_poster">
                            <img src={ImageSet.type1}/>
                        </div>
                        <div 
                            className="main_img_line"
                            style={
                                EditorStore.isPlayVideo ?
                                {transform: 'rotate(10deg)'} :
                                {}
                            }
                        >
                            <img src={ImageSet.playLine}/>
                        </div>
                    </div>
                </div>
                <div className="video_audio_main">
                    <AudioBoxCom mp3Src={EditorDraftStore.articleMp3}/>
                    <div className="video_audio_download">
                        {/* <img src={ImageSet.download} title="下载音频文件"/> */}
                        <a 
                            href={EditorDraftStore.articleMp3}
                            download="导出mp3文件"
                            rel = 'noopener noreferrer'
                            target="view_window"
                            // style={{marginLeft: 20}}
                        >
                            <img src={ImageSet.download} title="下载音频文件"/>
                        </a>
                    </div>
                    <div className="video_audio_bottom">
                        {
                            this.renderBottomTxt()
                        }
                    </div>
                </div>
                {/* <div className="video_audio_download">
                    <a 
                        href='http://sc1.111ttt.cn/2018/1/03/13/396131229550.mp3'
                        download="导出mp3文件"
                        rel = 'noopener noreferrer'
                    >
                        <img src={ImageSet.download} title="下载音频文件"/>
                    </a>
                </div> */}
                {/* {
                    this.renderBottomTxt()
                } */}
            </div>
        )
    }
    renderArticle(){
        const {EditorDraftStore, ArticleStore} = this.props.store
        return (
            <div className="render_article">
                {
                    this.renderTitle(ArticleStore.activeTitle)
                }
                <div 
                    className="article_main"
                    dangerouslySetInnerHTML={{__html: ArticleStore.activeContent}}
                > 
                </div>
                {
                    this.renderBottomTxt()
                }
            </div>
        )
    }
    renderTitle(propsTitle){
        return (
            <div className="render_title">
                <div className="title">{  propsTitle || '无标题'}</div>
                <div className="title_info">
                    <div>作者</div>
                    <div style={{color:'#ED6146'}}>公众号名称</div>
                    <div>2017-10-17</div>
                </div>
                <div className="title_line"></div>
            </div>
        )
    }
    renderBottomTxt(){
        return (
            <div className="render_bottom_txt">
                阅读2.0万
            </div>
        )
    }
   
  
}
export default Index