import {action, observable} from 'mobx'
import api from '../utils/Api';
import { IsPhone } from '../utils/utils';

class UserStore{
    @observable token = "";//登录标示
    @observable headUrl = "";//用户头像
    @observable login = "";//登录昵称
    @observable phone = "";//绑定的手机号 如果是空的说明未绑定手机号
    @observable url = "";//微信二维码图片地址
    @observable code = "";//微信二维码查询参数
    @observable isShowModal = false;  //控制登录界面是否显示
    @observable bindPhoneShow = false; //在登录界面显示的时候，控制是否需要绑定手机


    @observable bindPhone = null;
    @observable bindCode = null;
    @observable bindError = "";

    @observable userAgainurl = true;//是否同意用户协议

    @action userAgainurlFlag() {
        this.userAgainurl = !this.userAgainurl;
        return this.userAgainurl;
    }

    @observable bindTxt = "获取短信验证码"

    @action setBindPhone(phone) {
        this.bindPhone = phone;
    }

    @action setBindCode(code) {
        this.bindCode = code
    }

    timer = null;
    timerCode = null;
    // 退出登录
    @observable isOpenSignOut = false
    @action clearAll(){
        this.token = "";
        this.headUrl = "";
        this.login = "";
        sessionStorage.clear();
        window.open('/', '_self')
    }
    isLogin(){
        return this.token ? true : false
    }

    @action setShowModal(flag) {
        this.isShowModal = flag;       
        if(this.isShowModal) {
            //当登录注册界面可见的时候，开启轮询检查扫码状态
            this.getQrCodeState()
        }else {
            //当登录注册界面不可见的时候，关闭轮询
            this.clearMyInterval();
            this.bindPhoneShow = false;
            this.code = ""
            this.bindPhone = ""
            this.bindCode = ""
        }
    }

    @action async getPhoneCode(){
        //如果bindTxt不是数字 那么说明倒计时开始了，已经请求了短信发送 没个30秒可以请求一次
        if(this.bindTxt.indexOf('秒') <= -1) {
            if(IsPhone(this.bindPhone)) {
                this.bindTxt = 60 + "秒"
                this.timerCode = setInterval(()=>{
                    let t = parseInt(this.bindTxt.replace('秒'));
                    t -- ;
                    this.bindTxt = t + "秒"
                    if(t === 0) {
                        clearInterval(this.timerCode);
                        this.timerCode = null;
                        this.bindTxt = "获取短信验证码"
                    }
                }, 1000)


                const resut = await api.getPhoneCode(this.bindPhone);
                console.log(resut)
            }else {
                this.setBindError("请输入正确的手机号")
            }
        } else {

        }
        
    }

    @action setBindError(text){
        this.bindError = text
        setTimeout(()=>{
            this.bindError = ""
        }, 1500)
    }
    /**
     * 定时任务 每隔2秒获取一次二维码扫码状态 返回 0 未关注 1 已关注
     * 返回 1 时候 请求登录信息 如果手机phone 未空或“” 说明未绑定手机
     */
    @action getQrCodeState(){
        this.clearMyInterval();
        this.timer = setInterval(async ()=>{
            if(this.code) {
                let result = await api.getQrCodeState(this.code);
                if(result && 200 === result.code) {      
                    if(1 === parseInt(result.data)) {
                        let res = await api.getQrLoginState(this.code);
                        if(res && 200 === res.code){
                            this.clearMyInterval();
                            const {token, headUrl, login, phone} = res.data;
                            this.token = token;
                            this.headUrl = headUrl;
                            this.login = login;
                            this.phone = phone;
                            // 微信-登陆成功——记录登录信息
                            sessionStorage.setItem('login', login);
                            sessionStorage.setItem('token', token);
                            sessionStorage.setItem('headUrl', headUrl);
                            //如果phone == "" 说明未绑定手机号
                            if(!this.phone) {
                                // 展示绑定手机号UI
                                this.bindPhoneShow = true;
                            } else {
                                // 关闭登录UI
                                this.isShowModal = false;
                            } 
                        }  
                    }
                }
            }
        }, 2000)
    }
    /**
     * 停止心跳请求
     */
    @action clearMyInterval() {
        if(this.timer != null) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    /**
     * 获取二维码图片和code参数
     */
    @action getQrCode(){
        return new Promise(async (resolve, reject)=>{
            this.bindPhone = "";
            this.bindCode = "";
            let result = await api.reqCode()
            if(result && 200 === result.code) {
                this.url = result.data.url;
                this.code = result.data.code;
                console.log(this.url)
                resolve(true)
            }else {
                reject(false)
            }
        }) 
    }

    @action async BPhone(){
        return new Promise(async (resolve, reject) => {
            if(this.bindPhone && this.bindPhone.length === 11 && this.bindCode && this.bindCode.length === 4) {
                let result = await api.bindPhone(this.bindPhone, this.bindCode, this.token);
                if(result && 200 === result.code) {
                    //绑定成功关闭登录UI
                    this.isShowModal = false;
                    //重制登录状态
                    this.bindPhoneShow = false;

                    this.bindCode = "";

                    this.bindPhone = "";

                    resolve(true)
                }else {
                    this.setBindError(result.message)
                    reject(false)
                }
            }else {
                this.setBindError(`请填写手机号或验证码`)
                reject(false)
            }
        })
    }

    @action phoneLogin(){
        return new Promise(async (resolve, reject)=>{
            if(this.userAgainurl && this.bindPhone && this.bindCode) {
                let result = await api.loginPhone(this.bindPhone, this.bindCode)
                if(result && 200 === result.code) {
                    console.log(result)
                    const {token, login, headUrl} = result.data
                    this.token = token;
                    this.headUrl = headUrl;
                    this.login = login;
                    this.phone = this.bindPhone;
                        
                    this.bindPhone = ""
                    this.bindCode = "";
                    this.isShowModal = false;
                    // 登陆成功
                    sessionStorage.setItem('login', login);
                    sessionStorage.setItem('token', token);
                    sessionStorage.setItem('headUrl', headUrl);
                    resolve(true)
                }else {
                    reject(false)
                }
            } else {
                reject(false)
            }
        })   
    }
    // 图形验证码
    @observable isVerificationCode = false
}

const userStore = new UserStore();
export default userStore;