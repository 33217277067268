import Reback from '../images/reback.png'
import ModalLeft from '../images/modalLeft.png'
import Ai from '../images/ai.png'
import Copy from '../images/copy.png'
import Preview from '../images/preview.png'
import Quality from '../images/quality.png'
import Translate from '../images/translate.png'
import Web from '../images/web.png'
import Tipbtn from '../images/tipBtn.png'
import Search from '../images/search.png'
import Hot from '../images/hot.png'
import ArrowBottom from '../images/arrowBottom.png'
import WordMore from '../images/wordMore.png'
import ReadHistory from '../images/readHistory.png'
import Material from '../images/material.png'
import AddTopic from '../images/addTopic.png'
import TypeCommend from '../images/typeCommend.png'
import ArrowDown from '../images/arrowDown.png'
import HotBlack from '../images/hotBlack.png'
import PageNext from '../images/pageNext.png'
import PagePre from '../images/pagePre.png'
import ArrowRight from '../images/arrowRight.png'
import HotOne from '../images/hotOne.png'
import HotTwo from '../images/hotTwo.png'
import HotThree from '../images/hotThree.png'
import HotOther from '../images/hotOther.png'
import FireOne from '../images/fireOne.png'
import FireTwo from '../images/fireTwo.png'
import FireThree from '../images/fireThree.png'
// 编辑图标
import Ebefore from '../images/eBefore.png'
import EAfter from '../images/eAfter.png'
import EBold from '../images/eBold.png'
import EBoth from '../images/eBoth.png'
import EClear from '../images/eClear.png'
import EColor from '../images/eColor.png'
import EH from '../images/eH.png'
import EImg from '../images/eImg.png'
import ELeft from '../images/eLeft.png'
import EMid from '../images/eMid.png'
import ERight from '../images/eRight.png'
import EWuxu from '../images/eWuxu.png'
import EYouxu from '../images/eYouxu.png'
import EXieti from '../images/eXieti.png'
import EXiahua from '../images/eXiahua.png'
import HomeBanner from '../images/homeBanner.png'
import HomeArrowRight from '../images/homeArrowRight.png'
import HomeHotBanner from '../images/homeHotBanner.png'
import ModalRight from '../images/modalRight.png'
import editorCircle from '../images/editorCircle.png'
import eZiTi from '../images/eZiTi.png'
import addArticle from '../images/addArticle.png'
import webHot from '../images/webHot.png'
import partnerOne from '../images/partnerOne.png'
import partnerTwo from '../images/partnerTwo.png'
import partnerThree from '../images/partnerThree.png'
import partnerFour from '../images/partnerFour.png'
import bigLeft from '../images/bigLeft.png'
import bigRight from '../images/bigRight.png'
import loginBg from '../images/loginBg.png'
import logo from '../images/logo.png'
import loginYes from '../images/loginYes.png'
import loginNo from '../images/loginNo.png'
import loginPhone from '../images/loginPhone.png'
import loginYan from '../images/loginYan.png'
import wx from '../images/wx.png'
import wxLight from '../images/wxLight.png'
import phoneLight from '../images/phoneLight.png'
import typeSelected from '../images/typeSelected.png'
// 26中分类
import type1 from '../images/type1.png'
import type2 from '../images/type2.png'
import type3 from '../images/type3.png'
import type4 from '../images/type4.png'
import type5 from '../images/type5.png'
import type6 from '../images/type6.png'
import type7 from '../images/type7.png'
import type8 from '../images/type8.png'
import type9 from '../images/type9.png'
import type10 from '../images/type10.png'
import type11 from '../images/type11.png'
import type12 from '../images/type12.png'
import type13 from '../images/type13.png'
import type14 from '../images/type14.png'
import type15 from '../images/type15.png'
import type16 from '../images/type16.png'
import type17 from '../images/type17.png'
import type18 from '../images/type18.png'
import type19 from '../images/type19.png'
import type20 from '../images/type20.png'
import type21 from '../images/type21.png'
import type22 from '../images/type22.png'
import type23 from '../images/type23.png'
import type24 from '../images/type24.png'
import type25 from '../images/type25.png'
import type26 from '../images/type26.png'
import rebackTop from '../images/rebackTop.png'
import dataBlack from '../images/dataBlack.png'
import add from '../images/add.png'
import homeAni from '../images/homeAni.jpg'
import searchAni from '../images/searchAni.png'
import searchModalClose from '../images/searchModalClose.png'
import homeScroll from '../images/homeScroll.png'
import prePhone from '../images/prePhone.png'
import previewClose from '../images/previewClose.png'
import playing from '../images/playing.png'
import pauseing from '../images/pauseing.png'
import playVideo from '../images/playVideo.png'
import playCircle from '../images/playCircle.png'
import playCD from '../images/playCD.png'
import playLine from '../images/playLine.png'
import phone2 from '../images/phone2.png'
import phone3 from '../images/phone3.png'
import tipsClose from '../images/tipsClose.png'
import tipsOpen from '../images/tipsOpen.png'
import closeBlack from '../images/closeBlack.png'
import bigSearch from '../images/bigSearch.png'
import download from '../images/download.png'
import book from '../images/book.png'
import orangeUp from '../images/orangeUp.png'
import orangeDown from '../images/orangeDown.png'
import dddSelect from '../images/dddSelect.png'
import qq from '../images/qq.png'
import weibo from '../images/weibo.png'
import erWei from '../images/erWei.jpg'
import circle from '../images/circle.png'
import news24 from '../images/news24.png'
import yanZheng from '../images/yanZheng.png'
import searchSource from '../images/searchSource.png'
import introduce1 from '../images/introduce1.jpg'
import introduce2 from '../images/introduce2.jpg'
import introduce3 from '../images/introduce3.png'
import introduce4 from '../images/introduce4.jpg'
import homePlay from '../images/homePlay.png'
import caiJian from '../images/caiJian.png'
import fangDa from '../images/fangDa.png'
import suoXiao from '../images/suoXiao.png'
import qqEr from '../images/qqEr.jpg'
import weiboEr from '../images/weiboEr.png'
import addImg from '../images/addImg.png'
import backAudio from '../images/backAudio.png'
import fastAudio from '../images/fastAudio.png'
import playAudio from '../images/playAudio.png'
import deleteIcon from '../images/delete.png'
import audioSelect from '../images/audioSelect.png'
import audioNo from '../images/audioNo.png'
import pauseAudio from '../images/splitPause.png'
import audioDownload from '../images/audioDownload.png'
export default {
    audioDownload,
    pauseAudio,
    audioNo,
    audioSelect,
    deleteIcon,
    backAudio,
    fastAudio,
    playAudio,
    addImg,
    qqEr,
    weiboEr,
    caiJian,
    fangDa,
    suoXiao,
    homePlay,
    introduce1,
    introduce2,
    introduce3,
    introduce4,
    searchSource,
    yanZheng,
    news24,
    circle,
    erWei,
    qq,
    weibo,
    dddSelect,
    orangeDown,
    orangeUp,
    book,
    download,
    bigSearch,
    closeBlack,
    tipsOpen,
    tipsClose,
    phone2,
    phone3,
    playCD,
    playLine,
    playCircle,
    playVideo,
    playing,
    pauseing,
    previewClose,
    prePhone,
    homeScroll,
    searchModalClose,
    searchAni,
    homeAni,
    add,
    dataBlack,
    rebackTop,
    type1,
    type2,
    type3,
    type4,
    type5,
    type6,
    type7,
    type8,
    type9,
    type10,
    type11,
    type12,
    type13,
    type14,
    type15,
    type16,
    type17,
    type18,
    type19,
    type20,
    type21,
    type22,
    type23,
    type24,
    type25,
    type26,
    typeSelected,
    phoneLight,
    wxLight,
    loginYes,
    loginYan,
    loginPhone,
    loginNo,
    wx,
    logo,
    loginBg,
    bigLeft,
    bigRight,
    partnerFour,
    partnerOne,
    partnerThree,
    partnerTwo,
    webHot,
    addArticle,
    eZiTi,
    editorCircle,
    ModalRight,
    HomeHotBanner,
    HomeArrowRight,
    HomeBanner,
    EMid,
    EAfter,
    Ebefore,
    EBold,
    EBoth,
    EClear,
    EColor,
    EH,
    EImg,
    ELeft,
    ERight,
    EWuxu,
    EXiahua,
    EXieti,
    EYouxu,
    FireOne,
    FireTwo,
    FireThree,
    HotOne,
    HotTwo,
    HotThree,
    HotOther,
    ArrowRight,
    PageNext,
    PagePre,
    HotBlack,
    ArrowDown,
    AddTopic,
    TypeCommend,
    Material,
    ReadHistory,
    Reback,
    ModalLeft,
    Ai,
    Copy,
    Preview,
    Quality,
    Translate,
    Web,
    Tipbtn,
    Search,
    Hot,
    ArrowBottom,
    WordMore
}