import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    render() {
        return(
            <div
                className="time_line_box_com"
            >
               
               <div
                    className="news_24_list"
                >
                    <div className="news_24_list_top">
                        <div 
                            className="top_fixed_btn"
                            onClick={(e) => {
                                e.stopPropagation()
                                if(this.isBelongId(this.props.txtId)){// 存在
                                    this.props.deleteTagTxt && this.props.deleteTagTxt()
                                } else {
                                    this.props.addTagTxt && this.props.addTagTxt()
                                }
                            }}
                        >

                            <img src={
                                this.isBelongId(this.props.txtId) ?
                                ImageSet.audioSelect :
                                ImageSet.audioNo
                            }/>
                        </div>
                        <div className="top_time">
                            {this.props.beginTime} - {this.props.endTime}
                        </div>
                        </div>
                            <div className="news_24_list_bottom">     
                               {this.props.item}
                            </div>
                        </div>
            </div>
        )
    }
    isBelongId(id){
        // const { asStore } = this.props.store;
        const  tagsList = this.props.tagList;
        let tagRes = false
        if(tagsList.length > 0){
           tagsList.filter(item=>{
                if(item.id == id){
                    tagRes = true
                }
            })
        }
        return tagRes
    }
    
}
export default Index