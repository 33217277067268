import React from 'react';
import {observer, inject} from 'mobx-react';
import MaskCom from '../maskBox'
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    source = ['微信公众号', '新浪微博', '头条号', '企鹅号']
    state = {
        titleInputValue: '',
        isTipModal: false
    }
    render() {
        const { SendStore, ArticleStore } = this.props.store
        const {dataList} = SendStore
        return(
            <div 
                className="webs_out_modal_box"
            >
                {
                    this.renderMask(SendStore.error)
                }
                <div className='modal_source'>
                    <div className="source_main">
                        <div className="main_txt txt_active">
                            <div className="txt_line line_active"></div>
                            微信公众号
                            {/* <div className="txt_line line_active"></div> */}
                        </div>
                        <div className="main_txt" style={{opacity:'.5', cursor:'not-allowed'}}>
                            <div className="txt_line"></div>
                            新浪微博
                        </div>
                        <div className="main_txt" style={{opacity:'.5', cursor:'not-allowed'}}>
                            <div className="txt_line"></div>
                            头条号
                        </div>
                        <div className="main_txt" style={{opacity:'.5', cursor:'not-allowed'}}>
                            <div className="txt_line"></div>
                            企鹅号
                        </div>
                    </div>
                </div>
                <div className="modal_line"></div>
                <div className='modal_selected'>
                    <div className="selected_top">
                        <div>已授权</div>
                        <div>
                            {/* 管理 */}

                        </div>
                    </div>
                    <div className="selected_bottom">
                        {/* <div className="bottom_btn bottom_btn_active">微信公众号</div> 
                        <div className="bottom_btn">微信公众号</div> */}
                        {
                            dataList.length > 0 &&
                            dataList.map((item, index) => {
                                return <div 
                                    // className="bottom_btn bottom_btn_active"
                                    key={`bottom_btn_authorization_list${index}`}
                                    className={
                                        SendStore.wxList.indexOf(item.authorizerAppid) != -1 ?
                                        'bottom_btn bottom_btn_active' :
                                        'bottom_btn'
                                    }
                                    onClick={() => {
                                        // 添加或者去除公众号指定的appId
                                        SendStore.addWxList(item.authorizerAppid)
                                    }}
                                >
                                    {item.nickName}
                                </div>
                            })
                        }
                        {/* 点击进行公众号授权 */}
                        <img 
                            src={ImageSet.add}
                            title="微信公众号授权"
                            onClick={()=>{
                                window.location.href=SendStore.wx;
                                // 保存文章和文章id
                                this.sessionActive()
                            }} 
                        />
                       
                        
                    </div>
                </div>
                <div className="modal_line"></div>
                <div className='modal_working'>
                    <div className="working_input">
                        标题
                        <input type="txt"  
                            // maxLength={15}
                            value={this.state.titleInputValue || ArticleStore.activeTitle}
                            onChange={(e) => {
                                SendStore.setTitle(e.target.value)
                                this.setState({
                                    titleInputValue: e.target.value
                                })
                            }}
                        />
                    </div>
                    <div className="working_input">
                        作者
                        <input type="txt"  
                            // maxLength={15} 
                            onChange={(e) => {
                                SendStore.setAuthor(e.target.value)
                            }}
                        />
                    </div>
                    <div className="working_imgs">
                        <div className="imgs_txt">
                            封面
                            <div className="imgs_txt_others">
                                <input 
                                    type="checkbox" 
                                    onChange={e=>SendStore.setIsShowCover()}
                                />&nbsp;正文中显示封面图片
                            </div>
                        </div>
                        <div className="imgs_box">
                           {
                               SendStore.cover != '' ?
                               <div className="imgs_box_main">
                                   <img src={SendStore.cover}/>
                               </div> :
                                <div  
                                    className="imgs_box_tips"
                                    onClick={()=>{this.refs.ul_input.click()}}
                                >
                                    <img src={ImageSet.addImg}/>
                                    <div>建议尺寸：900 * 500</div>
                                    <input 
                                        accept={`image/*`} 
                                        ref="ul_input" 
                                        type="file" 
                                        style={{display: "none"}} 
                                        onChange={this.onSelectChanage.bind(this)}
                                    />
                                </div>
                           }
                        </div>
                    </div>
                    <div className="working_input">
                        摘要
                        <input type="txt"  
                            // maxLength={11}
                            onChange={e=>SendStore.setArticleAbstract(e.target.value)}
                        />
                    </div>
                    {/* <div className="working_input">
                        备注
                        <input type="txt"  maxLength={11} />
                    </div> */}
                    <div className="working_input">
                        原文外链
                        <input 
                            type="txt"  
                            // maxLength={11} 
                            className="input_max"
                            onChange={e=>SendStore.setArticleUrl(e.target.value)}
                        />
                    </div>
                </div>
                <div className="modal_submit">
                    <div 
                        className="submit_btn"
                        style={
                            SendStore.wxList.length == 0 ?
                            {cursor:'not-allowed', opacity:'.5'} :
                            {}
                        }
                        onClick={() => {
                            const that = this
                            // 同步到指定的公众号id
                            if(SendStore.wxList.length > 0){
                                this.send(() => {
                                    that.setState({
                                        isTipModal: true
                                    })
                                })
                            }
                        }}
                    >
                        同步
                    </div>
                </div>
            </div>
        )
    }
    renderMask(propTxt = ''){
        const that = this
        return <MaskCom 
                    show={that.state.isTipModal}
                    propStyle={{position:'absolute'}}
                    prop2Style={{height:'100%'}}
                >
                <div 
                    className="render_mask"
                >
                    <div className="render_mask_close">
                        <img 
                            src={ImageSet.closeBlack}
                            onClick={() => {
                                that.setState({
                                    isTipModal: false
                                })
                            }}
                        />
                    </div>
                    <div className="redner_mask_txt">
                        {propTxt}
                    </div>
                </div>
        </MaskCom>
    }
    componentDidMount(){
        const {SendStore, userStore, ArticleStore}= this.props.store;
        SendStore.loadData(userStore.token);
        SendStore.redict(userStore.token)
        if(ArticleStore.activeTitle != ''){
            SendStore.setTitle(ArticleStore.activeTitle)
        }
       
    }
    onSelectChanage(e){
        let {SendStore, userStore}= this.props.store;

        SendStore.selectFile(e.target.files[0], userStore.token);
    }
    send(callback = ''){
        let {SendStore, userStore, ArticleStore}= this.props.store;
        let contentTest =`<div style="width: 100% !important"><div label-module="para-title"><h2>工商信息</h2><a href="javascript:;"><em></em>编辑</a></div><div label-module="para">企业名称：特赞（上海）信息科技有限公司；统一社会信用代码：9131011533262045X2；法定代表人：范凌；成立日期：2015年04月22日；登记机关：自由贸易试验区市场监管局。<sup> [1]</sup><a name="ref_[1]_21211443"> </a></div><div><a name="2"></a><a name="sub21211443_2"></a><a name="经营范围"></a></div><div label-module="para-title"><h2>经营范围</h2><a href="javascript:;"><em></em>编辑</a></div><div label-module="para">信息科技领域内的技术服务、技术转让、技术咨询，计算机软硬件的开发、设计、销售，系统集成，通讯产品、集成电路、电子产品的研发和销售，电子商务（不得从事金融业务），财务咨询，企业管理咨询，商务咨询，会务服务，展览展示服务，企业营销策划，电脑图文、动漫软件设计、制作，从事货物及技术的进出口业务。 【依法须经批准的项目，经相关部门批准后方可开展经营活动】<sup> [1]</sup><a name="ref_[1]_21211443"> </a></div></div>`
        SendStore.saveDocment(ArticleStore.activeId, ArticleStore.activeContent, userStore.token, callback);
    }
    sessionActive(){
        const {ArticleStore}= this.props.store;
        sessionStorage.setItem("articleId", ArticleStore.activeId)
        sessionStorage.setItem("articleTitle", ArticleStore.activeTitle)
        sessionStorage.setItem("articleContent", ArticleStore.activeContent)
    }
}
export default Index