import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        activeIndex: 0
    }
    render() {
        return(
            <div 
                className="active_box_undeline"
            >
                {
                        this.props.txts && this.props.txts.map((item, index) => {
                            return <div 
                            className="box_content"
                            key={`top${index}`}
                            onClick={() => {
                                this.setState({
                                    activeIndex: index
                                })
                                this.props.propClick && this.props.propClick(item)
                            }}
                        >
                            <div 
                                className="bar_box_txt"
                                style={
                                    this.state.activeIndex == index ?
                                    {color: 'rgb(237, 97, 70)',} : {}
                                }
                            >
                                {item}
                            </div>
                            <div 
                                className="bar_box_active"
                                style={
                                    this.state.activeIndex == index ?
                                    {opacity: '1'} : {opacity: '0'}
                                }
                            ></div>
                        </div>
                        })
                    }
            </div>
        )
    }
}
export default Index