import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    render() {
      
        return(
            <div 
                className="article_div_list"
            >
                <div className="list_title">
                    {this.props.title}
                </div>
                <div className="list_source">
                    <div className="source_name">
                        {this.props.source}
                    </div>
                    <div className="source_date">
                       {this.props.time}
                    </div>
                    <div className="source_time">
                        {/* 14:04:00 */}
                    </div>
                </div>
                <div className="list_img_txt">
                    <div className="img_txt_img">
                        {/* <img src="http://get-file.oss-cn-hangzhou.aliyuncs.com/es/images/e14fbf59891b11e99e49100501442af5_0.png?x-oss-process=image/resize,m_lfit,limit_1,h_300"/> */}
                        <img src={this.props.src}/>
                    </div>
                    <div className="img_txt_txt">
                        {/* {this.props.content} */}
                        {this.handleContent(this.props.content) || '无内容'}
                    </div>
                </div>
                <div className="list_key_word">
                    {
                        this.props.keyWords && this.props.keyWords.map((item, index) => {
                            return <div 
                                        className="key_word"
                                        key={`searchKeyword${index}`}
                                    >
                                        #{item}
                                    </div>
                        })
                    }
                    {/* <div className="key_word">
                        #与之路
                    </div>
                    <div className="key_word">
                        #地铁
                    </div>
                    <div className="key_word">
                        #工作人员
                    </div>
                    <div className="key_word">
                        #成都
                    </div>
                    <div className="key_word">
                        #表示
                    </div> */}
                </div>
            </div>
        )
    }
    handleContent(data){
        var dataCon = data.split('<BR/>')
        return dataCon.concat(' ')
    }
}
export default Index