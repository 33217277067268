import React, {Fragment} from 'react'
import './toolBar.scss';
import {Button,ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {Editor, EditorState, RichUtils, AtomicBlockUtils, Modifier} from 'draft-js';
import api from '../../utils/Api'

export function ToolBar(props) {
    return (
        <ButtonGroup>
            {props.children}
        </ButtonGroup>
    )
}


export function ToolButton(props) {
    const {srcUrl, label, barStyle, isShowTxt, isActive} = props
    const onClick = props.onClick;
    return <Fragment>
            <Button 
                onMouseDown={(e)=>{
                    e.preventDefault(); 
                    if(onClick) {
                        onClick()
                    }}
                }
            >
            {
              isShowTxt ?
             <div style={{fontSize:'14px'}}>{ label}</div> :
              <div
                style={
                  isActive ?
                  { background:'#ddd'} :
                  {}
                }
                className="tool_button_box"
              >
                <img 
                  src={srcUrl} 
                  title={label} 
                  style={barStyle || {width:'12px'}}
                />
              </div>
            }
            {/* {label} */}
            {/* <img src={srcUrl} title={label} style={barStyle || {width:'14px'}}/> */}
            </Button>
        </Fragment>
}
// 插入图片
export class ImageUploader extends React.PureComponent{
  constructor(props) {
    super(props);

    this.state = {
      imageList: [],
    };
  }
    render(){
      return <Fragment>
        <Button>
          <div 
            className="tool_button_box"
            onClick={this.handleIconClick}
          >
            <img 
              src={this.props.srcUrl}
              style={{
                width:'15px'
              }}
            />
          </div>
          <input
            ref={e => this.input = e}
            type="file"
            accept="image/*"
            onClick={this.handleInputClick}
            onChange={this.handleInputChange}
            multiple={false}
            style={{
              display:'none'
            }}
          />
        </Button>
    </Fragment>
    }
   // 点击上传图片图标的时候触发上传图片
   handleIconClick = (e) => {
    if (this.input.files) {
      this.input.click();
    }
  }

  handleInputClick = (e) => {
    e.stopPropagation();
  }

  handleInputChange = async () => {
    const files = this.input.files || [];

    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      // 将图片转为src
      reader.onload = (e) => {
        const src = e.target.result;
        
        this.props.insertImage(src);
      };
      reader.readAsDataURL(file);
      // let result = await api.upLoadFile(file, sessionStorage.getItem('token'));
      // result = result.data;
      // const that = this
      // if(result && 200 == result.code){
      //     let message = result.data;
      //     // message.url
      //     that.props.insertImage(message.url);
      // }
    }
  }

}

export class TDropdown extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false
        };
    }
    
    toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }
    
    render() {
      const {srcUrl, label, barStyle, isTxt} = this.props
        return (
          <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>
              <div style={{display:'inline-block'}}>
                  {
                    !isTxt ? 
                    <img src={srcUrl} title={label} style={barStyle || {width:'14px'}}/> : 
                    <span className="btn_txt_down" style={{fontSize:'14px'}}>{this.props.label}</span>
                  }
              </div>
            </DropdownToggle>
            {
              this.props.data ? 
              <DropdownMenu>
                {
                  this.props.data.map((item, index) => {
                    return <DropdownItem onClick={()=>this.props.onClick ? this.props.onClick(item.style) : ""} key={index}>{item.label}</DropdownItem>
                  })
                }
              </DropdownMenu> : ""
            }
          </ButtonDropdown>
        );
    }
}
// 颜色样式Button
export class StyleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty()
    }
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let style;
    // const {editorState} = this.state
    // var currentStyle = editorState.getCurrentInlineStyle()
    // var active=currentStyle.has(this.props.style)
    // if (this.props.active) {
    //   console.log(this.props.active)
    //   style = {...styles.styleButton, ...this.props.colorStyleMap[this.props.style]};
    // } else {
    //   style = styles.styleButton;
    // }
    const colorBtn = this.props.colorStyleMap[this.props.style]['color']
    return (
      <span 
        onMouseDown={this.onToggle}
        style={{width:'10px', height:'10px', background:colorBtn,display:'block'}}
      >
        {/* {this.props.label} */}
        {/* {colorBtn} */}
      </span>
    );
  }
}
const styles = {
  root: {
    fontFamily: '\'Georgia\', serif',
    fontSize: 14,
    padding: 20,
    width: 600,
  },
  editor: {
    borderTop: '1px solid #ddd',
    cursor: 'text',
    fontSize: 16,
    marginTop: 20,
    minHeight: 400,
    paddingTop: 20,
  },
  controls: {
    fontFamily: '\'Helvetica\', sans-serif',
    fontSize: 14,
    marginBottom: 10,
    userSelect: 'none',
  },
  styleButton: {
    color: '#999',
    cursor: 'pointer',
    marginRight: 16,
    padding: '2px 0',
  },
};
export const Head = [
  {label: '一级标题', style: 'header-one'},
  {label: '二级标题', style: 'header-two'},
  {label: '三级标题', style: 'header-three'},
  {label: '四级标题', style: 'header-four'},
]

export const titles = [
    {
        label: '',
        data: []
    }
]


