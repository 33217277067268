import {observable, action} from 'mobx';
import api from '../utils/Api'
class dataSource{
    @observable sourceList = [];
    @observable token = ''
    @action setToken(tok){
        this.token = tok
    }
    @action async getSourceData(){
        let result = await api.getInformationSourcesList(this.token)
        if(result && result.code == 200){
            this.sourceList = result.data
            this.handleSelects(result.data)
        }
    }
    @observable hangYeList = ['全部']
    @observable hangYeActive = ''
    @observable guoJiaList = ['全部']
    @observable jiBieList = ['全部']
    @observable quDaoList = ['全部']
    @action handleSelects(array){
        if(array){
            array.map((item, index) => {
                if(this.hangYeList.indexOf(item.informationSourcesType) == -1){
                    this.hangYeList.push(item.informationSourcesType)
                }
                if(this.guoJiaList.indexOf(item.countyName) == -1){
                    this.guoJiaList.push(item.countyName)
                }if(this.jiBieList.indexOf(item.levelName) == -1){
                    this.jiBieList.push(item.levelName)
                }if(this.quDaoList.indexOf(item.webChannel) == -1){
                    this.quDaoList.push(item.webChannel)
                }
            })
        }
    }
    @observable allSelectTypes = ['行业','国家','级别','渠道']
    @observable isOpenSelect = -1
    @observable selectedSourceList = []
    @action async addOrRemoveSource(item, name){
        var Inde = this.selectedSourceList.indexOf(name)
        if(Inde == -1){// 添加
            this.selectedSourceList.push(name)
          
            let res1 = await api.addInformationSources(item.informationSourcesId, this.token)
        } else {// 删除
            this.selectedSourceList.splice(Inde,1)
            let res1 = await api.deleteInformationSources(item.informationSourcesId, this.token)
        }
        if(this.selectedSourceList.length == 0){
            sessionStorage.setItem("isInformation",'false')
        } else {
            sessionStorage.setItem("isInformation",'true')
        }
    }
    // 全选-全不选
    @action  allSelected(){
        if(this.sourceList.length > 0 && this.selectedSourceList.length == this.sourceList.length){// 全不选
            this.sourceList.map((item, index) => {
                api.deleteInformationSources(item.informationSourcesId, this.token)
            })
            this.selectedSourceList = []
        } else {// 全选
            this.selectedSourceList = []
            this.sourceList.map((item, index) => {
                this.selectedSourceList.push(item.informationSourcesName)
                api.addInformationSources(item.informationSourcesId, this.token)
            })
        }
        
    }
    @action searchSource(name){
        this.sourceList = this.sourceList.filter(item=>{
            return item.informationSourcesName.indexOf(name) != -1;
        })
    }
    // 过滤行业数据-国家等
    @action async filterSource(param, typeName){
        // 先获取原始数据
        let result = await api.getInformationSourcesList(this.token)
        if(result && result.code == 200){
            this.sourceList = result.data
        }
        // 再过滤——指定类型数据
        this.allSelectTypes.map((activeName, index) => {

            if(activeName != '行业' && index == 0){
                this.sourceList = this.sourceList.filter(item=>{
                    return item['informationSourcesType'] == activeName;
                })
            }
            if(activeName != '国家' && index == 1){
                this.sourceList = this.sourceList.filter(item=>{
                    return item['countyName'] == activeName;
                })
            }
            if(activeName != '级别' && index == 2){
                this.sourceList = this.sourceList.filter(item=>{
                    return item['levelName'] == activeName;
                })
            }
            if(activeName != '渠道' && index == 3){
                this.sourceList = this.sourceList.filter(item=>{
                    return item['webChannel'] == activeName;
                })
            }
        })
       
    }
    // 索引对应名称
    @action IndexName(index){
        if(index == 0){
            return '行业'
        }
        if(index == 1){
            return '国家'
        }
        if(index == 2){
            return '级别'
        }
        if(index == 3){
            return '渠道'
        }
    }
}
const DataSourcesStore = new dataSource();
export default DataSourcesStore