import React, {Component} from 'react';
import Mask from '../maskBox'
import './index.scss';
import ImageSet from '../../utils/image'

export default class Index extends Component{
    render(){
        return <Mask show={this.props.show}>
            <div className="confirm_layout">
                {
                    this.props.titleClose ? 
                    <img style={{width: 15, height: 15, position: 'absolute', right: 15, top: 15}} onClick={()=>this.props.cancel?this.props.cancel(): ""} src={ImageSet.closeBlack}></img> 
                    : ""
                }
                <div className="confirm_layout_title">{this.props.title}</div>
                <div className="confirm_layout_content">{this.props.content}</div>
                {
                    this.props.onButtom ? "" :
                    <div className="confirm_layout_buttom">               
                    <div onClick={()=>this.props.confirm?this.props.confirm(): ""} className="confirm_buttom_layout confirm_buttom_layout2">确定</div>
                    <div onClick={()=>this.props.cancel?this.props.cancel(): ""} className="confirm_buttom_layout">取消</div>
                    </div>
                }
            </div>
        </Mask>
    }
}