import {observable, action} from 'mobx';
import api from '../utils/Api'

class searchs{
    @observable isSearch = false// 是否显示搜索结果
    @observable token="";//登录标示
    @action setToken(token){
        this.token = token;
    }
    @observable page = 1;//当前数据页
    @observable pageCount = 6;//每夜获取的数据
    
    @observable searchWord = "";//搜索词
    @action setSearchWord(str){
        this.searchWord = str;
    }

    @observable beginDate="";
    @observable endDate="";
    @observable countryNameZhKey="";//搜索条件 地区KEY
    @observable languageCodeKey="";//搜索条件 语言KEY
    @observable mediaLevelKey="";//搜索条件 媒体权重
    @observable sentimentIdKey="";//搜索条件 情感
    @observable loading = false
    @observable dataList = []// 搜索结果列表
    @observable articelInfo = []// 文章详情页
    @observable hotTypeName = ''
    @observable filterVulcan = 0// 最新-0/vulcan值-1
    @observable filterList = []
    // 引入搜索条件及相关数据和方法-开始
    @observable dataShowStr="时间"
    @observable dateParams = {
        data: [
            {
                key:'0',
                tab:'全部',
            },
            {
                key:'1',
                tab:'一天内',
            },
            {
                key:'3',
                tab:'三天内',
            },
            {
                key:'7',
                tab:'七天内',
            },
            {
                key:'30',
                tab:'一月内',
            }
        ],
        action: key=>{
            if(key == '0'){
                this.dataShowStr="时间"
                this.beginDate = "";// 时间参数
                this.endDate = "";  // 时间参数  
                this.searchAction(); 
            } else {
                let end = new Date();
                
                // let endStr = end.Format("yyyy-MM-dd hh:mm:ss");
                let beginStr = this.addDate(end, -key);
                let endStr = this.addDate(end)
                // console.log(beginStr)
                // console.log(endStr)
                this.beginDate = beginStr;
                this.endDate = endStr;
                this.searchAction(); 
            }
        },
    }
    @observable countryNameZhTitle="地区";//显示条件 地区名
    @observable countryNameParams = {
        data: [
            {
                key:'',
                tab:'全部',
            },
            {
                key:'中国',
                tab:'中国',
            },
            {
                key:'美国',
                tab:'美国',
            },{
                key:'英国',
                tab:'英国',
            },{
                key:'德国',
                tab:'德国',
            },{
                key:'法国',
                tab:'法国',
            },{
                key:'俄罗斯',
                tab:'俄罗斯',
            },{
                key:'日本',
                tab:'日本',
            },{
                key:'韩国',
                tab:'韩国',
            },{
                key:'意大利',
                tab:'意大利',
            },{
                key:'西班牙',
                tab:'西班牙',
            },{
                key:'其他',
                tab:'其他',
            }
        ],
        action: key=>{
            this.countryNameZhTitle = key == '' ? "地区" : key;
            this.countryNameZhKey = key;
            this.searchAction();    
        }
    }
    @observable languageCodeKeyTitle="语言"//显示条件 语言
    @observable languageCodeParams = {
        data: [
            {
                key:'',
                tab:'全部',
            },{
                key:'zh',
                tab:'中文',
            },{
                key:'en',
                tab:'英文',
            },{
                key:'de',
                tab:'德文',
            },{
                key:'fr',
                tab:'法文',
            },{
                key:'es',
                tab:'西班牙文',
            },{
                key:'ja',
                tab:'日文',
            },{
                key:'ko',
                tab:'韩文',
            },{
                key:'ru',
                tab:'俄文',
            },{
                key:'pt',
                tab:'葡萄牙'
            },{
                key:'ar',
                tab:'阿拉伯文'
            },{
                key:'other',
                tab:'其他'
            }
        ],
        action: key=>{
            this.languageCodeKey = key;  
            console.log('searyada')
            this.searchAction();  
        },
        actionTab: tab=>{
            this.languageCodeKeyTitle = tab == '全部' ? '语言' : tab
        }
    }
    @observable mediaLevelKeyTitle="媒体影响力";//显示
    @observable mediaLevelParams={
        data: [
            {
                key:'',
                tab:'全部',
            },
            {
                key:'5',
                tab:'高影响力',
            },
            {
                key:'4',
                tab:'较高影响力',
            },
            {
                key:'3',
                tab:'一半影响力',
            },
            {
                key:'2',
                tab:'较小影响力',
            },
            {
                key:'1',
                tab:'最小影响力',
            },
    
        ],
        action: key=>{
            this.mediaLevelKey = key;
            this.searchAction();  
        },
        actionTab: tab=>{
            this.mediaLevelKeyTitle = tab == '全部' ? '媒体影响力' : tab;
        }
    }
    @observable sentimentIdKeyTitle="情感";
    @observable sentimentIdParams = {
        data: [
            {
                key:'1,0,-1',
                tab:'全部'
            },
            {
                key:'1',
                tab:'正面情感'
            },
            {
                key:'0',
                tab:'中性情感'
            },
            {
                key:'-1',
                tab:'负面情感'
            }
        ],
        action: key=>{
            this.sentimentIdKey = key;
            this.searchAction();  
        },
        actionTab: tab=>{
            this.sentimentIdKeyTitle =  tab == '全部' ? '情感' : tab;;
        }
    }
    @action addDate(d, days) {
        if (days == undefined || days == '') {
            days = 0;
        }
        var date = new Date(d);
        date.setDate(date.getDate() + days);
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return date.getFullYear() + '-' + this.getFormatDate(month) + '-' + this.getFormatDate(day) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }
    @action getFormatDate(arg) {
        if (arg == undefined || arg == '') {
            return '';
        }

        var re = arg + '';
        if (re.length < 2) {
            re = '0' + re;
        }

        return re;
    }
    // 引入搜索条件及相关数据和方法-结束
    @action filterVulcanList(){
        if(this.filterVulcan){
            this.filterList = this.dataList.sort((a, b) => {
                return  b.vulcaHot - a.vulcaHot
            })
        } else {
            this.filterList = []
        }
    }
    // 默认热点推荐
    @action getHotType(pageNum = 1, typeName = '全部'){
        this.loading = true
        this.hotTypeName = typeName
        this.page = pageNum
        api.getHotList(`https://www.vulcandata.cn/data/v/article/${pageNum}/6?category=${typeName}`).then((data) => {
            if(data.code == 200){
                this.dataList = data.data.list
                this.loading = false
                this.isSearch = false
            }
        })
    }
    // 默认热点推荐-下一页更多
    @action getMoreHotType(){
        this.loading = true
        const typeName = this.hotTypeName
        const pageNum = this.page + 1
        api.getHotList(`https://www.vulcandata.cn/data/v/article/${pageNum}/6?category=${typeName}`).then((data) => {
            if(data.code == 200){
                this.dataList = this.dataList.concat(data.data.list)
                // 按vulcan值加载下一条
                this.filterVulcanList()
                this.loading = false
                this.isSearch = false
                this.page++
            }
        })
    }
    // 高通数据接口
    @action async searchAction(){
        this.loading = true;
        this.page = 1;
        let result = await api.search(this.searchWord, this.page, this.pageCount, this.beginDate, this.endDate, this.countryNameZhKey, this.languageCodeKey, this.mediaLevelKey, this.sentimentIdKey, this.checkIsInformation(), sessionStorage.getItem('token'));
        // 每次搜索新的数据-关键词更换-数据清空
        this.activeKeyWord = ''
        this.newDataList = []
        console.log(sessionStorage.getItem('token'))
        if(result && result.code == 200){
            this.loading = false;
            this.dataList = result.data.list
            this.isSearch = true
            this.getKeyWordTop(result.data.list)
        }
    }
    // 高通数据接口——更多
    @action async moreSearchAction(){     
        this.loading = true;
        let result = await api.search(this.searchWord, this.page + 1, this.pageCount, this.beginDate, this.endDate, this.countryNameZhKey, this.languageCodeKey, this.mediaLevelKey, this.sentimentIdKey, this.checkIsInformation(), sessionStorage.getItem('token'));
        if(result && 200 == result.code){
            this.page = this.page + 1
            this.dataList = this.dataList.concat(result.data.list)
            this.loading = false
            this.isSearch = true
            if(this.activeKeyWord != ""){// 已经选择了指定关键词-关键词更多数据
                this.getFilterDatas(this.activeKeyWord)
            }
        }
        
    }   
    // 获取文章详情-
    @observable isShowDetail = false // 是否显示文章详情页
    @action async getArticleInfo(item, callback){
        this.loading = true;
        let result = await api.getSearchInfoByUUID(item.id, sessionStorage.getItem('token'));
        console.log(typeof result.code)
        if(result && 200 == result.code){
            this.articelInfo = result.data
            // console.log(this.articelInfo)
            this.loading = false;
            this.isShowDetail = true
            if(callback){
                callback()
            }
        }
    }
    @action handleArticleInfo(item){
        return {
            'tagId': '',
            'author': item.zhSource ||'',
            'title': item.zhTitle || '',
            'languageCode': item.languageTName || '',
            'content': item.zhSummary == null ? '' : item.zhSummary,
            'url': item.url || '',
            'keyWord': item.keyWord || '',
            'long_content': item.zhContent || '',
            'dataSourceId': item.id || '',
            'levelNum': '',
            'createTime': item.createTimeView,
            'sentimentId':''
        }
    }
    @action async getArticleInfo2(item, callback){
        this.loading = true;
        let result = await api.getSearchInfoByUUID2(item.id, sessionStorage.getItem('token'));
        if(result && 200 == result.code){
            this.articelInfo = this.handleArticleInfo(result.data)
            this.loading = false;
            this.isShowDetail = true
            if(callback){
                callback()
            }
        }
    }

    // 是否是用户选择的信源
    @action checkIsInformation(){
        return sessionStorage.getItem("isInformation") == "true" ? 1 : 0
    }
    // 获取关键词top20
    @observable keyWordsTop = ['全部'];// 关键词tops-
    @observable keyWordsTabData = [];// 将关键词和keyword拼成新的数组
    @observable newDataList = []
    @observable activeKeyWord = ""
    // 根据关键词获取dataList
    @action getFilterDatas(word){
        const _that = this
        if(word == "全部"){
            this.newDataList = [];
            this.activeKeyWord = ''
        } else {
            this.activeKeyWord = word
            this.newDataList = [];
            let res = this.dataList.filter((item, index) => {
                return item['keyWord'].indexOf(word) != -1
            })
            this.newDataList = res.concat()
        }

    }
    // 关键词top
    getKeyWordTop(datas){
        let words = [];
        datas.map((item, index) => {
            item.keyWord.forEach(ele => {
                if(ele != 'undefined' && ele != '全部') {
                    words.push(ele);
                }
            })
        })
        this.getDatasCount(words);
    }
    getDatasCount(arr){
        var obj = {},
        k, arr1 = [];
        for(var i = 0, len = arr.length; i < len; i++) {
            k = arr[i];
            if(obj[k])
                obj[k]++;
            else
                obj[k] = 1;
        }
        for(var o in obj) {
            arr1.push({
                el: o,
                count: obj[o]
            });
        }
        arr1 = arr1.sort(this.compareData('count'));
        if(arr1.length >= 15){
            arr1 = arr1.slice(0, 15);
        }
        this.keyWordsTop = ['全部'];
        arr1.forEach((ele) => {
            this.keyWordsTop.push(ele['el']);
        })
        // 拼接tab和关键词
        this.addTabKeys();
    }
    compareData(property){
        return function(a,b){
            var value1 = a[property];
            var value2 = b[property];
            return value2 - value1;
        }
    }
    addTabKeys(){
        this.keyWordsTabData = [
            {
                'tab': '文章',
                'keywords': this.keyWordsTop
            }
        ]
        console.log(this.keyWordsTabData)
    }
}
const SearchStore = new searchs();
export default SearchStore