import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    render() {
        const count = String(this.props.count)
        return(
            <div 
                className="top_bottom_box"
                onClick={() => {
                    this.props.propClick &&
                    this.props.propClick()
                }}
            >
               {/* <img src="http://get-file.oss-cn-hangzhou.aliyuncs.com/es/images/e14fbf59891b11e99e49100501442af5_0.png?x-oss-process=image/resize,m_lfit,limit_1,h_300"/> */}
               {/* <img src={this.props.src}/> */}
               <div className="top_bottom_box_txt">
                   {this.props.title}
               </div>
               <div className="top_bottom_hot">
                    <div className="bottom_count">
                          <img src={ImageSet.HotBlack}/>
                         
                          {
                              count != 'undefined' ?
                              (
                                count.length <= 5 ?
                                count :
                                count.substring(0,count.length - 4) + '+'
                              ) : '12450+'
                          }
                      </div>
               </div>
            </div>
        )
    }
}
export default Index