import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import LoadingFixedCom from '../loadingFixedBox'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        activeLan: '英文',
        languageIndex: 0,
        isShowLangModal: false,
    }
    select_designate_list = ["nen", "nja", "nko", "nfr", "nde", "nru", "nar", "npt", "nes"]
    languages = ["英文", "日文", "韩文", "法文", "德文", "俄文", "阿拉伯文", "葡萄牙文", "西班牙文"]
   componentDidMount(){
    this.transTxtHtmls()
   }
    render() {
        const { EditorDraftStore } = this.props.store
        const { 
            editorText,
            transTxt,
            transLoading,
            transHtml
        } 
        = EditorDraftStore
        return(
            <div 
                className="translate_modal_box"
            >
                <LoadingFixedCom 
                    show={transLoading}
                />
               <div className="modal_top">
                   <div className="top_main">
                    <div 
                        className="top_tranlsate_txt"
                    >
                            翻译
                    </div>
                    <div className="top_tranlsate_select">
                        <div
                            onClick={() => {
                                this.setState({
                                    isShowLangModal: !this.state.isShowLangModal
                                })
                            }}
                        >
                        {
                            this.state.activeLan
                        }
                        <img 
                            src={ImageSet.ArrowDown}
                            style={{
                                marginLeft:"5px",
                                width:"13px"
                            }}
                        />
                        </div>
                        <div 
                            className="tranlsate_select_modal"
                            style={
                                this.state.isShowLangModal ?
                                {}:
                                {display:'none'}
                            }
                        >
                            {
                                this.languages.map((item, index) => {
                                    return <div
                                            // className="language_txt"
                                            key={`language${index}`}
                                            className={
                                                this.state.languageIndex == index ?
                                                'language_txt language_txt_active':
                                                'language_txt'
                                            }
                                            onClick={() => {
                                                this.setState({
                                                    languageIndex: index,
                                                    activeLan: item,
                                                    isShowLangModal: false
                                                })
                                                EditorDraftStore.transPurpose = this.select_designate_list[index]
                                                // EditorDraftStore.translateTxt()
                                                this.transTxtHtmls()
                                            }}
                                        >
                                            {item}
                                    </div>
                                })
                            }
                        </div>
                    </div>
                   </div>
               </div>
               <div className="modal_line"></div>
               <div className="modal_bottom">
                   <div 
                        className="bottom_working"
                        dangerouslySetInnerHTML={{__html: transHtml}}
                   >
                        {/* {transTxt || '无'} */}
                       
                   </div>
               </div>
            </div>
        )
    }
    transTxtHtmls(){
        const { EditorDraftStore, ArticleStore } = this.props.store
        const { activeContent } = ArticleStore
    // EditorDraftStore.translateTxt()
    //  一段一段翻译
        var newhtml = activeContent.replace(/&nbsp;/g, "")
        let txt = newhtml == null ? ArticleStore.activeContent.replace(/<[^>]+>/g, "&nbsp;") : newhtml.replace(/<[^>]+>/g, "&nbsp;");
        let texts = txt.split("&nbsp;");
        EditorDraftStore.transHtml = activeContent
        texts.filter(item=>{return item != ""}).map((item, index)=>{
            EditorDraftStore.translateTxt(item)
        })
    }
}
export default Index