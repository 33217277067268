import {observable, action} from 'mobx';
import api from '../utils/Api'
// 海外数据
class oversea{
   
    @observable pageNum = 1// 页码
    @observable pageCount = 6// 每页数据量
    @observable typeName = ''// 指定显示类别名称
    @observable beginDate = ''
    @observable endDate = ''
    @observable countryNameZhKey = ''
    @observable languageCodeKey = ''
    @observable mediaLevelKey = ''
    @observable sentimentIdKey = ''
    @observable tagList = "12,13,19"
    @observable mediaIdList = ''
    @observable overseasList = [];//海外信源集合
    @observable overseaAllPage = 0
    @observable overLoading = false
    @observable token = ''
    @action setToken(txt){
        this.token = txt
    }
    @observable seaDataList = []// 海外数据列表
    @action  async overseasLoadData(pageNum = 1, typeName="全部") {
        let result = await api.getSubjectName("海外信息源", this.token);
        if(result && 200 === result.code){
            this.overseasList = result.data;
            this.mediaIdList = "";
            this.overseasList.map( (item, index) => {
              let id = item.informationSourcesId;
              if(0 === index) {
                  this.mediaIdList = id
              } else {
                  this.mediaIdList = `${this.mediaIdList},${id}`
              }
            })
            this.mediaIdListCopy = this.mediaIdList
            this.getOverPageList(pageNum, typeName)
        }
    }
    @action async getOverPageList(pageNum = 1, typeName="全部"){
        this.overLoading = true
        let result = await api.search(typeName, pageNum, this.pageCount, this.beginDate, this.endDate, this.countryNameZhKey, this.languageCodeKey, this.mediaLevelKey, this.sentimentIdKey, this.checkIsInformation(), this.token, false, this.mediaIdList, this.tagList);
        if(result && result.code == 200){
            this.seaDataList = result.data.list.length > 0 ? this.handleData(result.data.list) : []
            this.seaDataList = this.seaDataList && this.seaDataList.slice(0,6)
            // 成功获取数据更新状态
            this.pageNum = pageNum
            this.typeName = typeName || '全部'
            // 总页码
            var allPage =  result.data.total && parseInt(result.data.total/6)
            this.overseaAllPage = allPage > 30 ? 30 : allPage
            // console.log(this.seaDataList.length)
            // console.log(result.data.list.length +'_over_length')
            this.overLoading = false
        }
    }
    @action checkIsInformation(){
        return 0
    }
    @action handleData(data){
        let newData = []
        if(data.length == 0){
            return 
        } else {
            data.map((item, index) => {
                newData.push({
                    'tagPic':item.tagPic,
                    'title': item.title,
                    'content': item.content,
                    'createTime': item.createTime,
                    'vulcaHot': item.vulcanHot || Math.floor(Math.random()*1000,1)
                })
            })
        }
        return newData
    }
}
const OverSeaStore = new oversea();
export default OverSeaStore