import React from 'react';
import {observer, inject} from 'mobx-react';
import { DropdownButton, DropdownItem} from 'react-bootstrap'
import BtnTipsCom from '../../component/btnTips'
import ArticleDivListCom from '../../component/articleDivList'
import SearchInputCom from '../../component/searchInput'
import EditorDraftBoxCom from '../../component/editorDraftBox'
import LoadingCom from '../../component/loading'
import AnimationFoldCom from '../../component/animationFold'
import ImageSet from '../../utils/image'
import TypeAllBoxCom from '../../component/typeAllBox'
import SelectOptionCom from '../../component/selectOption'
import TranslateModalCom from '../../component/translateModal'
import AnimationBtnLiCom from '../../component/animationBtnLi'
import WebsOutModalCom from '../../component/websOutModal'
import PreviewModalCom from '../../component/previewModal'
import SelectGroupCom from '../../component/selectGroup'
import ItemSearchCom from '../../component/itemSearch'
import FilterKeysBoxCom from '../../component/filterKeysBox'
import VulcanLoginCom from '../../component/vulcanLogin'
// import LoadingFixedCom from '../../component/loadingFixedBox'
import LoadingFixedCom from '../../component/loadingLinesFixed'
import CropperImgCom from '../../component/cropperImg'
import MaskCom from '../../component/maskBox'
import ReactDraftWys from '../../component/reactDraftWys'
import {EditorState, RichUtils, AtomicBlockUtils, Modifier} from 'draft-js';
import './index.scss'
import './anFold.scss'
import './renderSearch.scss'
import api from '../../utils/Api'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        isHideLeft: false,
        isHideRight: true,
        isFoucs: false,
        arr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
        hotWords: ['金融', '科技', '互联网', '体育', '财经'],
        editorState: EditorState.createEmpty(),
        activeColor: '',
        isShowColorBox: false,
        isShowFontsizeBox: false,
        isCircleTips: false,
        isRebackTop: false,
        activeHotTypeI: -1,
        isClickTips: false,
        scrollDomTop: 0,
        textInputValue: '',
        closeQuote: true,
        quoteActiveTxt:'',
        isOpenFankui: false
    }
    // 颜色
    COLORS = [
        {label: 'Red', style: 'red'},
        {label: 'Orange', style: 'orange'},
        {label: 'Yellow', style: 'yellow'},
        {label: 'Green', style: 'green'},
        {label: 'Blue', style: 'blue'},
        {label: 'Indigo', style: 'indigo'},
        {label: 'Violet', style: 'violet'},
    ]
    colorStyleMap = {
        red: {
          color: 'rgba(255, 0, 0, 1.0)',
        },
        orange: {
          color: 'rgba(255, 127, 0, 1.0)',
        },
        yellow: {
          color: 'rgba(180, 180, 0, 1.0)',
        },
        green: {
          color: 'rgba(0, 180, 0, 1.0)',
        },
        blue: {
          color: 'rgba(0, 0, 255, 1.0)',
        },
        indigo: {
          color: 'rgba(75, 0, 130, 1.0)',
        },
        violet: {
          color: 'rgba(127, 0, 255, 1.0)',
        },
    }
    fontSizeS = [
        {label: '9px', style: '9px'},
        {label: '10px', style: '10px'},
        {label: '11px', style: '11px'},
        {label: '12px', style: '12px'},
        {label: '14px', style: '14px'},
        {label: '16px', style: '16px'},
        {label: '18px', style: '18px'},
        {label: '20px', style: '20px'},
        {label: '24px', style: '24px'},
        {label: '30px', style: '30px'},
        {label: '36px', style: '36px'}
    ]
    fontFamilyS = [
        {label: '宋体', style: 'SimSun'},
        {label: '黑体', style: 'SimHei'},
        {label: '楷体', style: 'KaiTi'},
        {label: 'arial', style: 'arial'},
    ]
    styles = {
        root: {
          fontFamily: '\'Georgia\', serif',
          fontSize: 14,
          padding: 20,
          width: 600,
        },
        editor: {
          borderTop: '1px solid #ddd',
          cursor: 'text',
          fontSize: 16,
          marginTop: 20,
          minHeight: 400,
          paddingTop: 20,
        },
        controls: {
          fontFamily: '\'Helvetica\', sans-serif',
          fontSize: 14,
          marginBottom: 10,
          userSelect: 'none',
          border: 'solid 1px red'
        },
        styleButton: {
          color: '#999',
          cursor: 'pointer',
          marginRight: 16,
          padding: '2px 0',
        },
    }
    scrollDom = ''
    scrollDetailDom = ''
    componentDidMount() {
        const {SearchStore, userStore, HotDataStore, EditorStore, ArticleStore, SendStore} = this.props.store
        //  获取数据
        // 初始化
        this.initEditor()
        //  缓存
        this.handleCacheData()

        // 页面滚动
        if (this.scrollDom) {
            this.scrollDom.addEventListener("scroll", () => {
                const _that = this
                const { scrollTop } = this.scrollDom;
                // console.log(scrollTop)
                if(scrollTop > 200){
                    _that.setState({
                        isRebackTop: true
                    })
                } else {
                    _that.setState({
                        isRebackTop: false
                    })
                }
            });
        }
        // 是否授权成功——检查页面的error
        var location = this.props.location;
        if(location.search != ""){
            var errorP = location.search;
            var errorArr = errorP.slice(1).split('=');//  ["error", "0"]
            if(errorArr[0] == 'error' && errorArr[1] == 0){
                console.log('授权成功')
                this.setState({
                    isHideRight: false,
                    isHideLeft: true
                })
                EditorStore.tipsTxtActive = '全网发文'
                this.setState({
                    textInputValue: sessionStorage.getItem("articleTitle") || ''
                })
                ArticleStore.activeId = sessionStorage.getItem("articleId") || '';
                ArticleStore.activeContent = sessionStorage.getItem("articleContent") || '';
                ArticleStore.activeTitle = sessionStorage.getItem("articleTitle") || ''
                SendStore.setTitle(ArticleStore.activeTitle)
                // 文章指定——更新，不保存
                ArticleStore.articleSaveStatus = true
                // 如果文章内容不为空，则插入指定的html
                if(ArticleStore.activeContent != ''){
                    this.editor_wys._insertHtml(ArticleStore.activeContent)
                }
                // 清空
                sessionStorage.setItem("articleId", '')
                sessionStorage.setItem("articleTitle", '')
                sessionStorage.setItem("articleContent", '')

            }
        }

    }
    
    componentWillUnmount() {
        window.removeEventListener('mouseup', () => {})
        if (this.scrollDom) {
            this.scrollDom.removeEventListener("scroll", () => {
              
            });
          }
    }
    handleCacheData(){
        const {SearchStore, userStore, HotDataStore, ArticleStore} = this.props.store
        //  由首页搜索进入编辑页面——缓存
        const searchHome = sessionStorage.getItem("home_to")
        var resSearch = false
        if(searchHome == 'true'){
            const homeWord = sessionStorage.getItem("home_word")
            SearchStore.setSearchWord(homeWord)
            SearchStore.searchAction()
            sessionStorage.setItem("home_to", 'false')
            resSearch = true
        }
        // 由点击卡片进入编辑部分-缓存
        const cardTitle = sessionStorage.getItem("title_to")
        if(cardTitle == 'true'){
            const titleWord = sessionStorage.getItem("title_word")
            ArticleStore.activeTitle = titleWord
            SearchStore.setSearchWord(titleWord)
            SearchStore.searchAction()
            sessionStorage.setItem("title_to", 'false')
            resSearch = true
        }
        if(!resSearch) {
            //无搜素词——默认展示热点
            SearchStore.getHotType()
        }
        this.setState({
            textInputValue: ArticleStore.activeTitle
        })

        // 如果文章内容不为空，则插入指定的html
        if(ArticleStore.activeContent != ''){
            // this.editor._insertHtml(ArticleStore.activeContent)
           this.editor_wys._insertHtml(ArticleStore.activeContent)
            // this.editor._insertHtml('<p>111111<span style="font-size: 36px">111122222222111</span></p>')
        }
    }
    // 搜索-数据列表页
    renderSearchHotList(){
        const {SearchStore, HotDataStore, EditorStore} = this.props.store
        const {isRebackTop} = this.state
        const {filterList, dataList, isSearch} = SearchStore
        return (
            <div className="render_search_hot_list">
                <div className="left_content_search">
                    <SearchInputCom 
                        placeholderTxt="搜索" 
                        search={() => {
                            SearchStore.searchAction()
                        }}
                        onChange={(word) => {
                            SearchStore.setSearchWord(word)
                        }}
                        value={SearchStore.searchWord}
                    />
                </div>
                <div className="left_content_hot">
                        <img 
                            src={
                                isSearch ? 
                                ImageSet.book : 
                                ImageSet.Hot
                            }
                        />
                        <div>{
                            isSearch ? '文章' : '热点'
                    }</div>
                </div>
                {
                    isSearch ?
                    this.renderSearch() :
                    this.renderHotData()
                }
                <div 
                                className="articles_loading_more"
                                onClick={() => {
                                    SearchStore.isSearch ?
                                    SearchStore.moreSearchAction() :
                                    SearchStore.getMoreHotType()
                                }}
                            >
                                    {
                                        dataList.length == 0 ?
                                        <div className="loading_black">
                                            <img src={ImageSet.dataBlack}/>
                                            列表是空的...
                                        </div> :
                                        '点击加载更多...'                                        
                                    }
                                    
                </div>
            </div>
        )
    }
    render() {
        const {SearchStore, HotDataStore, EditorStore, EditorDraftStore, ArticleStore} = this.props.store
        const {isRebackTop} = this.state
        const {filterList, dataList, isSearch, isShowDetail} = SearchStore
        const {editorTextCount} = EditorDraftStore
        const {activeTitle, acticleStatusTxt} = ArticleStore   
        return(
            <div className="editor_page">
                {
                    this.renderCustomerFeedback()
                }
                <div className="page_header">
                    <div className="header_left">
                        <img 
                            src={ImageSet.Reback}
                            onClick={()=>{window.history.go(-1)}}
                            style={{width:'8px'}}
                        />
                        <a className="header_txt" href="/hot.html">热点发现</a>
                        <a className="header_txt" href="/myArticle.html">我的文章</a>
                        {/* 自动保存 */}
                        <span style={{fontSize:'12px', color:'#ddd', marginLeft:'20px'}}>
                            {acticleStatusTxt}
                        </span>
                    </div>
                    <div className="header_right">
                    <a 
                        className="header_txt" 
                        href="####"
                        style={{
                            opacity:'.5',
                            cursor:'not-allowed'
                        }}
                    >产品介绍</a>
                    <a className="header_txt"
                        onClick={() => {
                            this.setState({
                                isOpenFankui: true
                            })
                        }}
                        href="####"
                    >反馈</a>
                    <a 
                        className="header_txt" href="####"
                        // onClick={() => {

                        // }}
                        style={{
                            cursor:'not-allowed',
                            opacity:"0.3"
                        }}
                    >
                        帮助
                    </a>
                    {/* <img src="http://thirdwx.qlogo.cn/mmopen/iahdQicCC5VBQrLNJ3ictmGoOMpPmjhlZYsvYgexnFlmdjf8icSvh9GAjnmibcS93Zasz9hCM2EdJiaMsQia9qJDtltUF4yOMpvg0DdZvnXzIHRtPY/132" className="header_user"/> */}
                    <div className="header_user">
                    <VulcanLoginCom/>
                    </div>
                    </div>
                </div>
                <div 
                    className="page_toolbox"
                    style={this.state.isHideLeft ? {right: '15%'} : {}}
                >
                    <div 
                        className="toolbox_tips_over" 
                    >
                        <AnimationBtnLiCom
                            clickModal={() => {
                                this.clickModalRight()
                            }}
                            closeModal={() => {
                                this.setState({
                                    isHideRight: true,
                                })
                            }}
                        />
                            {/* <div onClick={() => {
                                this.clickModalRight()
                            }}>
                                <BtnTipsCom txt="翻译" imgUrl={ImageSet.Translate} isCircleTips={this.state.isCircleTips}/>
                            </div>
                            <div>
                                <BtnTipsCom txt="质量检测" imgUrl={ImageSet.Quality} isCircleTips={this.state.isCircleTips}/>
                            </div>
                        <div>
                            <BtnTipsCom txt="全网发文" imgUrl={ImageSet.Web} isCircleTips={this.state.isCircleTips}/>
                        </div>
                        <div>
                        <BtnTipsCom txt="一键复制" imgUrl={ImageSet.Copy} isCircleTips={this.state.isCircleTips}/>
                        </div>
                        <div>
                        <BtnTipsCom txt="AI工具" imgUrl={ImageSet.Ai} isCircleTips={this.state.isCircleTips}/>
                        </div>
                        <div>
                        <PreviewModalCom imgUrl={ImageSet.Preview} isCircleTips={this.state.isCircleTips}/>
                        </div> */}
                    </div>
                  
                </div>
                <div className="page_article">
                    <div className="article_tips">
                        {/* 编辑左侧-素材-阅读历史 */}
                        <div className="left_other_tip">
                            <div className="other_tip_box other_tip_active">
                                <div>
                                    <img src={ImageSet.Material}/>
                                </div>
                                <div className="tip_box_txt">
                                    素材
                                </div>
                            </div>
                            <div 
                                className="other_tip_box"
                                style={{
                                    opacity:'0.5',
                                    cursor:'not-allowed'
                                }}
                            >
                                <div>
                                    <img src={ImageSet.ReadHistory}/>
                                </div>
                                <div className="tip_box_txt">
                                    阅读历史
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        className="article_left"
                        style={this.state.isHideLeft ? {width: '0px', minWidth:'0px'} : {}}
                    >
                        {/* 返回顶部 */}
                       
                            <div 
                                className="articles_reback_top"
                                style={
                                    isRebackTop ?
                                    {display:'flex'} :
                                    {display:'none'}
                                }
                                onClick={() => {
                                    this.scrollDom.scrollTop = 0
                                }}
                            >
                                <img src={ImageSet.rebackTop}/>
                            </div> 
                        
                        
                        {/* 编辑左侧-文章 */}
                       <div 
                            className="left_content"
                            style={this.state.isHideLeft ? {display:'none'} : {display:'block'}}
                            ref={e => this.scrollDom = e}
                        >
                            <LoadingFixedCom 
                                show={SearchStore.loading}
                                // show='true'
                            />
                            {
                                isShowDetail ?
                                this.renderListDetails() :
                                this.renderSearchHotList()
                            }
                        </div> 
                    </div>
                    <div className="article_right">
                        {/* 编辑右侧区域-文章弹出btn */}
                        <div 
                            className="right_modal_btn"
                        >
                            <img 
                                src={this.state.isHideLeft ? ImageSet.ModalRight : ImageSet.ModalLeft}
                                // onMouseOver={() =>{}}
                                onClick={() => {
                                    this.clickModalLeft()
                                }}
                            />
                        </div>

                        {/*  编辑器*/}
                        <div 
                            className="right_editor"
                            // style={
                            //     this.state.isHideRight ?
                            //     {}:
                            //     {paddingRight:'20%'}
                            // }
                        >
                            {/* <div 
                                className="editor_toolbar"
                            >
                                <div className="toolbar_main">
                                   {
                                       this.renderEditorToolBar()
                                   }
                                </div>
                            </div> */}
                            <div 
                                className="editor_working"
                            >
                                {
                                    this.renderReactDraftWys()
                                }
                                {/* <div 
                                    className="working_main"
                                >

                                  <div className="working_main_input">
                                        <input 
                                            type="text" 
                                            maxLength="50" 
                                            placeholder="无标题" 
                                            className="title-input"
                                            value={
                                                this.state.textInputValue.length >= 30 ?
                                                this.state.textInputValue.substr(0,30) :
                                                this.state.textInputValue
                                            }
                                            onChange={(e) => {
                                                this.setState({
                                                    textInputValue: e.target.value
                                                })
                                                ArticleStore.activeTitle = e.target.value
                                                ArticleStore.saveOrUpdateArticle()
                                            }}
                                        /> 
                                        <div 
                                            className="title-limit-box"
                                        >
                                        {
                                            this.state.textInputValue.length >= 30 ?
                                            '30' :
                                            this.state.textInputValue.length
                                        }
                                        /30
                                        </div>
                                  </div>


                                  <div className="working_main_editor">
                                    <div 
                                        className="empty-hint"
                                        style={
                                            this.state.isFoucs || ArticleStore.activeContent != '' ? 
                                            {display: 'none'}: 
                                            {}
                                        }
                                    >
                                      
                                    </div>
                                   
                                    <div 
                                       
                                        className="main_editor_body"
                                    >
                                    {
                                        // this.renderEditorWork()
                                        // this.renderReactDraftWys()
                                    }
                                    </div>
                                  </div>

                                  <div className="working_main_word_count">
                                      <div className="count_words">
                                        {editorTextCount}/字
                                      </div>
                                      <div className="count_imgs">
                                        0/图
                                      </div>
                                      <div className="count_videos">
                                        0/视频
                                      </div>
                                  </div>

                                </div> */}

                            </div>
                        </div>

                        {/* 编辑右侧区域-AI弹出层 */}
                        <div 
                            className="right_editor_modal"
                            style={this.state.isHideRight ? {width: '0px'} : {}}
                        >
                            <div className="editor_modal_close">
                               <div>
                                    {/* {EditorStore.tipsTxtActive} */}
                               </div>
                               <img 
                                    src={ImageSet.closeBlack}
                                    onClick={() => {// 关闭右侧窗口
                                        this.setState({
                                            isHideRight: true,
                                        })
                                        EditorDraftStore.isTimingTrans = false
                                    }}
                               />
                            </div>
                            {
                                EditorStore.tipsTxtActive == '翻译'?
                                <TranslateModalCom/> :
                                (
                                    EditorStore.tipsTxtActive == '全网发文' ?
                                    <WebsOutModalCom/> : ''
                                )
                            }
                            {/* <TranslateModalCom/> */}
                            {/* <WebsOutModalCom/> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    // 点击-列表详情页
    renderListDetails(){
        const {SearchStore, HotDataStore, EditorStore, ArticleStore} = this.props.store
        const {filterList, dataList, isSearch,articelInfo} = SearchStore
        const _that = this
        return (
            <div className="render_list_detail">
                <div className="list_detail_main">
                    <div className="detail_main_close">
                        <div className="close_fff"></div>
                        <div 
                            className="close_box"
                            onClick={() => {
                                SearchStore.isShowDetail = false
                                this.scrollDom.scrollTop = this.state.scrollDomTop
                            }}
                        >
                            <img src={ImageSet.closeBlack}/>
                            关闭文章
                        </div>
                    </div>
                    <div 
                        className="detail_main_content"
                        ref={e => this.scrollDetailDom = e}
                    >
                        <div className="detail_title">
                            {articelInfo.title}
                        </div>
                        <div className="detail_others">
                            <div className="others_time">
                            {articelInfo.createTime}
                            </div>
                            <div className="others_source">
                            {articelInfo.author}
                            </div>
                        </div>
                        <div 
                            className="detail_sunmmary"
                            style={
                                articelInfo.content == '' ?
                                {display:'none'} :
                                {}
                            }
                        >
                            <div className="summmary_title">
                                核心内容
                                <span>由AI智能获取</span>
                            </div>
                            <div 
                                style={{
                                    fontSize:"12px",
                                    marginTop:'15px'
                                }}
                                dangerouslySetInnerHTML={{__html: articelInfo.content}}
                            >
                                {/* {articelInfo.content} */}
                            </div>
                        </div>
                        <div 
                            className="detail_details_txts_main"
                            // dangerouslySetInnerHTML={{__html: articelInfo.zhContent}}
                            // onMouseUp={(e) => {
                            //     _that.hoverSelectedTxt(e)
                            // }}
                        >
                            <div
                                className="detail_details_txts"
                                dangerouslySetInnerHTML={{__html: articelInfo.long_content}}
                                onMouseUp={(e) => {
                                    e.stopPropagation()
                                    _that.hoverSelectedTxt(e)
                                }}
                            ></div>
                            {/* 引用文本小弹窗 */}
                            <div 
                                className="article_quote_modal"
                                style={
                                    _that.state.closeQuote ?
                                    {display:'none'}:
                                    {}
                                }
                                onClick={() => {
                                    // 将文本插入到编辑器内部
                                    
                                    // this.editor._insertText(_that.state.quoteActiveTxt)
                                    this.editor_wys._insertText(_that.state.quoteActiveTxt)
                                    ArticleStore.activeContent = _that.state.quoteActiveTxt
                                    _that.setState({
                                        closeQuote: true
                                    })
                                }}
                            >
                                引用文本
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
    // 热点列表页
    renderHotData(){
        const {SearchStore, HotDataStore, EditorStore} = this.props.store
        const {isRebackTop} = this.state
        const {filterList, dataList, isSearch} = SearchStore
        return <div className="render_hot_data">
                    {/* hot-words */}
                    <div className="left_content_hot_words">
                                <div className="hot_words_txt">
                                        <div 
                                            className={
                                                    !SearchStore.isSearch && this.state.activeHotTypeI == -1 ?
                                                    'hot_word_list hot_word_list_active' :
                                                    'hot_word_list'
                                            }
                                            onClick={() => {
                                                    SearchStore.getHotType('1', '全部')
                                                    this.setState({
                                                        activeHotTypeI: -1
                                                    })
                                                }}
                                            >
                                               推荐
                                            </div>
                                    {
                                        HotDataStore.hotTypesUser && HotDataStore.hotTypesUser.map((item, index) => {
                                            return <div 
                                                // className="hot_word_list"
                                                className={
                                                    !SearchStore.isSearch && this.state.activeHotTypeI == index ?
                                                    'hot_word_list hot_word_list_active' :
                                                    'hot_word_list'
                                                }
                                                key={`hot${index}`}
                                                onMouseOver={() => {}}
                                                onClick={() => {
                                                    SearchStore.getHotType('1', item)
                                                    this.setState({
                                                        activeHotTypeI: index
                                                    })
                                                }}
                                            >
                                                {item}
                                            </div>
                                        })
                                    }
                                    
                                    <div className="hot_word_more">
                                        <div
                                            style={{
                                                width:'30px',
                                                display:'flex',
                                                alignItems:'center',justifyContent:'center',
                                            }}
                                        >
                                            <TypeAllBoxCom/>
                                        </div>
                                    </div> 
                                      
                                </div>
                                <div 
                                    className="hot_words_select_option"
                                    style={{
                                        marginRight:'10px'
                                    }}
                                >
                                    <SelectOptionCom 
                                        propClick={(index) => {
                                            SearchStore.filterVulcan = index
                                            SearchStore.filterVulcanList()
                                        }}
                                    />
                                </div>
                    </div>
                    {/* hot-data */}
                    <div className="left_content_articles">
                                {
                                    (filterList.length > 0 ? filterList : dataList).map((item, index) => {
                                        return <div
                                                className="article_list"
                                                key={`article_list${index}`}
                                                onClick={() => {
                                                    const scTop = this.scrollDom.scrollTop
                                                    this.setState({
                                                        scrollDomTop: scTop
                                                    })
                                                    this.getArticleInfo2(item,()=>{
                                                        this.scrollDom.scrollTop = 0
                                                    })
                                                    
                                                }}
                                            >
                                            <ArticleDivListCom 
                                                src={item.tagPic || 'https://www.vulcandata.cn/_image_1_1/2.jpg'}
                                                title={item.title || '无标题'}
                                                content={item.content || '无内容'}
                                                source={item.author || '无'}
                                                keyWords={item.keyWord || ['无']}
                                                time={item.createTime || '无'}
                                            /> 
                                        </div>
                                    })
                                }
                                {/* <div 
                                    className="articles_loading_more"
                                    onClick={() => {
                                        SearchStore.isSearch ?
                                        SearchStore.moreSearchAction() :
                                        SearchStore.getMoreHotType()
                                    }}
                                >
                                    {
                                        dataList.length == 0 ?
                                        <div className="loading_black">
                                            <img src={ImageSet.dataBlack}/>
                                            列表是空的...
                                        </div> :
                                        '点击加载更多...'                                        
                                    }
                                    
                                </div> */}
                                
                           </div>
        </div>
    }
    // 搜索列表页
    renderSearch(){
        const {SearchStore, HotDataStore, EditorStore} = this.props.store
        const {dataList, newDataList, activeKeyWord} = SearchStore
        let searchParams = [
            {
                title: SearchStore.dataShowStr,
                ...SearchStore.dateParams
            },
            // 地区
            // {
            //     title: SearchStore.countryNameZhTitle,
            //     ...SearchStore.countryNameParams
            // },
            {
                title: SearchStore.languageCodeKeyTitle,
                ...SearchStore.languageCodeParams
            },
            // 媒体影响力
            // {
            //     title: SearchStore.mediaLevelKeyTitle,
            //     ...SearchStore.mediaLevelParams
            // },
            {
                title: SearchStore.sentimentIdKeyTitle,
                ...SearchStore.sentimentIdParams
            }
        ];
        return <div
                className="render_search_page"
            >
                {/* keyword_filter */}
                <div className="filter_keyword_line_box">
                    <FilterKeysBoxCom
                        wordsFilters={SearchStore.keyWordsTabData}
                        isShowAllBtn={activeKeyWord == ""}
                        // keyWordsTop={SearchStore.keyWordsTop}
                    />
                </div>
                {/* 搜索条件 */}
                {/* <SelectGroupCom 
                    titles={searchParams}
                /> */}
                <div  
                    // style={{border:'solid 1px red'}}
                >
                {/* 文章内容list */}
                {
                    (newDataList.length > 0 ? newDataList : dataList).map((item, index)=>{
                        return <div 
                                className="editor_base_nav_layout"
                                key={index}
                                onClick={() => {
                                    
                                    const scTop2 = this.scrollDom.scrollTop
                                    // console.log(scTop2)
                                    this.setState({
                                        scrollDomTop: scTop2
                                    })
                                    this.getArticleInfo(item,()=>{
                                        this.scrollDom.scrollTop = 0
                                    })
                                }}
                            >
                                    <ItemSearchCom
                                        // isShowSummary={articelInfo.content == ''}  
                                        index={index}
                                        title={item.title}
                                        content={item.content?item.content.replace(/<em>/g, "").replace(/<\/em>/g, ""):item.content} 
                                        source={item.author}
                                        date={item.createTime}
                                        keywords={
                                            item.keyWord.length > 5 ? item.keyWord.slice(0,5) : item.keyWord
                                        }
                                        tagPic={item.tagPic}
                                        searchWord={SearchStore.searchWord}
                                    />
                                </div>
                    })
                }
                </div>
            </div>
    }
    // 编辑器——主要编辑区域——react-draft-wysing
    renderReactDraftWys(){
        const {EditorDraftStore, userStore} = this.props.store
        return (
            <div className="render_react_draft_wys">
                <ReactDraftWys
                    ref={
                        (editor)=>{
                            this.editor_wys = editor
                            EditorDraftStore.editorClassExport = editor
                        }
                    }
                />
                {
                    this.renderDraftWysTitle()
                }
                {
                    this.renderDraftWysBottom()
                }
            </div>
        )
    }
    renderDraftWysTitle(){
        const that = this
        const {SearchStore, HotDataStore, EditorStore, EditorDraftStore, ArticleStore} = this.props.store
        return (
            <div className="render_draft_wys_title">
                <div className="working_main_input">
                    <input 
                        type="text" 
                        maxLength="50" 
                        placeholder="无标题" 
                        className="title-input"
                        value={
                            that.state.textInputValue.length >= 30 ?
                            that.state.textInputValue.substr(0,30) :
                            that.state.textInputValue
                        }
                        onChange={(e) => {
                            that.setState({
                                textInputValue: e.target.value
                            })
                            ArticleStore.activeTitle = e.target.value
                            ArticleStore.saveOrUpdateArticle()
                        }}
                    /> 
                    <div 
                        className="title-limit-box"
                    >
                        {
                            that.state.textInputValue.length >= 30 ?
                                '30' :
                            that.state.textInputValue.length
                        }
                        /30
                    </div>
                </div>
            </div>
        )
    }
    renderDraftWysBottom(){
        const {SearchStore, HotDataStore, EditorStore, EditorDraftStore, ArticleStore} = this.props.store
        const {editorTextCount, editorImgCount} = EditorDraftStore
        return (
            <div className="render_draft_wys_bottom">
                <div className="working_main_word_count">
                    <div className="count_words" style={{fontSize:'12px'}}>
                        字数 {editorTextCount}
                    </div>
                    {/* <div className="count_imgs">
                    {editorImgCount}/图
                    </div> */}
                    {/* <div className="count_videos">
                        0/视频
                    </div> */}
                </div>
            </div>
        )
    }
    // 编辑器-编辑区域-小
    renderEditorWork(){
        var {TContent,ToolBar,ToolButton,TDropdown} = EditorDraftBoxCom
        const {EditorDraftStore, userStore} = this.props.store
        const {
            isCropperDiv,
            isCropperModal,
            cropperTxtLeft,
            cropperTxtTop,
            cropperImgUrl,
        } = EditorDraftStore
        const That = this
        return (
            <div className="render_editor_word">
          <div 
            className="editor_layout"
            id="editor-container"
            onMouseDown={
                ()=>{
                    this.editor.focus()
                    this.setState({
                        isFoucs: true
                    })
                }
            }>
            <TContent 
                ref={
                    (editor)=>{
                        this.editor = editor
                        EditorDraftStore.editorClassExport = editor
                    }
                }
            >

            </TContent>
            {/* 裁剪框——txt */}
                
                <div
                    className="cropper_main_txt"
                    style={
                        isCropperDiv ?
                        {
                            top: cropperTxtTop,
                            left: cropperTxtLeft
                        } :
                        {display:"none"}
                    }
                    // onMouseOver={() => {
                    //     EditorDraftStore.isCoverCropper = true
                    // }}
                    // onMouseOut={() => {
                    //     EditorDraftStore.isCoverCropper = false
                    // }}
                >
                    {/* 关闭按钮 */}
                    <div 
                        className="close_cropper"
                        onClick={() => {
                            EditorDraftStore.isCropperDiv = false
                        }}
                    >
                        <img src={ImageSet.closeBlack} style={{width:'8px'}}/>
                    </div>
                    {/* 裁剪 */}
                    <div
                        onClick={() => {
                            EditorDraftStore.isCropperDiv = false
                            EditorDraftStore.isCropperModal = true
                            EditorDraftStore.isCoverCropper = false
                        }}
                    >
                        <img src={ImageSet.caiJian} style={{width:'20px'}}/>
                    </div>
                    {/* 缩小 */}
                    <div
                        onClick={() => {
                            EditorDraftStore.fangDaOrXiaoImg(false)
                        }}
                    >
                        <img src={ImageSet.suoXiao} style={{width:'20px'}}/>
                    </div>
                    {/* 放大 */}
                    <div
                        onClick={() => {
                            EditorDraftStore.fangDaOrXiaoImg(true)
                        }}
                    >
                        <img src={ImageSet.fangDa} style={{width:'20px'}}/>
                    </div>
                </div>
            
            {/* 裁剪工具——modal */}
           
            {
                isCropperModal ?
                <div
                >
                <CropperImgCom
                    uploadedImageFile={cropperImgUrl}
                    onClose={() => {
                        EditorDraftStore.isCropperModal = false
                    }}
                    onSubmit={(blob) => {
                        EditorDraftStore.isCropperModal = false
                        EditorDraftStore.isCropperDiv = false
                        const str = URL.createObjectURL(blob);
                        let newFile = new File([blob], 'newImg.jpg');
                        this.submitNewFile(newFile, userStore.token)
                        
                    }}
                />
            </div> :
            ""
            }
            
          </div>
        </div>
        )
    }
    // 编辑器-工具区域
    renderEditorToolBar(){
        const {EditorDraftStore} = this.props.store
        const {TContent, ToolBar, ToolButton, TDropdown, ImageUploader} = EditorDraftBoxCom
        return (
            <div className="render_editor_toolbar">
               
               {/* <ToolBar className="render_editor_toolbar_main"> */}
                <ToolButton 
                srcUrl={ImageSet.Ebefore} 
                label="后退" 
                onClick={()=>{this.editor._undo()}}
                />
                <ToolButton srcUrl={ImageSet.EAfter} label="前进" onClick={()=>{this.editor._redo()}}/>

                {/* <TDropdown isTxt="true" label="字体" onClick={(style)=>{this.editor._toggleInlineStyle(style)}} data={this.fontFamilyS}/> */}
                {/* <TDropdown isTxt="true" label="字号" onClick={(style)=>{this.editor._toggleInlineStyle(style)}} data={this.fontSizeS}/> */}
                <div 
                    className="toolbar_fontsize" 
                    onClick ={(e) => {
                        e.stopPropagation()
                        this.setState({
                            isShowFontsizeBox: !this.state.isShowFontsizeBox
                        })
                    }}
                >
                    <ToolButton
                    // isShowTxt="true"
                    label="字号"
                    srcUrl={ImageSet.eZiTi}
                    barStyle={{width:'13px'}}
                    // onClick ={() => {
                    //     e.stopPropagation()
                    //     this.setState({
                    //         isShowFontsizeBox: true
                    //     })
                    // }}
                    />
                    {
                        this.state.isShowFontsizeBox ?
                        <div className="fontsize_box">
                            {
                                this.renderToolBarFontSize()
                            }
                        </div> : ""
                    }
                    
                    
                </div>
                <ToolButton 
                    srcUrl={ImageSet.EBold} 
                    label="加粗" 
                    onClick={()=>{this.editor._toggleInlineStyle(`BOLD`)}}
                    barStyle={{width:'13px'}}
                    isActive={EditorDraftStore.editorHandleStyles.bold}
                />
                <ToolButton 
                    barStyle={{width:'9px'}} 
                    srcUrl={ImageSet.EXieti} 
                    label="斜体" 
                    onClick={()=>{this.editor._toggleInlineStyle(`ITALIC`)}}
                    isActive={EditorDraftStore.editorHandleStyles.italic}
                />
                {/* <ToolButton srcUrl={ImageSet.EImg} 
                    label="添加图片" 
                    onClick={
                        ()=>{
                            this.editor._insertImage('http://pic-bucket.ws.126.net/photo/0003/2019-10-08/EQUT19RK00AJ0003NOS.jpg', '杨丞琳')
                        }
                    }
                    barStyle={{width:'15px'}}
                /> */}
                 <ImageUploader insertImage={(src) => {this.editor.addImage2(src)}} srcUrl={ImageSet.EImg} />
                <div 
                    className="toolbar_color"
                    onClick={(e) => {
                        e.stopPropagation()
                        this.setState({
                            isShowColorBox: true
                        })
                    }}
                >
                    <ToolButton 
                    barStyle={{width:'12px'}} 
                    srcUrl={ImageSet.EColor}
                    label="文本颜色"
                    // onClick={(e) => {
                    //     e.stopPropagation()
                    //     this.setState({
                    //         isShowColorBox: true
                    //     })
                    // }}
                    />
                    {
                        this.state.isShowColorBox ? 
                        <div className="toolbar_color_box">
                            {this.renderToolBarColor()}
                        </div> :
                        ""
                    }
                    <div 
                        style={{
                            background:`${EditorDraftStore.editorHandleStyles.color || '#000'}`
                        }}
                        className="toolbar_color_line"
                    />
                    
                </div>
                {/* <ToolButton label="对其方式" /> */}
                {/* <ToolButton label="颜色" /> */}
                <div className="render_edtior_line">
                    <div className="line"></div>
                </div>
                <ToolButton 
                    srcUrl={ImageSet.ELeft} 
                    label="左对齐"
                    onClick={()=>{this.editor._toggleBlockType(`blockleft`)}}
                />
                <ToolButton 
                    srcUrl={ImageSet.EMid} 
                    label="居中对齐" 
                    onClick={()=>{this.editor._toggleBlockType(`blockcenter`)}}
                />
                <ToolButton 
                    srcUrl={ImageSet.ERight} 
                    label="右对齐" 
                    onClick={()=>{this.editor._toggleBlockType(`blockright`)}}
                />
                {/* <ToolButton srcUrl={ImageSet.EBoth} label="两端对齐" onClick={()=>{this.editor._toggleInlineStyle(`UNDERLINE`)}}/> */}
                
                <div className="render_edtior_line">
                    <div className="line"></div>
                </div>

                <ToolButton barStyle={{width:'20px',margin:'5px'}} srcUrl={ImageSet.EWuxu} label="有序列表" onClick={()=>{this.editor._toggleBlockType(`unordered-list-item`)}}/>
                <ToolButton barStyle={{width:'20px',margin:'5px'}} srcUrl={ImageSet.EYouxu} label="无序列表" onClick={()=>{this.editor._toggleBlockType(`ordered-list-item`)}}/>
                
                <div className="render_edtior_line">
                    <div className="line"></div>
                </div>
                <ToolButton 
                    barStyle={{width:'12px'}} 
                    srcUrl={ImageSet.EXiahua} 
                    label="下划线" onClick={()=>{this.editor._toggleInlineStyle(`UNDERLINE`)}}
                    isActive={EditorDraftStore.editorHandleStyles.underline}
                />
                {/* <ToolButton barStyle={{width:'10px'}} srcUrl={ImageSet.EH} label="标题" onClick={()=>{this.editor._toggleInlineStyle(`UNDERLINE`)}}/> */}
                {/* <ToolButton label="等宽字体" onClick={()=>{this.editor._toggleInlineStyle(`CODE`)}}/> */}
                <ToolButton srcUrl={ImageSet.EClear} label="清空文本" onClick={()=>{this.editor._clear()}}/>
                {/* <TDropdowns label="字号" /> */}
                <ToolButton barStyle={{width:'9px'}} srcUrl={ImageSet.EH} label="标题" onClick={()=>{this.editor._toggleBlockType(`blockquote`)}} />
                {/* test
                <ToolButton barStyle={{width:'9px'}} srcUrl={ImageSet.EH} label="标题" onClick={()=>{this.editor._toggleInlineStyle('12px')}} /> */}
                
            {/* </ToolBar> */}
            </div>
        )
    }
    // 编辑器-颜色控制
    renderToolBarColor(){
        const {EditorDraftStore} = this.props.store
        const {StyleButton} = EditorDraftBoxCom
        const currentStyle = EditorDraftStore.editorState.getCurrentInlineStyle()
        return(
            <div className="render_toolbar_color">
                    {this.COLORS.map((item, index) => {
                        return <div 
                                className="color_style_btn_span"
                                key={item.label}
                            >
                            <StyleButton
                            colorStyleMap={this.colorStyleMap}
                            active={currentStyle.has(item.style)}
                            label={item.label + currentStyle.has(item.style)}
                            onToggle={() => {
                                this.editor._toggleColor(item.style)
                                this.setState({
                                    activeColor: item.style,
                                    isShowColorBox: false
                                  
                                })

                            }}
                            style={item.style}// 传red-内部获取color；
                        />
                        </div>
                    })}
            </div>
        )
    }
    // 编辑器-字体控制
    renderToolBarFontSize(){
        return(
            <div className="render_toolbar_fontsize">
                {
                    this.fontSizeS.map((item, index) => {
                        return <div 
                            className="fontsize_list"
                            key={`fontsize_list${index}`}
                            onClick={() =>{
                                this.editor._toggleInlineStyle(item.style)
                                this.setState({
                                    isShowFontsizeBox: false
                                })
                            }}
                            style={{fontSize:`${item.style}`}}
                        >
                            {item.label}
                        </div>
                    })
                }
            </div>
        )
    }
    // 反馈页面
    renderCustomerFeedback(){
        const that = this
        return <MaskCom show={this.state.isOpenFankui}>
            <div className="render_customer_feedback">
               <div className="customer_feedback_close">
                   <img 
                        src={ImageSet.closeBlack} 
                        style={{width:'10px'}}
                        onClick={() => {
                            that.setState({
                                isOpenFankui: false
                            })
                        }}
                   />
               </div>
               <div className="customer_feedback_main">
                   <img src={ImageSet.erWei} className="feedback_main_er"/>
                   <div className="feedback_main_content">
                       <div className="feedback_main_title">
                       俯瞰公众账号
                       </div>
                       <div className="feedback_main_txt">
                           
                       您可以扫描二维码关注俯瞰服务号，来
对我们的产品进行反馈！
                            
                       </div>
                   </div>
               </div>
            </div>
        </MaskCom>
    }
    initEditor(){
        // 右侧显示图标
        this.setState({
            isCircleTips: document.documentElement.clientWidth < 1900
        })
        window.addEventListener('click', () =>{
            this.setState({
                isShowFontsizeBox: false,
                isShowColorBox: false
            })
        })
        window.addEventListener('resize', () => {
            this.setState({
                isCircleTips: document.documentElement.clientWidth < 1900
            })
        })
    }
    clickModalLeft(){
        this.setState({
            isHideLeft: !this.state.isHideLeft,
            isHideRight: true
        })
    }
    clickModalRight(){
        this.setState({
            isHideRight: !this.state.isHideRight,
            isHideLeft: true
        })
    }
    getArticleInfo(item, callback){
        const {SearchStore} = this.props.store
        SearchStore.getArticleInfo(item, callback)
    }
    getArticleInfo2(item, callback){
        const {SearchStore} = this.props.store
        SearchStore.getArticleInfo2(item, callback)
    }
    // 保存文章
    saveOrUpdateArticleContent(){
        const {userStore, ArticleStore} = this.props.store
        var token = userStore.token
        ArticleStore.setToken(token)
        ArticleStore.saveOrUpdateArticle()
    }
    // 将裁剪图片上传到服务器
    async submitNewFile(file, token) {
        const {SearchStore, HotDataStore, EditorStore, EditorDraftStore, ArticleStore} = this.props.store
        if(file && token){
            let result = await api.upLoadFile(file, token);
            result = result.data;
            if(result && 200 == result.code){
                let message = result.data;
                var _iframe = document.getElementById('editor-container');
            var iframe_imgs = _iframe.getElementsByClassName('editor_work_img');
                if(iframe_imgs) {
                    // 替换图片的src
                    iframe_imgs[EditorDraftStore.cropperImgIndex].src = message.url
                }
            }
        }
    }
    // 鼠标经过-文字
    hoverSelectedTxt(e){
        var txt = window.getSelection ? window.getSelection().toString(): document.selection.createRange().text;
        
        if(txt == ""){//当选中内容为空时，阻止事件发生
            window.event? window.event.cancelBubble = true : e.stopPropagation();
           this.setState({
            closeQuote: true
           })
        }else{
            var oDiv=document.getElementsByClassName('article_quote_modal')[0];
            // console.log(e.nativeEvent)
            // console.log(e.nativeEvent.offsetX + '===========' + e.nativeEvent.offsetY)
            oDiv.style.left=e.nativeEvent.offsetX + 10 + 'px';  // 指定创建的DIV在文档中距离左侧的位置
            oDiv.style.top=e.nativeEvent.offsetY - 45 + 'px';  // 指定创建的DIV在文档中距离顶部的位置
            this.setState({
                closeQuote: false,
                quoteActiveTxt: txt
            })
        }
        
    }
}
export default Index