import React from 'react';
import {observer, inject} from 'mobx-react';
import TopBarCom from '../../component/topBar'
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Test extends React.Component{
    render() {
        return(
            <div className="vip_privilege_page">
                <div className="privilege_page_main">
                    <div className="privilege_header">
                        <TopBarCom 
                            showTxt="会员特权" 
                        />
                    </div>
                    <div className="privilege_content">
                    <div className="privilege_content_main">
                            <div className="content_box_one">
                                <div className="card">
                                    <div className="card__side card__side--front-1">
                                        <div className="card_one_main">
                                            <div className="front_title">
                                                免费版
                                            </div>
                                            <div className="front_price">
                                                ￥<span>0</span>元
                                            </div>
                                            <div className="front_btn">
                                                <div className="btn_main">
                                                    免费试用
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card_one_selected">
                                            
                                        </div>
                                    </div>
                                    <div className="card__side card__side--back card__side--back-1">
                                    asfasfasfas
                                    </div>
                                </div>
                            </div>

                            <div className="content_box_one">
                                <div className="card">
                                    <div className="card__side card__side--front-2">
                                    lakjdflasjdfhlasjdfhlakjsdhfaks
                                    </div>
                                    <div className="card__side card__side--back card__side--back-2">
                                    asdasdadsasd
                                    </div>
                                </div>
                            </div>

                            <div className="content_box_one">
                            <div className="card">
                                <div className="card__side card__side--front-3">
                                lhladjfhlakjsdhfkjashfaksjdhfkas
                                </div>
                                <div className="card__side card__side--back card__side--back-3">
                                安科技的刷卡机沙大海
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Test