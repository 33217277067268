import React ,{Component} from 'react';
import './lanrenzhijia.css';
import './loader.css';
import './index.scss';
import './aniCode.scss'
/**
 *  网络加载无序进度条
 */
export default class index extends Component{
    render(){
        return(
                    <div className={this.props.show ? "main loading_layou_window" : "loading_none"}>
                        {/* 原本的加载进度条 */}
                        {/* <div style={{width: 200, textAlign: 'center'}}>
                            <div className="loader">
                                <div className="loader-inner line-scale-pulse-out">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <br/>
                            <div style={{color: '#e94c15', fontWeight: 'bold', fontSize: 15}}>数据解析中，请耐心等待。</div>
                        </div> */}

                        {/* 新样式的加载条 */}
                        {/* <div className="render_animation_code">
                            <div id="container">
                                <div className="divider"></div>
                                <div className="loading-text">
                                    <span className="letter">L</span>
                                    <span className="letter">o</span>
                                    <span className="letter">a</span>
                                    <span className="letter">d</span>
                                    <span className="letter">i</span>
                                    <span className="letter">n</span>
                                    <span className="letter">g</span>
                                </div>
                            </div>
                        </div> */}
                        <div 
                            className="render_animation_loader"
                        >
                            <div className="loader">Loading...</div>
                        </div>
                    </div>
                
                )
                
        
    }
}