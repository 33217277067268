import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    topics = ['最新', 'Vulcan值']
    state = {
        isShowSelect: false,
        activeIndex: 0,
        isHovering: false
    }
    render() {
        const {SearchStore, HotDataStore, EditorStore} = this.props.store
        return(
            <div 
                className="select_option_main"
            >
               <div 
                    className="keyword_select_option"
                >
                                    <div 
                                        onClick={() => {
                                            this.setState({
                                                isShowSelect: !this.state.isShowSelect
                                            })
                                        }}
                                        className="option_main"
                                    >
                                        <div className="option_txt">
                                            {
                                                this.state.activeIndex == 0 ? '最新' : 'Vulcan值'
                                            }
                                        </div>
                                        <div style={{width:'10px'}}></div>
                                        <div className="option_img">
                                            <img 
                                                src={ImageSet.ArrowDown}
                                                style={this.state.isShowSelect ?
                                                {transform:"rotate(180deg)"} : {}}
                                            />
                                        </div>
                                    </div>
                                    {
                                    this.state.isShowSelect ?
                                    <div 
                                        className="keyword_option_box"
                                        onMouseOut={() => {
                                            this.setState({
                                                isHovering: false
                                            })
                                        }}
                                    >
                                        {
                                            this.topics.map((item, index) => {
                                                return <div 
                                                    key={`type_topic${index}`}
                                                    className="option_box_txt"
                                                    // className={this.state.activeIndex == index ? 
                                                    //         'option_box_active option_box_txt' :
                                                    //         'option_box_txt'
                                                    // }
                                                    style={
                                                        this.state.activeIndex == index ?
                                                        (this.state.isHovering ?
                                                        {color:'rgba(237,97,70,1)'} :
                                                        {background:'linear-gradient(0deg,rgba(237,97,70,.1) 0%,rgba(232,123,102,.1) 100%)',color:'rgba(237,97,70,1)'}) : {}
                                                    }
                                                    onClick={() => {
                                                        this.setState({
                                                            activeIndex: index
                                                        })
                                                        this.setState({
                                                            isShowSelect: false
                                                        })
                                                        // SearchStore.filterVulcan = index
                                                        // SearchStore.filterVulcanList()
                                                        this.props.propClick && this.props.propClick(index)
                                                    }}
                                                    onMouseOver={() => {
                                                        this.setState({
                                                            isHovering: true
                                                        })
                                                    }}
                                                >
                                                    {item}
                                                </div>
                                            })
                                        }
                                    </div> :
                                    ""
                                }
                                </div>
            </div>
        )
    }
}
export default Index