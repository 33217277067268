import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    render() {
        return(
            <div className="home_intro_vulcan">
                        <div className="txt_title">
                            <div className="title_txt">
                                {this.props.title}
                            </div>
                            <div className="title_undeline"></div>
                        </div>
                        <div className="txt_content">
                        {this.props.content}
                        </div>
                        <div className="txt_more">
                        {/* {this.props.moreTxt}
                        <img src={ImageSet.HomeArrowRight}/> */}
                        </div>
            </div>
        )
    }
}
export default Index