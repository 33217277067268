/*
    * 移动号码包括的号段：134/135/136/137,138,139；
    *                     147/148(物联卡号)；
    *                     150/151/152/157/158/159；
    *                     165（虚拟运营商）；
    *                     1703/1705/1706（虚拟运营商）、178；
    *                     182/183/184/187/188
    *                     198

    * 联通号段包括：130/131
    *               145
    *               155/156
    *               166/167(虚拟运营商)
    *               1704/1707/1708/1709、171
    *               186/186
    *
    * 电信号段包括： 133
    *                153
    *                162(虚拟运营商)
    *                1700/1701/1702(虚拟运营商)
    *                180/181/189
    *                191/199
    * */
export function IsPhone(phone) {
    let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
    return reg.test(phone);
}
/**
 * 是否是数字
 * @param {*} value 
 */
export function IsNaN(value) {
    return typeof value === 'number' && !isNaN(value);
}
/**
 * 过滤文字<BR/>
 */
export function HandleContent(data){
    var dataCon = data.split('<BR/>')
    return dataCon.concat(' ')
}
/**
 * 处理时间-...前
 * date-2019-10-31 10:52:14
 */
export function  HandleDate(date){
    var date1 = new Date(date)
    var date2 = new Date()
    const diff = date2.getTime() - date1.getTime()
    const diffDate = diff / (24 * 60 * 60 * 1000);// 天
    if(diffDate > 30){
        return parseInt(diffDate/30) + '月前'
    } else if (diffDate >= 1){
        return parseInt(diffDate) + '天前'
    } else if( diffDate > 0){
        if(parseInt(diffDate * 24) == 0){
            return parseInt(diffDate * 24 * 60) + '分钟前'
        }
        return parseInt(diffDate * 24) + '小时前'
    }
}
/**
 * 处理时间-13:10
 * date-2019-10-31 10:52:14
*/

export function GetHourMinute(date){
    var date1 = new Date(date)
    var hour1 = date1.getHours()
    var minute1 = date1.getMinutes()
    if(hour1 < 10){
        hour1 = '0' + hour1
    }
    if(minute1 < 10){
        minute1 = '0' + minute1
    }
    return hour1 + ':' + minute1
}
/**
 * 判断浏览器
*/
export function myBrowser(){
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isOpera = userAgent.indexOf("Opera") > -1;
    if (isOpera) {
        return "Opera"
    }; //判断是否Opera浏览器
    if (userAgent.indexOf("Firefox") > -1) {
        return "FF";
    } //判断是否Firefox浏览器
    if (userAgent.indexOf("Chrome") > -1){
        return "Chrome";
    }
    if (userAgent.indexOf("Safari") > -1) {
        return "Safari";
    } //判断是否Safari浏览器
    if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {
        return "IE";
    }; //判断是否IE浏览器
}
export function getRandom(min,max){
    min=Math.ceil(min);
    max=Math.floor(max);
    return Math.floor(Math.random()*(max-min+1))+min
}

// 毫秒值更改为——分秒值（50000=00:50）
export function millToSecond(mss){// fmt——秒单位
    var hours = parseInt((mss % (60 * 60 * 24)) / (60 * 60));
    if(hours < 10) {
        hours = "0" + hours;
    }
    var minutes = parseInt((mss % (60 * 60)) / (60));
    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    var seconds = Math.round(mss % (60));
    if(seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
    
}