import React from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom"; //Switch ,Prompt, Link, Redirect
import { Provider} from 'mobx-react';
import Store from './store';
import EditorPage from './pages/EditorPage'
import HotPage from './pages/HotPage'
import HotListPage from './pages/HotListPage'
import HomePage from './pages/HomePage'
import MyArticelPage from './pages/MyArticlePage'
import VipPage from './pages/VipPage'
import AIPage from './pages/AIPage'
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';

class App extends React.Component{
  render() {
    return (
      <Provider store={Store}>
          <Router>
            <div>
              {/* 编辑页面 */}
              <Route path='/' exact component={HomePage}></Route>
              <Route path='/editor.html' exact component={EditorPage}></Route>
              {/* 热点页面 */}
              <Route path='/hot.html' exact component={HotPage}></Route>
              {/* 热点列表页 */}
              <Route path='/hotList.html' render={()=>{return <HotListPage/>}} />
              {/* 我的文章 */}
              <Route path='/myArticle.html' exact component={MyArticelPage}></Route>
              {/* 会员特权 */}
              <Route path='/vip.html' render={()=>{return <VipPage/>}} />
              {/* 会员特权 */}
              <Route path='/AI.html' render={()=>{return <AIPage/>}} />
            </div>
          </Router>
      </Provider>
    )
  }
}

export default App;
