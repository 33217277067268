import {observable, action} from 'mobx';
import api from '../utils/Api'
function apiCallBack(result){
    if(result && result.status){
        return result.data;
    }else if(result && !result.status){
        return result
    }
}
Array.prototype.indexOf = function(val) { 
    for (var i = 0; i < this.length; i++) { 
    if (this[i] == val) return i; 
    } 
    return -1; 
};
Array.prototype.remove = function(val) { 
    var index = this.indexOf(val); 
    if (index > -1) { 
        this.splice(index, 1); 
    } 
};
class sendSto{
    @observable id = null;
    @observable isShowCover = false;//是否显示封面
    @observable title = "";
    @observable cover = "";
    @observable articleAbstract = "";//文章摘要
    @observable articleUrl = "";//原文链接
    @observable author = "";//作者
    @observable isOpenRemark = false;//是否开启评论
    @observable wxList = [];
    @observable dataList = [];
    @observable content="";
    @observable error="";
    @observable wx="";

    @observable playtest = ""


    @action clearError(){
        this.error = "";
    }

    @action setContent(txt){
        this.content = txt;
    }

    @observable weixinList = false;

    

    @action updateWeixinList(){
        this.weixinList = !this.weixinList;
    }

    @action setId(id){
        this.id = id;
    }

    @action setTitle(title){
        this.title = title;
    }

    @action setCover(cover){
        this.cover = cover;
    }

    @action setIsShowCover(){
        this.isShowCover = !this.isShowCover;
    }

    @action setArticleAbstract(txt){
        this.articleAbstract = txt;
    }

    @action setArticleUrl(url){
        this.articleUrl = url
    }

    @action setAuthor(author){
        this.author = author;
    }

    @action setIsOpenRemark(){
        this.isOpenRemark = !this.isOpenRemark;
    }

    @action addWxList(appId){
        if(this.wxList.indexOf(appId) == -1){
            this.wxList.push(appId);
        }else{
            this.wxList.remove(appId);
        }  
    }

    @action clearAll(){
        this.id = null;
        this.isShowCover = false;
        this.title = "";
        this.cover = "";
        this.articleAbstract = "";
        this.articleUrl = "";
        this.author = "";
        this.isOpenRemark = false;
        this.wxList = [];
    }

    @observable activeWxId = ''
    @action async loadData(token){
        let result = await api.getAuthorizeList(token);
        if(result && 200 == result.code){
            this.dataList= result.data
        }
        // console.log(result)
    }


    @action async selectFile(file, token){
        this.loading = true;
        let result = await api.upLoadFile(file, token);
        result = apiCallBack(result);
        // console.log(result);
        if(result && 200 == result.code){
            this.cover = result.data.url;
        }else{
            this.loading = false; 
        }
    }

    @action async saveDocment(id, content, token, callback = ''){
        // console.log(id)
        if(this.cover && this.title.length > 0){
             let result =  await api.saveOrEditArticleNew(id ? id : 0, 
                this.cover, this.title, content ? content : "<p></p>", 
                token, !this.isShowCover ? "" : 1, 
                this.author, this.articleAbstract, !this.isOpenRemark ? "" : 1, this.articleUrl,  this.wxList.join(','));


            this.id = null;
            
            if(result && 200 == result.code){
                this.error = '同步成功，可到公众号后台素材管理查看'
                callback && callback()
            }else{
                this.error = '同步失败，请检查同步信息';
                callback && callback()
            }
           

        } else{
            this.error = '请上传封面';
            callback && callback()
        } 
    } 

    @action async redict(token){
        let result = await api.getAuthorizeLink(token);
        if(result && 200 == result.code){ 
            this.wx= result.data.wx;
        }
    }
}
const SendStore = new sendSto();
export default SendStore