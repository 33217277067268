import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        //图片轮播div样式相关参数
        width: '10000px',
        transition: '500ms ease-out',
        transform: 'translatex(0px)',
        countnum: 5,     //图片数量
        curPageIndex: 0, //点击右箭头+1，左箭头减1
        viewcount: 4,    //初始展示图片数量（和div宽度相关） 
        Larrowstate: true,
        Rarrowstate: true,
    }
    render() {
        let { width,transition,transform,Larrowstate,Rarrowstate } = this.state
        let LArrowClaName = Larrowstate ? 'arrow_l' : 'img_hidden'
        let RArrowClaName = Rarrowstate ? 'arrow_r' : 'img_hidden'
        return(
            <div 
                className="vulcan_carousel_main"
            >
               <div className="bd basefix ">
                <div 
                    className="pro_list_wrap"  
                    style={{ width: width, transition: transition, transform: transform }} 
                >
                    <a className="pro_list_recomm" href="####">
                       <img src={ImageSet.partnerOne}></img>
                    </a>
                    <a className="pro_list_recomm" href="####">
                       <img src={ImageSet.partnerTwo}></img>
                    </a>
                    <a className="pro_list_recomm" href="####">
                       <img src={ImageSet.partnerThree}></img>
                    </a>
                    <a className="pro_list_recomm" href="####">
                       <img src={ImageSet.partnerFour}></img>
                    </a> 
                    <a className="pro_list_recomm" href="####">
                       <img src={ImageSet.partnerTwo}></img>
                    </a>
                </div>
            </div>
                <img 
                    className={LArrowClaName} 
                    onClick={() => this.handleclickPrev()}
                    src={ImageSet.bigLeft}
                    style={{width:'15px', position:'absolute', left:'0'}}
                />
               <img 
                    className={RArrowClaName} 
                    onClick={() => this.handleclickNext()}
                    src={ImageSet.bigRight}
                    style={{width:'15px',position:'absolute', right:'0'}}
                />
            </div>
        )
    }
    //函数
    handleclickPrev(){
        let { countnum,viewcount,curPageIndex,Rarrowstate } = this.state
        if(curPageIndex === countnum - viewcount){
            this.setState({
                Rarrowstate:true
            })
        }
        this.getPage(curPageIndex-1)
    }

    handleclickNext(){
        let { countnum,viewcount,curPageIndex,Rarrowstate } = this.state
        if (curPageIndex < 0) {
            curPageIndex = 0
        }
        if(curPageIndex === 0){
            this.setState({
                Larrowstate:true
            })
        }
        this.getPage(curPageIndex + 1)
    }

    getPage(value) {
        let { countnum,viewcount,Larrowstate,Rarrowstate} = this.state
        let n = countnum - viewcount
        //一项的宽度
        let itemwidth = 300
        let transform = 'translatex(-' + value * itemwidth + 'px)'
        value > n || 0 > value || this.setState({transform:transform})
        this.setState({
            curPageIndex: value,
        })
        if (value == 0 || value == -1) {
            this.setState({
                Larrowstate: false
            })
        }
        if (value == n) {
            this.setState({
                Rarrowstate: false
            })
        }
    }
}
export default Index