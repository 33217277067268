import {observable, action} from 'mobx';
class homes{
    @observable windowWidth = '';
    @observable windowHeight = ''

    @action getWindowWidth(){
        const wid = document.documentElement.clientWidth||document.body.clientWidth
        this.windowWidth = Math.floor(wid)
    }
    @action getWindowHeight(){
        const heig = document.documentElement.clientHeight||document.body.clientHeight
        this.windowHeight = Math.floor(heig)
    }
}
const HomeStore = new homes();
export default HomeStore