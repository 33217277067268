import {observable, action} from 'mobx';
class editors{
    @observable isPlayVideo = false;
    @observable isOpenTipsModal = false;
    @observable tipsTxtActive = ''
    @action setTipsTxt(txt){
        this.tipsTxtActive = txt
    }
    @action abc(){

    }
}
const EditorStore = new editors();
export default EditorStore