import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        sliderTime: 0,
        // 该字段是音频是否处于播放状态的属性
        playing: false,
        // 音频当前播放时长
        currentTime: 0,
        // 音频最大播放时长
        maxTime: 0,
        minTime: 0,
        step: 0.1
    }
    audio = null
    render() {
      const { srcSplit } = this.props
        return(
            <div 
                className="audio_split_video_com"
            >

                {/* 样式播放器 */}
                <div className="play_btn_schedule">
                    <div className="play_btn_line"/>
                    <div 
                      className="play_btn_line_active"
                      style={{
                        width: `${this.state.sliderTime}%`
                      }}
                    >
                        <img 
                            src={ImageSet.circle}
                            className="active_img"
                        />
                    </div>
                </div>
                <div className="play_btn_main">
                        <div>
                          {this.realFormatSecond(this.state.currentTime)}
                          /
                          {this.realFormatSecond(this.state.maxTime)}
                        </div>
                        <div className="main_plays">
                            {/* 快退  */}
                            <img 
                              src={ImageSet.fastAudio}
                              onClick={() => {
                                this.backAudio()
                              }}
                              title="快退"
                            />
                            {/* 播放 */}
                            <img
                              src = {
                                this.state.playing ?
                                ImageSet.pauseAudio :
                                ImageSet.playAudio
                              }
                              title={
                                this.state.playing ?
                                '暂停' : '播放'
                              }
                              // src={ImageSet.playAudio}
                              onClick={(e) => {
                                  e.stopPropagation()
                                  this.startPlayOrPause()
                              }}
                            />
                            {/* 快进 */}
                            <img 
                              src={ImageSet.backAudio}
                              onClick={() => {
                                this.fastAudio()
                              }}
                              title="快进"
                            />
                        </div>
                    <div>
                      <a href={this.props.srcSplit} download="vulcan" target="view_window">
                        <img 
                          src={ImageSet.audioDownload}
                          style={{
                            width:'12px'
                          }}
                        />
                      </a>
                    </div>
                            
                </div>
                {/* audio_隐藏 */}
                <audio
                    ref={ ref => this.audio = ref}
                    onPause={() => {
                      this.onPause()
                    }}
                    onPlay={() => {
                      this.onPlay()
                    }}
                    onTimeUpdate={(res) => {
                      this.onTimeupdate(res)
                    }}
                    onLoadedMetadata={(res) => {
                      this.onLoadedmetadata(res)
                    }}
                    controls="controls"
                    style={{
                      display:'none'
                    }}
                    // src='http://files.aiedit.com.cn/16k_92936e08a5c741f985f8e2b3163d68e9.mp3'
                    src={this.props.srcSplit}
                />
            </div>
        )
    }
    realFormatSecond(second) {
        var secondType = typeof second;
       
        if (secondType === "number" || secondType === "string") {
          second = parseInt(second);
       
          var hours = Math.floor(second / 3600);
          second = second - hours * 3600;
          var mimute = Math.floor(second / 60);
          second = second - mimute * 60;
       
          return (
            // hours + ":" + ("0" + mimute).slice(-2) + ":" + ("0" + second).slice(-2)
            ("0" + mimute).slice(-2) + ":" + ("0" + second).slice(-2)
          );
        } else {
          return "00:00";
        }
    }
    // 控制音频的播放与暂停
    startPlayOrPause() {
        return this.state.playing ? this.pause() : this.play();
    }
    // 快进音频-10秒
    fastAudio(){
      if(this.state.currentTime + 15 > this.state.maxTime){
        this.audio.currentTime = this.state.maxTime - 5
      } else {
        this.audio.currentTime = this.state.currentTime + 15
      }
      
    }
    // 快退音频-10秒
    backAudio(){
      if(this.state.currentTime - 15 < 0){
        this.audio.currentTime = 0
      } else {
        this.audio.currentTime = this.state.currentTime - 15
      }
      
    }
      // 播放音频
    play() {
        this.audio.play();
    }
      // 暂停音频
    pause() {
        this.audio.pause();
    }
      // 当音频播放
    onPlay() {
      this.setState({
        playing: true
      })
    }
      // 当音频暂停
    onPause() {
      this.setState({
        playing: false
      })
    }
      // 当加载语音流元数据完成后，会触发该事件的回调函数
      // 语音元数据主要是语音的长度之类的数据
    onLoadedmetadata(res) {
        const maxTime = parseInt(res.target.duration);
        this.setState({
          maxTime: maxTime
        })
    }
      // 当timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
      // 当音频当前时间改变后，进度条也要改变
    onTimeupdate(res) {
        const sliderTime = parseInt(
          (this.state.currentTime / this.state.maxTime) * 100
        )
        this.setState({
            currentTime: res.target.currentTime,
            sliderTime: sliderTime
        })

    }
    
}
export default Index