import request from './Request';
import md5 from './md5';
/**
 * 文本转语音
 * @param {*} str 
 */
function text2Voice(str, token = ""){
    return request.post(`social/textToVoice`, {content: str}, token);
}
/**
 * 文本翻译
 * @param {*} txt 
 * @param {*} srcl 
 * @param {*} nen 
 * @param {*} token 
 */
function txtTran2Txt(txt, srcl = "", nen = "", token = ""){
    var reg = /<[^<>]+>/g;
    let params = {
        content: txt ? txt.replace(reg, '').replace(/\n/g,'') : "",
        srcl: srcl,
		tatl: nen
    }
    console.log(`txtTran2Txt:${JSON.stringify(params)}`)
    return request.post(`/social/translate`, params, token)
}
/**
 * /isv4/subjectName
 * 检索专题
 */
function getSubjectName(name, token){
    return request.get(`/isv4/subjectName?subjectName=${name}`, token);
}
/**
 * 热点-list
 * @param {*} url 
 */
function getHotList(url){
    return request.get(url)
}
/**
 * 获取文章详情
 * @param {*} uuid -string-文章id
 */
function getSearchInfoByUUID(uuid,token){
    // return  request.get(`/social/info/${uuid}`, token)
    return  request.get(`/elastic/info/${uuid}`, token)
}
/**
 * 获取文章详情——热点
 * @param {*} uuid -string-文章id
 */
function getSearchInfoByUUID2(uuid,token){
    return  request.get(`/social/info/${uuid}`, token)
    // return  request.get(`/elastic/info/${uuid}`, token)
}
/**
 * 详细搜索 全条件搜索
    keyword: 搜索词
	beginDate: 开始时间
	endDate: 结束时间
	mediaLevel: 媒体级别
	highlight: 关键词高亮
	countryNameZh: 地区
	languageCode: 语言
	sentimentId: 情感标识
	mediaIdList: 信息源ID
	tagList: 行业ID
	fieldName: 排序字段
	order: 排序字段 (asc=升序 desc=降序)
	pageNo: 当前页数
	pageSize: 当前页条数
	isDataSource: 信息源范围 (0=全局信源 1=用户选择信源)
	mediaIdList: 信息源id 多个使用英文逗号分割
 */
function search(keyWrod, page, count, beginDate = "", endDate = "", countryNameZh = '', languageCode = '', mediaLevel = '',  sentimentId = '', isDataSource = 0 ,token, highlight = true, mediaIdList = "", tagList = "",  fieldName = "pubdate", order = "desc"){
    // request.abort();
    
    let params = {
        'keyword':`${keyWrod}`, 
        'pageNo': `${page}`,
        'pageSize': `${count}`,
        'beginDate': `${beginDate}`,
        'endDate': `${endDate}`,
        'mediaLevel':`${mediaLevel}`,
        'highlight':`${highlight}`,
        'countryNameZh':`${countryNameZh}`,
        'languageCode':`${languageCode}`,
        'sentimentId':`${sentimentId}`,
        'fieldName': `${fieldName}` ,
        'order':`${order}`, 
        'isDataSource': isDataSource,
        'mediaIdList': mediaIdList,
        'tagList': tagList
    }
    if(beginDate == "") delete params.beginDate;
    if(endDate == "") delete params.endDate;
    if(countryNameZh == "") delete params.countryNameZh;
    if(languageCode == "") delete params.languageCode;
    if(mediaLevel == "") delete params.mediaLevel;
    if(sentimentId == "") delete params.sentimentId;
    if(mediaIdList == "") delete params.mediaIdList;
    if(tagList == "") delete params.tagList;
    // return request.post(`/social/keyWord`, params, token ? token : "")
    return request.post(`/elastic/page`, params, token ? token : "")
}

/**
 * 获取微信登录二维码
 */
function reqCode(){
    return request.get(`/qrcode`)
}

/**
 * 定时获取登录状态
 * @param {*} event 
 */
function getQrCodeState(event) {
    return request.get(`/status?event=${event}`);
}

/**
 * 获取当前登录信息
 * @param {*} event 
 */
function getQrLoginState(event, token = null) {
    return request.post(`/de?event=${event}`, {}, token);
}

/**
 * 获取手机验证码
 * @param {*} phone 
 * @param {*} type 
 */
function getPhoneCode(phone) {
    return request.get(`/pl/send/${phone}`)
}
/**
 * 绑定手机号
 * @param {*} phone 
 * @param {*} code 
 * @param {*} token 
 */
function bindPhone(phone, code, token) {
    return request.post(`/pl/bindPhone`, {phone: phone, code: code}, token)
}
/**
 * 登录
 * @param {*} phone 
 * @param {*} code 
 */
function loginPhone(phone, code) {
    return request.post(`/pl/smsLogin`, {phone: phone, code: code}, null)
}
/**
 * 7*24h财经新闻——类型（黄金外汇、...）
 */
function channels7_24(token = ''){
    return request.get(`/7_24/channels`, token);
}
/**
 * 7*24h财经新闻
*/

function list7_24(rowNum, totalNum, id, token = ''){
    return request.get(`/7_24/list/${rowNum}/${totalNum}/${id}`,token);
}

/**
 * 保存文章
 * title-string,content-string
 */
function saveDocument(title, content, token = ''){
    return request.post(`/v4ahb/save`, {"title": title, "content": content}, token)
}

/**
 * 获取文件集合
 * @param {*} token 
 * @param {当前页数} rowNum 
 * @param {获取多少条} totalNum 
 */
function getMyArticleList(token = '', rowNum, totalNum){
    return request.get(`/v4ahb/list/${rowNum}/${totalNum}`,token);
}

/**
 * 修改文章
 * @param {*} articleId 
 * @param {*} iconUrl 
 * @param {*} title 
 * @param {*} content 
 * @param {*} token 
 */
function editorArticle(articleId, title, content, token){
    let params = {
        title: title,
        content: content
    }
    return request.post(`/v4ahb/update/${articleId}`, params, token);
}
/**
 * 删除文章
 * @param {*} articleId 
 * @param {*} token 
 */
function deleteArticle(articleId, token){
    return request.post(`/v4ahb/delete/${articleId}`,{}, token)
}
/**
 * 获取信息源
 * @param {*} token 
 */
function getInformationSourcesList(token){
    return request.get(`/informationSources/list`, token);
}
/**
 * 搜索信源
 * @param {*} like 
 * @param {*} token 
 */
function seachInformationSourcesList(like, token){
    return request.post(`/informationSources/like`, {name: like}, token);
}

/**
 * 移除信源
 * @param {*} id 
 * @param {*} token 
 */
function deleteInformationSources(id, token){
    return request.post(`/informationSources/delete`,{id: id}, token);
}
/**
 * 添加信源
 * @param {*} id 
 * @param {*} token 
 */
function addInformationSources(id, token){
    return request.post(`/informationSources/add`,{id: id}, token)
}
/**
 * 上传文件到服务器
 * @param {*} file 
 * @param {*} token 
 */
function upLoadFile(file, token, flag = 0, progress = null){
    let formData = new FormData();
    formData.append("file", file);
    return request.upload(`/v4f/up`, formData, token, progress);
}
// 第三方授权
/**
 *  获取已授权列表
 */
function getAuthorizeList(token = ''){
    return request.get('/list', token)
}
/**
 * 改版文章保存
 * {'articleId':'文章ID(为0是保存 其他为修改)',
 * 'title':'标题(必须)',
 * 'iconUrl':'封面链接(必须)',
 * 'articleContent':'文章内容(html 必须)',
 * 'type':'WX(微信类型) 保存类型(必须)',
 * 'isIcon':'是否显示封面 默认为0 (0 不显示封面 1 显示封面)',
 * 'author':'作者',
 * 'articleBrief':'文章简介',
 * 'isDiscuss':'是否开启留言评论 默认为0 (0 不开启 1 开启)',
 * 'originalHref':'原文链接 默认为空字符串',
 * 'appId':'同步公众号APPID(为空不同步)'}
 */
function saveOrEditArticleNew(articleId, iconUrl, title, articleContent, token, isIcon, author, articleBrief, isDiscuss, originalHref, appId ){
    let params = {
        articleId: articleId,
        title: title,
        iconUrl: iconUrl,
        articleContent: articleContent,
        type: 'WX',
        isIcon: isIcon,
        author: author,
        articleBrief: articleBrief,
        isDiscuss: isDiscuss,
        appId: appId,
        originalHref: originalHref
    }
    if(!isIcon) delete params.isIcon;
    if(!author) delete params.author;
    if(!articleBrief) delete params.articleBrief;
    if(!isDiscuss) delete params.isDiscuss;
    if(!originalHref) delete params.originalHref;


    console.log(JSON.stringify(params))

    return request.post(`/acd/sa`, params, token);

}
// 获取授权链接
function getAuthorizeLink(token){
    return request.get(`/redict`, token);
}
// 获取上传文件id——预分片上传
function preSliceUploadFile(name, chunks, file, ext, token){
    return request.post(`/shard/pr`, {"name": name, "md5": md5(file), "chunks": chunks, "ext": ext}, token);
}
// 分片上传
function fileSliceUpload(uploadId, file, token, callback, fila, handleXhrProgressCallback){
    request.fileSliceUpload(uploadId, `/shard/up`, file, token, callback, fila, handleXhrProgressCallback);
}
// 根据ID获取文件信息
function selectUpLoadFileStatus(uploadId, token){
    return request.get(`/v4f/sele/${uploadId}`, token);
}
/**
 * 根据上传Id 获取上传文件内容
 * @param {*} uploadId 
 * @param {*} token 
 */
function getUpLoadFileContentById(uploadId, token){
    return request.get(`/v4f/word/${uploadId}`, null,  token);
}
/**
 * 多个音频合成
 */
function fusionAudios(list, token){
    return request.post(`/ff/combination`, {'list' : list}, token)
}
export default {
    fusionAudios,
    getUpLoadFileContentById,
    selectUpLoadFileStatus,
    fileSliceUpload,
    preSliceUploadFile,
    getAuthorizeLink,
    getAuthorizeList,
    saveOrEditArticleNew,
    getSearchInfoByUUID2,
    upLoadFile,
    getInformationSourcesList,
    seachInformationSourcesList,
    deleteInformationSources,
    addInformationSources,
    text2Voice,
    editorArticle,
    deleteArticle,
    getMyArticleList,
    saveDocument,
    channels7_24,
    list7_24,
    txtTran2Txt,
    getSubjectName,
    getSearchInfoByUUID,
    getHotList,
    search,
    reqCode,
    getQrCodeState,
    getQrLoginState,
    getPhoneCode,
    bindPhone,
    loginPhone
}