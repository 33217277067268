import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        isShowModal: false
    }
    render() {
        // var bg = {
        //     backgroundImage:'url('+ ImageSet.Tipbtn +')'
        // }
        console.log(this.props.isCircleTips + 'this.props.isCircleTips')
        return(
            <div 
                className="btn_tips"
                style={  this.props.isCircleTips ? {width:'50px'} : {}}
                // style={bg}
            >
                
                {
                    this.props.isCircleTips ?
                    <div 
                    className="tips_circle_txt"
                    onMouseOver={() => {
                        this.setState({
                            isShowModal: true
                        })
                    }}
                    onMouseOut={() => {
                        this.setState({
                            isShowModal: false
                        })
                    }}
                >
                    <div 
                        className="btn_tips_bg_circle"
                    >
                        <img src={ImageSet.editorCircle}/>
                    </div>
                    <div className="tips_circle_img">
                        <img src={this.props.imgUrl}/>
                    </div>
                    <div 
                        className="tips_circle_modal"
                        style={this.state.isShowModal ? {display:'block'} : {display:'none'}}
                    >
                        {this.props.txt || '无'}
                        <div x-arrow="" className="popper__arrow"></div>
                    </div>
                </div> : 
                <div>
                    <div className="btn_tips_bg">
                        <img src={ImageSet.Tipbtn}/>
                    </div>
                    <div className="btn_tips_main">
                        <div className="tips_img">
                            <img src={this.props.imgUrl}/>
                        </div>
                        <div className="tips_txt">
                        {this.props.txt}
                        </div>
                    </div>
                </div>
                }
                
                
            </div>
        )
    }
}
export default Index