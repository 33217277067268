import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    render() {
        return(
            <div 
                className="web_hot_box"
                onClick={() => {
                    this.props.propClick &&
                    this.props.propClick()
                }}
            >
               <div className="hot_box_img">
                   <img src={this.props.src}/>
               </div>
               <div className="hot_box_txt">
                    <div className="txt_title">
                    {this.props.title}
                    </div>
                    <div className="txt_text">
                    {this.props.content}
                        
                    </div>
               </div>
               <div className="hot_txt_source">
                   <div className="source_left">
                       <div>{this.props.time}</div>
                       <div>{this.props.source}</div>
                   </div>
                   <div className="source_right">
                       <img src={ImageSet.HotBlack}/>
                       <div>
                           {this.props.vulcanHot}+
                       </div>
                   </div>
               </div>
            </div>
        )
    }
}
export default Index