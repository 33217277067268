import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        isShowClose: false,
        isValueEmpty: false,
        inputValue: ''
    }
    render() {
        const {isHome, placeholderTxt, onChange, search, value} = this.props
        return(
            <div 
                className="search_input"
            >
              <input
                    onKeyUp={(e) => {
                        if(e.keyCode == 13){
                            search && search()
                            // if(isHome){
                            //     window.location.href = '/editor.html'
                            //     sessionStorage.setItem("home_to", 'true')
                            //     sessionStorage.setItem("home_word", e.target.value);
                            // }
                            
                        }
                    }}
                    onChange={(e) => {
                        onChange && onChange(e.target.value)
                        this.setState({
                            inputValue: e.target.value
                        })
                        this.setState({
                            isValueEmpty: false
                        })
                    }}
                    value={this.state.isValueEmpty ? '' : value}
                    placeholder={placeholderTxt} 
                    className="input_input"
                    type="txt"/>
                <img 
                    className="input_img"
                    src={ImageSet.Search}
                    onClick={() => {
                        // if(isHome){
                        //     window.location.href = '/editor.html'
                        //     sessionStorage.setItem("home_to", 'true')
                        //     sessionStorage.setItem("home_word", this.state.inputValue);
                        // }
                        search && search()
                    }}
                />
            </div>
        )
    }
}
export default Index