import React from 'react';
import {observer, inject} from 'mobx-react';
import {Button, Modal, ModalFooter, ModalBody, ModalTitle, ModalHeader} from 'react-bootstrap'
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
   state = {
       isShowModal: false,
   }

    render() {
        return(
            <div 
                className="type_all_box_main"
            >
                
                <div 
                    onClick={() => {
                            this.setState({
                                isShowModal: true
                        })
                    }}
                    style={{fontSize:'14px'}}
                >
                  {/* <img src={ImageSet.WordMore} style={{width:'15px'}}/> */}
                  {
                      this.props.typeHtml ?
                      this.props.typeHtml :
                      <img src={ImageSet.WordMore} style={{width:'15px'}}/>
                  }
                </div>

                <Modal 
                    show={this.state.isShowModal}  
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >   
                    {this.renderModal()}
                </Modal>
            </div>
        )
    }
    renderModal(){
        const {HotDataStore} = this.props.store
        return (
            <div className="type_all_modal_main" style={{background:'#fff'}}>
                       <div className="modal_main_title">
                       选择感兴趣的领域，俯瞰会为您找到更精准的热点事件
                       </div>
                       <div className="modal_main_content">
                            {
                                HotDataStore.hotTypes.map((item, index) => {
                                    return <div 
                                            className="content_box" 
                                            key={`type_modal${index}`}
                                            onClick={() => {
                                                this.filterType(item)
                                            }}
                                        >
                                            <img
                                            src={ImageSet[`type${index +1}`] || 'https://www.vulcandata.cn/_image_1_1/1.jpg'}
                                            className="box_img"
                                            />
                                            <div className="box_txt">
                                                {item}
                                            </div>
                                            {
                                                HotDataStore.hotTypesUser.indexOf(item) != -1 ?
                                                <img src={ImageSet.typeSelected}
                                                className="box_selected_img"/> :
                                                ""
                                            }
                                            {/* 选中蒙层 */}
                                            {
                                                HotDataStore.hotTypesUser.indexOf(item) != -1 ?
                                                <div className="box_selected_Cover"/> :
                                                ""
                                            }
                                    </div>
                                })
                            }
                       </div>
                       <div className="modal_main_line"></div>
                       <div className="modal_main_select">
                           <div 
                                className="select_left"
                                onClick={() => {
                                    this.setState({
                                        isShowModal: false
                                    })
                                }} 
                            >
                               我再想想
                           </div>
                           <div className="select_right">
                               <div className="right_select">
                                   请选择3-5个领域
                               </div>
                               <div className="right_selected" style={{color:'#333'}}>
                                   已选择：
                                   <div className="selected_num" style={{color:'#ED6146'}}>
                                       {HotDataStore.hotTypesUser.length}个
                                   </div>
                               </div>
                               <div 
                                    className="right_ok"
                                    onClick={() => {
                                        this.setState({
                                            isShowModal: false
                                    })
                                }}
                               >
                                   完成
                               </div>
                           </div>
                       </div>
                    </div>
        )
    }
    filterType(name){
        // console.log(name)
        const {HotDataStore} = this.props.store
        if( HotDataStore.hotTypesUser.indexOf(name) != -1){// 已经存在
            const i = HotDataStore.hotTypesUser.indexOf(name)
            HotDataStore.hotTypesUser.splice(i, 1)
        } else {// 没有存在-判断数量
            var len = HotDataStore.hotTypesUser.length
            if(len >= 5){
                alert('只能添加3-5哦')
            } else {
                HotDataStore.hotTypesUser.push(name)
                // console.log(HotDataStore.hotTypesUser)
            }
        }
    }
   
  
}
export default Index