import React from 'react';
import {observer, inject} from 'mobx-react';
import {Modal, Toast, ToastHeader, ToastBody} from 'react-bootstrap'
import {Prompt, Link} from 'react-router-dom';
import TopBarCom from '../../component/topBar'
import SearchInputCom from '../../component/searchInput'
import HomeIntroCom from '../../component/homeIntro'
import WebHotBoxCom from '../../component/webHotBox'
import VulcanCarouselCom from '../../component/vulcanCarousel'
import UserAssessBoxCom from '../../component/userAssessBox'
import CooperationLinksCom from '../../component/cooperationLinks'
import VulcanBottomCom from '../../component/vulcanBottom'
import HomeTopCanvasCom from '../../component/homeTopCanvas'
import TypeAllBoxCom from '../../component/typeAllBox'
import ScrollBtnCom from '../../component/scrollBtn'
// import LoadingCom from '../../component/loading'
import LoadingCom from '../../component/loadingLinesWin'
import MaskCom from '../../component/maskBox'
import ImageSet from '../../utils/image'
import TestPage from '../HomeAnimation'
import { HandleContent, HandleDate, myBrowser, getRandom} from '../../utils/utils';
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    headerNavs = ['首页','热点','AI工具','我的文章','会员特权']
    webHotType = [
        '国际','体育','娱乐', '社会','财经','时事','汽车', '教育', '时尚', '美食', '文化', '健康养生'
    ]
    webHot = [1,1,1,1,1,1]
    state={
        isShowAni2: false,
        isTxtAni: false,
        activeIndex: -1,
        isValueEmpty: false,// 搜索词是否为空
        inputValue: '',// 输入的搜索词
        isDeepTopColor: false,
        isShowLoading: false,
        isToastChrome: false,
        isOpenFankui: false
    }
    scrollDir = 1// 大于0向下滚动
   render(){
    const {SearchStore, HotDataStore, userStore, ArticleStore} = this.props.store
    const {hotTypesUser} = HotDataStore
       return (
        <div className="home_page" >
            {/* 提示 */}
            <Toast
                delay={3000}
                autohide
                onClose={() => this.setState({
                    isToastChrome: false
                })}
                style={
                    this.state.isToastChrome ?
                    {
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex:'200'
                    }:
                    {display:"none"}
                    
                }
            >
                <ToastHeader>
                {/* <img 
                    src="holder.js/20x20?text=%20" 
                    className="rounded mr-2" 
                    alt="" 
                /> */}
                <strong className="mr-auto">通知</strong>
                {/* <small>just now</small> */}
                </ToastHeader>
                <ToastBody>推荐使用Chrome浏览器</ToastBody>
            </Toast>
            {/* 引入搜索全屏动画 */}
            {/* <LoadingCom 
                show={HotDataStore.hotListLoading}
            /> */}
            <div className="home_search_box">
                    <div
                        className={
                            this.state.isShowAni2 ?
                            'main-wrap--hide main-wrap':
                            'main-wrap'
                        }
                    >
                        {
                            this.renderHeader()
                        }
                    
                    </div>
                    <div 
                    className={
                        this.state.isShowAni2 ?
                        'search--open  search':
                        'search'
                    }
                    >
                        <div className="search__inner search__inner--up">
                            <div className="search_input_main">
                                <div className="search__form" action="">
                                    <input 
                                        className="search__input" 
                                        // name="search" 
                                        type="txt" 
                                        placeholder="请输入您想写的内容" 
                                        // autoComplete="off" 
                                        // autoCorrect="off" 
                                        // autoCapitalize="off" 
                                        // spellCheck="false"
                                        value={this.state.isValueEmpty ? '' : SearchStore.searchWord}
                                        onChange={(e) => {
                                            SearchStore.setSearchWord(e.target.value)
                                            this.setState({
                                                inputValue: e.target.value
                                            })
                                            this.setState({
                                                isValueEmpty: false
                                            })
                                        }}
                                        onKeyUp={(e) => {
                                            if(e.keyCode == 13){
                                                // 确定搜索
                                                ArticleStore.activeTitle =  e.target.value
                                                // console.log(ArticleStore.activeTitle + '--ArticleStore.activeTitle')
                                                // window.location.href = '/editor.html'
                                                this.props.history.push('/editor.html')
                                                sessionStorage.setItem("home_to", 'true')
                                                sessionStorage.setItem("home_word", e.target.value)
                                                SearchStore.searchAction()
                                            }
                                        }}
                                    />
                                    <span 
                                        className="search__info"
                                    >
                                       按回车，我们将智能为您匹配内容
                                    </span>
                                    <img 
                                        src={ImageSet.bigSearch}
                                        onClick={() => {
                                             // 确定搜索
                                            ArticleStore.activeTitle = this.state.inputValue
                                            // window.location.href = '/editor.html'
                                            this.props.history.push('/editor.html')
                                            sessionStorage.setItem("home_to", 'true')
                                            sessionStorage.setItem("home_word", this.state.inputValue);
                                            SearchStore.searchAction()
                                        }}
                                    />
                                </div>
                                
                            </div>
                            
                            <div 
                                onClick={() => {
                                    this.setState({
                                        isShowAni2: false
                                    })
                                    document.body.style.overflow = 'auto';
                                }}
                                className="search_close_main"
                            >
                                <img src={ImageSet.searchModalClose}/>
                            </div>
                        </div>
                        <div className="search__inner search__inner--down">
                            {/* 主要内容——搜索 */}
                            <div className="search__related">
                                <div className="search_inner_down_main">
                                    <div className="down_main_left">
                                        <div className="down_main_title">
                                            <span style={{fontSize:'25px'}}>欢迎</span>     
                                            开启【Vulcan俯瞰】智能聚合搜索！
                                        </div>
                                        <div>在这里，您可以</div>
                                        <div>
                                            检索全球
                                            <span className="left_main_big_txt">60</span>
                                            多个国家主流网站的信息素材
                                        </div>
                                        <div>
                                            实时获取全球
                                            <span className="left_main_big_txt">1000</span>家主流媒体最新资讯
                                        </div>
                                        <div>
                                            获得全球不同语言的
                                            <span className="left_main_big_txt">26</span>个分类热点推荐
                                        </div>
                                        <div>
                                            体验AI多语种信息的
                                            <span className="left_main_big_txt">秒级</span>切换翻译
                                        </div>
                                        <div>
                                            获得全网最新的
                                            <span className="left_main_big_txt">热点</span>资讯排行
                                        </div>
                                        <div style={{marginTop:'15px'}}>
                                            还等什么？输入你想了解的关键词吧.....
                                        </div>
                                    </div>
                                    <div className="down_main_line"></div>
                                    <div className="down_main_right">
                                        <div className="down_main_title">当前热搜</div>
                                        <div className="right_hot_words">
                                            {
                                                this.webHotType.map((item, index) => {
                                                    return <div
                                                        className="hot_words_txt"
                                                        key={`hot_words_txt${index}`}
                                                        style={{
                                                            color: `rgba(${getRandom(0,255)}, ${getRandom(0,255)}, ${getRandom(0,255)},1)`,
                                                            fontSize:getRandom(17,30) + 'px',
                                                            
                                                        }}
                                                        onClick={() => {
                                                            SearchStore.setSearchWord(item)
                                                            this.props.history.push('/editor.html')
                                                            sessionStorage.setItem("home_to", 'true')
                                                            sessionStorage.setItem("home_word", item)
                                                            SearchStore.searchAction()
                                                        }}
                                                    >
                                                        {item}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            {/* 头部 */}
            {/* <div className="home_page_header">
                <div className="header_bg_img">
                    <img src={ImageSet.HomeBanner}/>
                </div>
                <div className="head_main">
                    <TopBarCom showTxt="首页" isHome="true"/>
                    <div className="main_intro">
                        <div className="intro_title">
                        智能匹配·只为内容生产者 
                        </div>
                        <div className="intro_txt">
                        内容挖掘、撰写、管理、分发、追踪，助您高效有力
                        </div>
                    </div>
                    <div className="main_search">
                        <div className="search_main">
                        <SearchInputCom 
                            isHome="true" 
                            placeholderTxt="请输入你想写的内容或标题"
                            // search={() => {
                            //     SearchStore.searchAction()
                            // }}
                            // onChange={(word) => {
                            //     SearchStore.setSearchWord(word)
                            // }}
                            // value={SearchStore.searchWord}
                        />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* 新手教程 */}
            <div className="home_page_intros">
                <div className="intros_box">
                    <div className="box_img">
                        <img src={ImageSet.introduce4}/>
                        <img 
                            src={ImageSet.homePlay} 
                            className="intros_box_play_btn"
                            style={{
                                width:'40px'
                            }}
                        />
                    </div>
                    <div 
                        // className="box_txt"
                        className={
                            this.state.isTxtAni ?
                            'come-in box_txt' :
                            'box_txt'
                        }
                    >
                        <HomeIntroCom title='新手教程' content='如果您想继续了解我们，请点击左侧按钮或者下方的查看视频' moreTxt='查看视频  '/>
                    </div>
                    
                </div>
                <div className="intros_box">
                    <div 
                        // className="box_txt"
                        className={
                            this.state.isTxtAni ?
                            'come-in box_txt' :
                            'box_txt'
                        }
                    >
                        <HomeIntroCom title='监控全网热点、介绍' content='数据服务范围涵盖纸媒、网站、微信、微博、客户端、论坛，包括全国公开发行的纸媒、新闻资讯网站、头部优质微信号、微博号、移动新闻客户端等数据源。' moreTxt='查看详情'/>
                    </div>
                    <div className="box_img">
                    <img src={ImageSet.introduce1}/>
                    </div>
                </div>
                <div className="intros_box">
                    <div className="box_img">
                    <img src={ImageSet.introduce3}/>
                    </div>
                    <div 
                        // className="box_txt"
                        className={
                            this.state.isTxtAni ?
                            'come-in box_txt' :
                            'box_txt'
                        }
                    >
                        <HomeIntroCom title='素材处理' content='文字抽条：将文本进行段落拆条，用户点击段落
图片合成：将选中或输入的文字和图片素材进行编辑和一键合成
音频拆条：通过语音识别将音频解析为文字段落，用户可拖拽相关音频段落合成新音频。
视频拆条：用户可拖拽相关视频段落合成新视频。' moreTxt='查看详情'/>
                    </div>
                </div>

                <div className="intros_box">
                    <div 
                        className="box_txt"
                        className={
                            this.state.isTxtAni ?
                            'come-in box_txt' :
                            'box_txt'
                        }
                    >
                        <HomeIntroCom title='跨语言文本解决方案' content='借助神经网络机器翻译技术 对37种语言、1332个语言方向、2300个情感主题、100亿+句/对超大规模平行语料的数据采集和分类，包括翻译、分词、情感分析、关键词抽取、热点聚类等算法，为用户深度处理跨语言文本提供解决方案。' moreTxt='查看详情'/>
                    </div>
                    <div className="box_img">
                    <img src={ImageSet.introduce2}/>
                    </div>
                </div>
            </div>
           
            {/* 实时全网热点 */}
            <div 
                className="home_page_web_hot"
                // style={{background :`url(${ImageSet.HomeHotBanner}) no-repeat` }}
            >
                {/* <div className="web_hot_bg">
                    <img src={ImageSet.HomeHotBanner}/>
                </div> */}
                <LoadingCom 
                    show={this.state.isShowLoading && HotDataStore.hotListLoading}
                />
                <div className="web_hot_main">
                    <div className="main_content">
                        <div className="hot_content_title">
                        [ 实 时 全 网 热 点 ]
                        </div>
                        <div className="hot_content_tabs">
                            <div className="tabs_left">
                                {/* {
                                    this.webHotType.map((item, index) => {
                                        return <div         className="tabs_box"
                                        key={`webHot${index}`}
                                        style={
                                            index == 0 ?
                                            {background:'linear-gradient(90deg,rgba(237,97,70,1) 0%,rgba(232,123,102,1) 100%)', color:'#fff'} : {}
                                        }
                                        >
                                        {item}
                                    </div>
                                    })
                                } */}
                                <div 
                                    className="tabs_box"
                                    style={
                                            this.state.activeIndex == -1 ?
                                            {background:'linear-gradient(90deg,rgba(237,97,70,1) 0%,rgba(232,123,102,1) 100%)', color:'#fff'} : {}
                                        }
                                    onClick={() => {
                                        HotDataStore.getHotTypeList()
                                            this.setState({
                                                activeIndex: -1,
                                                isShowLoading: true
                                            })
                                    }}
                                >
                                    推荐
                                </div>
                                {
                                    hotTypesUser.map((item, index) => {
                                        return <div 
                                                className="tabs_box"
                                                key={`webHot${index}`}
                                                style={
                                                    this.state.activeIndex == index ?
                                                    {background:'linear-gradient(90deg,rgba(237,97,70,1) 0%,rgba(232,123,102,1) 100%)', color:'#fff'} : {}
                                                }
                                            onClick={() => {
                                                HotDataStore.getHotTypeList('1', item)
                                                this.setState({
                                                    activeIndex: index,
                                                    isShowLoading: true
                                                })
                                            }}
                                        >
                                            {item}
                                        </div>
                                    })
                                }
                            </div>
                            <div className="tabs_right">
                                <div className="tabs_box tabs_box_more" onClick={() => {
                                    window.open('/hot.html','_self')
                                }}>
                                {/* <TypeAllBoxCom typeHtml={this.renderTypeMoreBtn()}/> */}
                                    更多
                                    <img src={ImageSet.ArrowRight}/>
                                </div>
                            </div>
                        </div>
                        <div className="hot_content_boxs">
                            {
                                HotDataStore.hotTypeList && HotDataStore.hotTypeList.map((item, index) => {
                                    return <div 
                                        className="content_box_list"
                                        key={`web_hot_box${index}`}
                                    >
                                        <WebHotBoxCom
                                            src={item.tagPic || 'https://www.vulcandata.cn/_image_1_1/2.jpg'}
                                            title={item.title || '无标题'}
                                            content={HandleContent(item.content) || '无内容'}
                                            source={item.author || '无'}
                                            keyWords={item.keyWord || ['无']}
                                            time={HandleDate(item.createTime) || '无'}
                                            vulcanHot={item.vulcaHot || '1245'}
                                            propClick={() => {
                                                if(userStore.isLogin()){
                                                    ArticleStore.activeTitle = item.title
                                                    ArticleStore.articleSaveStatus = false
                                                    ArticleStore.saveNum = 0
                                                    this.props.history.push('/editor.html')
                                                    HotDataStore.clickHotTitle(item.title)
                                                } else {
                                                    userStore.isShowModal = true
                                                }
                                                
                                            }}
                                        />
                                    </div>
                                })
                            }
                            {
                                HotDataStore.hotTypeList.length == 0 ?
                                <div 
                                    style={{
                                        color:'#333', 
                                        fontSize:'14px',
                                        textAlign:'center',
                                        width:'100%',
                                        letterSpacing:'5px'
                                    }}
                                >
                                    数据加载中.....
                                </div> :
                                ""
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* 合作伙伴 */}
            {/* <div className="home_page_partner">
                <div className="page_partner_title title_home">
                    合作伙伴
                </div>
                <div className="page_partner_main">
                <VulcanCarouselCom/>
                </div>
            </div> */}
            {/* 客户评价 */}
            <div 
                className="home_page_assess"
                style={{display:"none"}}
            >
                <div className="page_assess_title title_home">
                    客户评价
                </div>
                <div className="page_assess_box">
                    <div className="box_list">
                        <UserAssessBoxCom 
                            content="关于广播行业的融媒体发展方向，一直是我们在琢磨和探讨的一个话题。一方面是如何把听觉的价值发挥到极致，另一方面是如何把听和其他感官融合在一起。在与Vulcan俯瞰合作的过程中，我们找到了一些特别有价值的方向，Vulcan俯瞰的数据聚合和分析能力，以及在多类型数据处理方面的技术能力，让我们在媒体融合实践中的设想变成了现实，而且这些工具和能力随着版本的迭代变得越来越好用。"
                            iconUrl={ImageSet.type10}
                            source="中央广播电视总台央广经济之声"
                            user="刘志军"
                        />
                    </div>
                    <div className="box_list">
                        <UserAssessBoxCom
                            content="作为一个期货交易所，我们承担着监控市场风险、发布市场信息的职能。过往我们更多是通过媒体进行的单向信息传达。至于媒体的传播效果如何？受众是否获得并理解信息？我们还可以通过什么更好的方式将信息有效传递给受众？现在，Vulcan俯瞰帮助我们寻找到了答案。通过技术手段具备强大的数据加工能力、建立自己的融媒体态信息发布平台，通过数据监控了解信息传播的效果。通过技术赋能期货交易所的应用场景，Vulcan俯瞰做到了。"
                            user="董婷婷"
                            iconUrl={ImageSet.type8}
                            source="郑州商品交易所"
                        />
                    </div>
                   
                </div>
            </div>
            {/* 底部 */}
            <div 
                className="home_page_fixed_bottom"
                style={{width:'100%',marginTop:'50px'}}
            >
                {/* <CooperationLinksCom/> */}
                <VulcanBottomCom />
            </div>
        </div>
       )
   }
   renderTypeMoreBtn(){
    return <div style={{color:'#000'}}>
            更多
            <img src={ImageSet.ArrowRight} style={{width:'6px', marginLeft:'5px'}}/>
        </div>
    
   }
   renderHeader(){
       const {isShowAni2} = this.state
       return (
            <div className="home_page_header">
                <div className="header_bg_img">
                    {/* <img src={ImageSet.HomeBanner}/> */}
                    <TestPage/>
                </div>
                <div className="head_main">
                    <div></div>
                    <div className="header_main_comp">
                        <TopBarCom 
                            showTxt="首页" 
                            isHome="true"
                            isDeepColor={this.state.isDeepTopColor}
                            clickSearch={() => {
                                this.setState({
                                    isShowAni2: true
                                })
                                document.body.style.overflow = 'hidden';
                            }}
                        />
                    </div>
                    {/* <div className="main_intro">
                        <div className="intro_title">
                        智能匹配·只为内容生产者 
                        </div>
                        <div className="intro_txt">
                        内容挖掘、撰写、管理、分发、追踪，助您高效有力
                        </div>
                    </div> */}
                    {/* <div className="main_search">
                        <div className="search_main">
                        <SearchInputCom 
                            isHome="true" 
                            placeholderTxt="请输入你想写的内容或标题"
                            // search={() => {
                            //     SearchStore.searchAction()
                            // }}
                            // onChange={(word) => {
                            //     SearchStore.setSearchWord(word)
                            // }}
                            // value={SearchStore.searchWord}
                        />
                        </div>
                    </div> */}
                    <div className="head_main_scroll">
                        <ScrollBtnCom clickBtn={() => {this.scrollNext()}}/>
                    </div>
                </div>
            </div>
       )
   }
   
    //监听滚动事件
    componentDidMount() {
        const {HomeStore, HotDataStore, userStore} = this.props.store
        HotDataStore.getHotTypeList()
        HomeStore.getWindowHeight()
        const heig = HomeStore.windowHeight
        window.addEventListener('scroll',(e) => {
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                if(scrollTop > (heig - 200) && scrollTop < (heig - 150) && this.scrollDir < 0){
                    this.scrollPre()
                }
                
                if(scrollTop > 5 && scrollTop < 15 && this.scrollDir > 0){
                    this.scrollNext()
                }
                if(scrollTop == 0){
                    this.setState({
                        isDeepTopColor: false
                    })
                }
                if(scrollTop > 0){
                    this.setState({
                        isDeepTopColor: true
                    })
                }
        })
        if(myBrowser() != 'Chrome'){
            this.setState({
                isToastChrome: true
            })
        }

    }
     
    componentWillUnmount() {
        window.removeEventListener('scroll',()=>{})
    }
    // 滚动到下一屏
    scrollNext(){
        const {HomeStore} = this.props.store
        HomeStore.getWindowHeight()
        const heig = HomeStore.windowHeight
        const _that = this
        _that.setState({
            isTxtAni: true
        })
        const tim = setInterval(() => {
            
            if(document.documentElement){
                document.documentElement.scrollTop+=10
            }
            if(document.body){
                document.body.scrollTop += 10
            }
            var heightTop = document.documentElement.scrollTop || document.body.scrollTop;
            if(heightTop >= (heig -100)){
                clearInterval(tim)
                _that.scrollDir = -1
            }
        }, 10)
    }
    // 滚动到上一屏
    scrollPre(){
        const _that = this
        var scrollToptimer = setInterval(function () {
            var top = document.body.scrollTop || document.documentElement.scrollTop;
            var speed = top / 10;
            if (document.body.scrollTop!=0) {
                document.body.scrollTop -= speed;
            }else {
                document.documentElement.scrollTop -= speed;
            }
            if (top == 0) {
                clearInterval(scrollToptimer);
                _that.scrollDir = 1
            }
        }, 10); 
    }
}
export default Index