import React, {Component} from 'react';

import { observer , inject } from 'mobx-react';
import './index.scss';
import ImageSet from '../../utils/image'
@inject("store")
@observer
class Index extends Component{
    state={
       show: this.props.show ? this.props.show : false,
       title:null
    }

    render(){
        let xiaView = <img src=''></img>;
        
        let titleView = <div 
            className={this.props.left?"select_title_layout_left select_font_size" : "select_title_layout select_font_size"}
            onClick={() => {
                this.props.titleClick && this.props.titleClick()
            }}
        >
        {this.state.title ? this.state.title : this.props.title}
        <img src={ImageSet.dddSelect}/>
        </div>
        return(
            <div className="select_layout" style={this.props.style?this.props.style:{}} >
                {titleView}
                <div 
                    className="select_content_layout" 
                    style={this.state.show ? {display: 'block'} : {}}
                >
                    {
                        this.props.data ? this.props.data.map((item, index)=>{
                            let name = typeof item == 'string' ? item : item.tab;
                            return <div 
                                key={index} 
                                style={this.props.left ? {textAlign: 'left'} : {}} 
                                className="select_content_layout_div select_font_size"
                                onClick={
                                    ()=>
                                    this.clickItem2(item)
                                }
                            >
                                {name}
                            </div>
                        }):""
                    }
                </div>
            </div>
        )
    }

    componentWillReceiveProps(n, old){
        if(n.show != this.state.show){
            this.setState({
                show: n.show
            })
        }
    }
  
    clickItem2(item){
        this.props.action && this.props.action(item.key)
        if(item.tab != '全部'){
            this.setState({
                title:item.tab
            })
        } else{
            this.setState({
                title:item.title
            })
        }
        this.props.listClick && this.props.listClick()
    }
}
export default Index