import React from 'react';
import {observer, inject} from 'mobx-react';
import {Prompt, Link} from 'react-router-dom';
import TopBarCom from '../../component/topBar'
import MyArticleBoxCom from '../../component/myArticleBox'
import ConfrimModalCom from '../../component/confirmModal'
import ImageSet from '../../utils/image'
import { HandleContent, HandleDate, GetHourMinute} from '../../utils/utils';
import './index.scss'
@inject("store")
@observer
class Test extends React.Component{
    arr = [1, 1, 1, 1, 1, 1, 1, 1, 1,1,1,1,1,1,1,1,1,1,1,1,1,1]
    state={
        deleteArticle: false,
        deleteMuster: false,
        articleIdActive: ''
    }
    componentDidMount(){
        const {ArticleStore, userStore} = this.props.store
        // console.log(userStore.token)
        ArticleStore.setToken(userStore.token)
        ArticleStore.getMyArticleList()
    }
    render() {
        const {ArticleStore, userStore, EditorDraftStore} = this.props.store
        const {
            myArticleDataList
        } = ArticleStore
        return(
            <div className="my_article_page">
                <ConfrimModalCom
                    show={this.state.deleteMuster || this.state.deleteArticle}
                    content={
                        this.state.deleteArticle ?
                        "确定删除该文章":
                        '"确定删除文章集"'
                    }
                    confirm={
                        () => {
                            this.setState({
                                deleteMuster: false,
                                deleteArticle: false
                            })
                            // 删除本地列表
                            ArticleStore.myArticleDataList = myArticleDataList.filter((it, ind) => {
                                return it.articleId != this.state.articleIdActive
                            })
                            // 删除数据库中的数据
                            ArticleStore.deleteMyArticle(this.state.articleIdActive)
                        }
                    }
                    cancel={() => {
                        this.setState({
                            deleteMuster: false,
                            deleteArticle: false
                        })
                    }}
                />
                <div className="article_page_header">
                    <TopBarCom showTxt="我的文章"/>
                </div>
                <div className="article_page_main">
                    <div className="main_all_boxs">
                        {/* <div className="all_boxs_left">
                            <div className="left_main">
                                <div className="left_box_title">
                                    <div className="title_left">
                                        <span>1</span>个文章集
                                    </div>
                                    <div className="title_right">
                                        <img src='https://static.getgetai.com/base/pc-nuxt/19-10-30/img/9d7bef0.svg'/>
                                    </div>
                                </div>
                                <div className="left_box_sums">
                                    <div className="sums_one sums_selected">
                                        <div className="sums_top_txt">默认文章集</div>
                                        <div className="sums_bottom_txt">28个文章</div>
                                    </div>
                                    <div className="sums_one sums_others">
                                        <div className="others_left">
                                            <div className="sums_top_txt">文章集</div>
                                            <div className="sums_bottom_txt">0个文章</div>
                                        </div>
                                        <div 
                                            title="删除" 
                                            className="others_delete"
                                            onClick={() => {
                                                this.setState({
                                                    deleteMuster: true
                                                })
                                            }}
                                        >
                                            <img src={ImageSet.searchModalClose}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="all_boxs_right">
                            <div className="right_main">
                                <div className="right_box_add article_box">
                                    <div 
                                        className="box_content"
                                        onClick={() => {
                                            // 新建文章-跳转编辑页面，清空编辑器的内容
                                            // window.open('/editor.html','_self')
                                            this.props.history.push('/editor.html')
                                            // 新建文章——开启保存新文章
                                            ArticleStore.articleSaveStatus = false
                                            ArticleStore.saveNum = 0
                                            ArticleStore.activeTitle = ''
                                            ArticleStore.acticleContent = ''
                                        }}
                                    >
                                        <div className="box_add_main">
                                            <div className="add_img">+</div>
                                            <div className="add_txt">新建文章</div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    myArticleDataList &&
                                    myArticleDataList.length > 0 &&
                                    myArticleDataList.map((item, index) => {
                                        return <div 
                                            className="article_box"
                                            key={`articel_box${index}`}
                                        >
                                             {/* <Link 
                                                key={index} 
                                                to='/editor.html'
                                                onClick={() => {
                                                    ArticleStore.activeTitle = item.title
                                                    ArticleStore.articleSaveStatus = true// 更新文章
                                                    // ArticleStore.activeContent = item.articleContent
                                                    ArticleStore.activeId = item.articleId
                                                    
                                                }}
                                             > */}
                                                <div 
                                                    className="box_content"
                                                >
                                                    <MyArticleBoxCom
                                                        dropEditor={() => {
                                                            ArticleStore.activeTitle = item.title
                                                            ArticleStore.articleSaveStatus = true// 更新文章
                                                            ArticleStore.activeContent = item.articleContent
                                                            ArticleStore.activeId = item.articleId
                                                            // console.log(item.articleContent)
                                                        }}
                                                        deleteProp={() => {
                                                            this.setState({
                                                                deleteArticle: true,
                                                                articleIdActive: item.articleId
                                                            })
                                                        }}
                                                        content={item.articleContent || '无内容'}
                                                        title={item.title || '无标题'}
                                                        time={HandleDate(item.createDate)}
                                                    />
                                                </div>
                                            {/* </Link> */}
                                        </div>
                                    })
                                }
                                <div className="right_main_more_btn">
                                    <div
                                        onClick={() => {
                                            ArticleStore.getMoreMyArticleList()
                                        }}
                                    >点击加载更多...</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Test