import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    
    render() {
        const count = String(this.props.vulcanHot)
        return(
            <div 
                className="left_right_box"
                onClick={() => {
                    this.props.propClick &&
                    this.props.propClick()
                }}
            >
              <div className="box_content">
                  {/* <div className="content_img"> */}
                      {/* <img src="http://get-file.oss-cn-hangzhou.aliyuncs.com/es/images/e14fbf59891b11e99e49100501442af5_0.png?x-oss-process=image/resize,m_lfit,limit_1,h_300" id="img_img_img"/> */}
                      <img src={this.props.src}/>
                  {/* </div> */}
                  <div className="content_txt">
                     <div className="txt_title">
                        {this.props.title}
                     </div>
                     <div 
                        className="txt_content"
                        dangerouslySetInnerHTML={{__html: this.props.content}}
                     >
                        {/* {this.props.content} */}
                     </div>
                  </div>
              </div>
              <div className="box_others">
                  <div className="others_time">
                        <div>
                            {this.props.time}
                        </div>
                        <div className="others_time_source">
                            {this.props.source}
                        </div>
                  </div>
                  <div className="others_hot_count">
                      <img src={ImageSet.HotBlack} style={{marginRight:'5px', width:'10px'}}/>
                       {/* {this.props.vulcanHot}+ */}
                       
                       {
                           count.length <= 5 ?
                           count :
                           count.substring(0,count.length - 4) + '+'
                       }
                  </div>
              </div>
            </div>
        )
    }
    handleContent(data){
        var dataCon = data.split('<BR/>')
        return dataCon.concat(' ')
    }
    handleDate(date){
        var date1 = new Date(date)
        var date2 = new Date()
        const diff = date2.getTime() - date1.getTime()
        const diffDate = diff / (24 * 60 * 60 * 1000);// 天
        if(diffDate > 30){
            return parseInt(diffDate/30) + '月前'
        } else if (diffDate >= 1){
            return parseInt(diffDate) + '天前'
        } else if( diffDate > 0){
            if(parseInt(diffDate * 24) == 0){
                return parseInt(diffDate * 24 * 60) + '分钟前'
            }
            return parseInt(diffDate * 24) + '小时前'
        }
    }
}
export default Index