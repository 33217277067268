import React, {Component, Fragment} from 'react';
import './index.scss'

export default class Index extends Component{
    render(){
        return(
            <div 
            className={this.props.show ? "mask_layou" : "mask_none"} 
            onClick={this.props.click ? this.props.click : null}
            style={this.props.propStyle}
            >
                <div 
                    className="mask_content"
                    style={this.props.prop2Style}
                >
                    {this.props.children}
                </div>
            </div>
        )
    }
}