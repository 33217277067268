import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        hotUrl: ImageSet.HotOther,
        fireUrl: ImageSet.HotBlack
    }
    componentDidMount(){
        if(this.props.hotIndex == 0){
            this.setState({
                hotUrl: ImageSet.HotOne,
                fireUrl: ImageSet.FireOne
            })
        } else if(this.props.hotIndex == 1){
            this.setState({
                hotUrl: ImageSet.HotTwo,
                fireUrl: ImageSet.FireTwo
            })
        } else if(this.props.hotIndex == 2){
            this.setState({
                hotUrl: ImageSet.HotThree,
                fireUrl: ImageSet.FireThree
            })
        }
    }
    render() {
        const count = String(this.props.count)
        return(
            <div 
                className="left_mid_right_box"
                onClick={() => {
                    // this.props.history.push('/editor.html')
                    this.props.propClick &&
                    this.props.propClick()
                }}
            >
              <div className="box_left">
                  <img src={this.state.hotUrl}/>
              </div>
              <div className="box_mid">
              <img src={this.props.src || 'http://get-file.oss-cn-hangzhou.aliyuncs.com/es/images/e14fbf59891b11e99e49100501442af5_0.png?x-oss-process=image/resize,m_lfit,limit_1,h_300'}/>
              </div>
              <div className="box_right">
                  <div className="right_top">
                  {this.props.title || '无标题'}
                  
                  </div>
                  <div className="right_bottom">
                      <div className="bottom_time">
                       {this.props.time}
                      </div>
                      <div className="bottom_count">
                          <img src={this.state.fireUrl}/>
                         
                          {
                              count != 'undefined' ?
                              (
                                count.length <= 5 ?
                                count :
                                count.substring(0,count.length - 4) + '+'
                              ) : '12450+'
                          }
                      </div>
                  </div>
              </div>
            </div>
        )
    }
}
export default Index