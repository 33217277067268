import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import ImgTxtTitleCom from '../../component/imgTxtTitle'
import TopBarCom from '../../component/topBar'
import LeftMidRightBoxCom from '../../component/leftMidRightBox'
// import LoadingCom from '../../component/loading'
import LoadingCom from '../../component/loadingLinesWin'
import { HandleContent, HandleDate } from '../../utils/utils';
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    hotList = [1,2,3,4,5,6,7,8,9,1,1,1,1,1,1]
    scrollDom = ''
    componentDidMount() {
        if (this.scrollDom) {
          this.scrollDom.addEventListener("scroll", () => {
            this.onScroll(this);
          });
        }

        const {HotDataStore} = this.props.store
        HotDataStore.getHotList()
      }
     
      componentWillUnmount() {
        if (this.scrollDom) {
          this.scrollDom.removeEventListener("scroll", () => {
            this.onScroll(this);
          });
        }
      }
    render() {
        const {HotDataStore, ArticleStore} = this.props.store
        return(
            <div 
                className="hot_list_page"
                ref={e => this.scrollDom = e}
            >
                <LoadingCom 
                    show={HotDataStore.hotLoading}
                />
                <div className="hot_list_topbar">
                    <TopBarCom showTxt="热点"/>
                </div>
                <div className="hot_list_main">
                    <div className="main_title">
                        <ImgTxtTitleCom imgTitle={ImageSet.Hot} imgTxt="热门事件"/>
                    </div>
                    <div 
                        className="main_content"
                    >
                        {/* <div className="content_toolbar">
                            <div className="toolbar_top_name">排名</div>
                            <div className="toolbar_img">图片</div>
                            <div className="toolbar_txt">事件</div>
                        </div> */}
                        <div className="content_list">
                            {
                                HotDataStore.hotList.map((item, index) => {
                                    return <div 
                                        className="list_box"
                                        key={`hotList${index}`}
                                    >
                                        <LeftMidRightBoxCom 
                                        hotIndex={index} 
                                        title={item.title} 
                                        count={item.hot}
                                        time={HandleDate(item.createDate) || '无'}
                                        src={item.banner}
                                        propClick={() => {
                                            // this.props.history.push('/editor.html')
                                            HotDataStore.clickHotTitle(item.title)
                                            ArticleStore.activeTitle = item.title
                                            ArticleStore.articleSaveStatus = false
                                            ArticleStore.saveNum = 0
                                            window.open('/editor.html','_self')
                                        }}
                                        // history={this.props.history}
                                        />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
     
    onScroll() {
        const { clientHeight, scrollHeight, scrollTop } = this.scrollDom;
        const isBottom = clientHeight + scrollTop === scrollHeight;
        console.log(clientHeight, scrollHeight, scrollTop, isBottom);
    }
}
export default Index