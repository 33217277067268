import React from 'react';
import {observer, inject} from 'mobx-react';
import { OverlayTrigger, Tooltip, Button} from 'react-bootstrap'
import ImageSet from '../../utils/image'
import PreviewModalCom from '../previewModal'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        isShowCircle: false
    }
    imgs = [
        {
            'src': ImageSet.Translate,
            'txt': '翻译'
        },{
            'src': ImageSet.Quality,
            'txt': '质量检测'
        },{
            'src': ImageSet.Web,
            'txt': '全网发文'
        },{
            'src': ImageSet.Copy,
            'txt': '一键复制'
        },{
            'src': ImageSet.Ai,
            'txt': 'AI工具'
        },{
            'src': ImageSet.Preview,
            'txt': '预览'
        },
    ]
    render() {
        const {EditorStore, EditorDraftStore, ArticleStore, SendStore} = this.props.store
        return(
            <div className="animation_btn_li_main">
                <div
                    className={
                        this.state.isShowCircle ?
                        'btn_li_widget btn_li_widget_active' :
                        'btn_li_widget'
                    }
                >
                    <div className="btn_li_menu">
                        <div    
                            className="btn_li_toggle"
                            onClick={() => {
                                this.setState({
                                    isShowCircle: !this.state.isShowCircle
                                })
                                
                            }}
                        >
                            {/* <img src={
                                !this.state.isShowCircle ? ImageSet.tipsClose : ImageSet.tipsOpen
                                }
                            /> */}
                            <OverlayTrigger
                                key='left_tips'
                                placement='left'
                                overlay={
                                    <Tooltip id={`tips-left`}>
                                        {
                                            !this.state.isShowCircle ?
                                            '展开' :
                                            '收起'
                                        }
                                    </Tooltip>
                                }
                            >
                                <div 
                                    className="list_li_btn"
                                >
                                    <img src={
                                        this.state.isShowCircle ? ImageSet.tipsOpen : ImageSet.tipsClose
                                        }
                                    />
                                </div>
                            </OverlayTrigger>
                        </div>
                        <ul className="list_ul">
                            {/* {
                                this.imgs.map((item, index) => {
                                    return <li
                                        className="list_li"
                                        key={`list_li${index}`}
                                        onClick={() => {
                                            this.props.onClick &&
                                            this.props.onClick()
                                        }}
                                    >
                                        <img 
                                            src={item}
                                            className="list_li_img"
                                        />
                                    </li>
                                })
                            } */}
                            {
                                this.imgs.map((item, index) => {
                                    return <li
                                            className="list_li"
                                            key={`overlay${index}`}
                                            style={
                                                (index == 1 || index == 4 || index == 3 )?
                                                {opacity:'0.5',
                                                    cursor: 'not-allowed'} :{}
                                            }
                                        >
                                        <OverlayTrigger
                                            key={`left${index}`}
                                            placement='left'
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>
                                                    {item.txt}
                                                </Tooltip>
                                            }
                                            >
                                            {/* <div 
                                                className="list_li_btn"
                                                onClick={() => {
                                                    this.props.onProps &&
                                                    this.props.onProps()
                                                }}
                                            >
                                                <img 
                                                    src={item.src}
                                                    className="list_li_img"
                                                />
                                            </div> */}
                                            {
                                                index != 5 ?
                                                <div 
                                                    className="list_li_btn"
                                                    onClick={() => {
                                                        this.clickCirBtn(index)
                                                        EditorStore.setTipsTxt(item.txt)
                                                        if(item.txt == '全网发文'){
                                                            SendStore.setId(ArticleStore.activeId);
                                                            SendStore.setTitle(ArticleStore.activeTitle);
                                                            // if(item.title == '无标题') {
                                                            //     sendStore.setTitle('');
                                                            // }
                                                            SendStore.setContent(ArticleStore.activeContent);
                                                            // if(item.articleContent == '无内容') {
                                                            //     SendStore.setContent('');
                                                            // }
                                                        }
                                                    }}
                                                >
                                                    <img 
                                                        src={item.src}
                                                        className="list_li_img"
                                                    />
                                                </div> :
                                                <PreviewModalCom 
                                                imgUrl={ImageSet.Preview}       isCircleTips='true'
                                                />
                                            }
                                        </OverlayTrigger>
                                    </li>
                                })
                            }
                            {/* <li
                                onClick={() => {
                                    this.open_close()
                                }}
                            >1</li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li> */}
                        </ul>
                    </div>
                </div>
                {/* <div>
                    aksjdhkashdkajsdhkasjdhaskj
                </div>
                <div>
                    {
                        this.props.contentBox ? this.props.contentBox : ''
                    }
                </div>      */}
        </div>
        )
    }
    clickCirBtn(index){
        const {clickModal, closeModal} = this.props
        const { EditorDraftStore } = this.props.store
        if(index == 0 || index == 2){
            clickModal && clickModal()
            if(index == 0){
               // 翻译
                EditorDraftStore.isTimingTrans = true
                // EditorDraftStore.translateTxt()
            }
        } else {
            closeModal && closeModal()
        }
        // 一键复制
        // if(index == 3){
        //     this.copy('public-DraftEditor-content','innerHTML')
        // }

    }
    copy (className, attr) {
        let target = null;
        if (attr) {
            target = document.createElement('div');
            target.id = 'tempTarget';
            target.style.opacity = '0';
            if (className) {
                let curNode = document.querySelector('.' + className);
                var strHtml = curNode.innerHTML;
               
                // target.innerHTML = strHtml;
                target.innerHTML =`<div data-contents="true"><div class="" data-block="true" data-editor="1oifm" data-offset-key="fqlqm-0-0"><div data-offset-key="fqlqm-0-0" class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span data-offset-key="fqlqm-0-0"><span data-text="true">案发生法发顺丰</span></span></div></div><figure class="" data-block="true" data-editor="1oifm" data-offset-key="c5kca-0-0" contenteditable="false"><span class="rdw-image-alignment rdw-image-center"><span class="rdw-image-imagewrapper"><img src="https://static.getgetai.com/img/e953d8570a444b1fb6d22c3c472d2d29/20191211120714.png" style="height: auto; width: auto;"></span></span></figure><div class="" data-block="true" data-editor="1oifm" data-offset-key="86vcc-0-0"><div data-offset-key="86vcc-0-0" class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span data-offset-key="86vcc-0-0"><br data-text="true"></span></div></div></div>`
                document.body.appendChild(target);
            }
            
        }
        try {
            let range = document.createRange();
            console.log(strHtml)
            // console.log(target)
            range.selectNode(target);
            // 移除内容
            window.getSelection().removeAllRanges();
            // 添加新内容
            window.getSelection().addRange(range);
            document.execCommand('copy');
            alert('复制成功')
        } catch (e) {
            alert('复制失败')
        }
    
        if (attr) {
            // remove temp target
            target.parentElement.removeChild(target);
        }
    }
}
export default Index