import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import AnimationBtnLiCom from '../../component/animationBtnLi'
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    state = {
        isShow:false,
        isShowCircle: false
    }
    render() {
        return(
            <div 
                className="animation_fold"
            >
                 
                <div className="fold_cont_principal">
                    
                    <div className="fold_cont_central">
                        <div 
                            className={
                                this.state.isShow ?
                                'fold_cont_modal fold_cont_modal_active' :
                                'fold_cont_modal'
                            }
                        >
                            {/* 左侧 */}
                            <div className="fold_cont_photo">
                               {
                                   this.props.leftConent || '0000'
                               }
                            </div>
                            {/* 右侧弹窗 */}
                            <div className="fold_cont_text_ingredients">
                                <div className="fold_cont_over_hidden">
                                    氨基酸的换了卡实践活动卡接收到
                            
                                </div>
                                <div className="fold_cont_btn_open_dets">
                                    {/* <div    
                                        className="dets_txt"
                                        onClick={() => {
                                            this.open_close()
                                        }}
                                    /> */}
                                    <div className="fold_open_dets_btns">
                                        {
                                            <AnimationBtnLiCom onClick={
                                                ()=>{
                                                    this.open_close()
                                                }
                                            }/>
                                        }
                                    </div>
                                </div>

                            </div>



                        </div>

                    </div>
                </div>                 
            </div>
        )
    }
    open_close(){
        this.setState({
            isShow: !this.state.isShow
        })
    } 
    
}
export default Index