import React ,{Component} from 'react';
import './index.scss';
/**
 *  网络加载无序进度条
 */
export default class index extends Component{
    render(){
        return(
                <div className={this.props.show ? "main loading_layou" : "loading_none"}>
                    <div className="loading_fixed_box">
                        <div className="loader">Loading...</div>
                    </div>
                </div>
            
            )
                
        
    }
}