import React from 'react';
import {observer, inject} from 'mobx-react';
import HomeTopCanvasCom from '../../component/homeTopCanvas'
import TopBarCom from '../../component/topBar'
import AudioSplitCom from '../../component/audioSplit'
import TimeLineCom from '../../component/timeLineBox'
import ImageSet from '../../utils/image'
import AddSplit2Page from '../AddAduioSplit2'
import AudioSplitVideoCom from '../../component/audioSplitVideo'
import './index.scss'
@inject("store")
@observer
class Test extends React.Component{
    audioLis = [1,1,1]
    audioSplit = null
    audioContent = null
    state = {
        audioAllTxt: [],
        activeIndex: 0,
        openDrggPage: false 
    }
    render(){
        const { asStore } = this.props.store
        const { adapterList } = asStore
        return <div className="AI_page">
            <div className="ai_page_topbar">
                <TopBarCom showTxt="AI工具"/>
            </div>
            <div className="ai_page_audio">
                {
                    !this.state.openDrggPage 
                    ? this.renderSplitOne()
                    : <AddSplit2Page
                        closeDrgg={
                            () => {
                                this.setState({
                                    openDrggPage: false
                                })
                            }
                        }
                    />
                }
            </div>
        </div>
    }
    renderSplitOne(){
        const that = this;
        const { asStore } = this.props.store
        const { adapterList, tagsList} = asStore
        return (<div className="render_split_one">
                    <div className="audio_left">
                            <div className="left_title">
                                <div>音频库</div>
                                <div onClick={that.clickAddFile.bind(this)}>
                                    添加
                                    <input accept="audio/mpeg" type="file" ref="audio" onChange={that.selectFile.bind(this)} style={{display: "none"}}/>
                                </div>
                            </div>
                            <div className="left_content">
                                {
                                    adapterList.length > 0 ?
                                    adapterList.map((item, index) => {
                                        return <div
                                            className="audio_list_content"
                                            key={`audio_list_${index}`}
                                            style={
                                                that.state.activeIndex == index ?
                                                {background:'rgba(140,140,140,.08)'} : {}
                                            }
                                            onClick={() => {
                                                if(this.state.activeIndex != index){
                                                    this.clickAudioName(index)
                                                    this.setState({
                                                        activeIndex: index
                                                    })
                                                }
                                                
                                            }}
                                        >
                                        {item.audiofile.name}
                                        {
                                            item.tagsList.length > 0 ?
                                            <span
                                                style={{
                                                    color: '#ED6146',
                                                    marginLeft:'5px',
                                                }}
                                            >
                                                ({item.tagsList.length})
                                            </span> :
                                            ""
                                        }
                                        </div>
                                    }) :
                                    <div className='left_content_empty'>
                                        请点击上方添加按钮，添加音频。
                                    </div>
                                }
                            </div>

                            {/* 合成按钮 */}
                            <div className="left_synthesis_btn">
                                <div
                                    className="synthesis_btn"
                                    style={
                                        tagsList.length == 0 ?
                                        {cursor: 'not-allowed', opacity:"0.6"} : {}
                                    }
                                    onClick={() => {
                                        if(tagsList.length != 0){
                                            that.setState({
                                                openDrggPage: true
                                            })
                                        }  
                                    }}
                                >
                                    合成
                                </div>
                            </div>
                    </div>
                    <div className="audio_right">
                        {/* 生成的音频拆条 */}
                        {
                            adapterList.length == 0 ?
                            <div className="one_audio_empty">
                                
                                空空如也
                                {/* <AudioSplitVideoCom/> */}
                            </div> :
                            that.renderAudioSplit()
                        }
                    </div>
            </div>)
    }
    renderAudioSplit() {
        const { asStore } = this.props.store
        const { adapterList } = asStore
        const that = this
        return(
            <div className="render_audio_split">
                
                <div className="ai_page_main">
                    <div 
                        className="audio_layout_content"
                        ref={ref => that.audioContent = ref}
                        // onScroll={(e) => {
                        //     that.audioContentScroll(e)
                        // }}
                    >
                        {
                            adapterList &&
                            adapterList.length > 0 &&
                            adapterList.map((item, index) => {
                                return <div
                                    key={`asStoreadapterList${index}`}
                                >
                                    <AudioSplitCom 
                                        adapter={item} 
                                        index={index} 
                                        segments={(item, fileId)=>{
                                            // 添加到合成列表
                                            asStore.addTag(item, fileId);
                                        }}
                                        deleteSegment={(item=>{
                                            // 在合成列表中删除
                                            asStore.deletaTag(item)
                                        })}
                                        addTagTxts={
                                            (item, fileId)=>{
                                                // 翻译后的文本添加到合成列表
                                                asStore.addAudioTxt(item, fileId)
                                            }
                                        }
                                    />
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
    componentDidMount(){
        const {asStore, userStore} = this.props.store;
        asStore.setToken(userStore.token)
    }
    // 添加音频
    clickAddFile(e){
        if(this.refs.audio) {
            this.refs.audio.click()
        }
    }
    // 选择音频-自动转为汉字
    selectFile(e) {
        const {asStore} = this.props.store;
        const { adapterList } = asStore;
        asStore.selectFile(e)
        const adapLen = adapterList.length
        if(adapLen > 0){
            setTimeout( () => {
                if(this.audioContent){
                    this.audioContent.scrollTop = adapLen * 600
                }
                this.setState({
                    activeIndex: adapLen
                })
            }, 100)
            
        }
    }
    // 点击音频列表
    clickAudioName(index){
        console.log(this.audioContent)
        if(this.audioContent){
            this.audioContent.scrollTop = index * 600
        }
    }
    // audioContentScroll(e){
    //     const eCone = e.currentTarget
    //     if(eCone.scrollTop){
    //         const Ind = parseInt(eCone.scrollTop / 600);
    //         this.setState({
    //             activeIndex: Ind
    //         })
    //     }
    // }
}
export default Test