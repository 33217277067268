import React from 'react'

export default class MediaBlock extends React.Component {
  render() {
      const { contentState, block } = this.props;
      let entity, type = "image", src, style; 
      if(block.getEntityAt(0)) {
        entity = contentState.getEntity(block.getEntityAt(0));
        type = entity.getType();
        src= entity.getData().src;
        style = entity.getData().style;
      } else {
        type = 'image'
      }
      
      if (type === 'image') {
        return (
          // <Image src={src} />
          <img alt=""
            src={src} 
            className="editor_work_img"
            title="点击图片有惊喜啊"
            style={{style}}
            // style={{width:'100px'}}
          />
        )
      } else if (type === 'video') {
        return (
          <div>This is a Video</div>
        )
      } else {
        return <img alt=""
          src={src} 
          className="editor_work_img"
          title="点击图片有惊喜啊"
          // style={{style}}
          style={{width:'100px'}}
        />
      }
  }
}