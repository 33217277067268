import React from 'react';
import {observer, inject} from 'mobx-react';
import TopBarCom from '../../component/topBar'
import ImgTxtTitleCom from '../../component/imgTxtTitle'
import TopBottomBoxCom from '../../component/topBottomBox'
import LeftRightBoxCom from '../../component/leftRightBox'
import PageNumberBoxCom from '../../component/pageNumberBox'
import VulcanBottomCom from '../../component/vulcanBottom'
import ActiveBoxUndelineCom from '../../component/activeBoxUndeline'
import CooperationLinksCom from '../../component/cooperationLinks'
import TypeAllBoxCom from '../../component/typeAllBox'
// import LoadingCom from '../../component/loading'
import LoadingCom from '../../component/loadingLinesWin'
import SelectOptionCom from '../../component/selectOption'
import ImageSet from '../../utils/image'
import { HandleContent, HandleDate, GetHourMinute} from '../../utils/utils';
import './index.scss'
@inject("store")
@observer
class Index extends React.Component{
    arr = [1, 2, 3,4,5,6,7,8,9,1]
    topics = ['最新', 'Vulcan值']
    links = ['21世纪经济报道', '经济观察报', '第一财经', '财经网', '财新网', '中国经济网']
    activeTypeTxt = ['国内', '国外']
    scrollNews24Dom = ''
    state = {
        isShowSelect: false,
        activeIndex: -1,
        filterVulcan: [],
        filterVulcanIndex: 0,
        isHoveringTypes: false,
        isForeign: false,
        isFiltering: false,
        isShowNews24Select: false,
        news24SelectTxt: '全部'
    }
    render() {
        const {HotDataStore, OverSeaStore, ArticleStore, userStore} = this.props.store
        const {
            hotTypesUser,
            hotTypeList, 
            hotListLoading,
            news_24_list,
            news_24_typesData,
            hotTypePageAll
        } 
        = HotDataStore
        const{seaDataList,overLoading, overseaAllPage} = OverSeaStore
        return(
            <div 
                className="hot_page"
            >
                <LoadingCom 
                    show={
                        this.state.isForeign ?
                        overLoading :
                        hotListLoading
                    }
                />
                <div className="hot_page_topbar">
                    <TopBarCom showTxt="热点"/>
                </div>
                <div className="page_main">
                    <div className="main_hot_content">
                        <ImgTxtTitleCom imgTitle={ImageSet.Hot} imgTxt="热门事件"/>
                        <div className="hot_content_more" 
                            onClick={() => {
                                // window.open('/hotList.html','_self')
                                if(userStore.isLogin()){
                                    this.props.history.push('/hotList.html')
                                } else {
                                    userStore.isShowModal = true
                                }
                            }}
                        >
                            全网热点榜
                            <img src={ImageSet.ArrowRight}/>
                        </div>
                        <div className="hot_content_main">
                        {
                           HotDataStore.hotList.slice(0,10).map((item, index) => {
                               return <div 
                                    key={`topB${index}`}
                                    className="hot_content_box"
                                >
                                   <TopBottomBoxCom
                                        count={item.hot}
                                        title={item.title} 
                                        // src={`https://www.vulcandata.cn/_image_1_1.8/${index + 1}.jpg`}
                                        propClick={() => {
                                            if(userStore.isLogin()){
                                                ArticleStore.activeTitle = item.title
                                                ArticleStore.articleSaveStatus = false
                                                ArticleStore.saveNum = 0
                                                this.props.history.push('/editor.html')
                                                HotDataStore.clickHotTitle(item.title)
                                            } else {
                                                userStore.isShowModal = true
                                            }
                                            
                                        }}
                                   />
                               </div>
                           })
                        }
                        </div>
                    </div>
                    <div className="main_type_commend">
                        <ImgTxtTitleCom imgTitle={ImageSet.TypeCommend} imgTxt="分类推荐"/>
                        <div className="main_type_commend_main">
                        <div className="type_commend_main">
                            <div 
                                className="commend_add_topic"
                                style={{
                                    cursor:'not-allowed',
                                    opacity:'.2'
                                }}
                            >
                                <img src={ImageSet.AddTopic}/>
                                订制专题
                            </div>
                            <div className="type_select">
                                <div className="select_country">
                                    <ActiveBoxUndelineCom 
                                        txts={this.activeTypeTxt}
                                        propClick={(name) => {
                                            if(name == '国内'){
                                                this.setState({
                                                    isForeign: false
                                                })
                                                HotDataStore.getHotTypeList('1',HotDataStore.hotTypeFilterName)
                                                // 是否存在筛选条件-vulcan值
                                                if(this.state.isFiltering){
                                                    this.filterDataNum(hotTypeList)
                                                }
                                               
                                            } else if(name == '国外'){
                                                this.setState({
                                                    isForeign: true
                                                })
                                                OverSeaStore.getOverPageList('1',HotDataStore.hotTypeFilterName)
                                                 // 是否存在筛选条件-vulcan值
                                                if(this.state.isFiltering){
                                                    this.filterDataNum(seaDataList)
                                                }
                                                
                                            }
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="topics_keyword">
                                <div className="keywords_main">
                                        <div 
                                            className="keywords keywords_active"
                                            style={
                                                this.state.activeIndex == -1 ?
                                                {background:"linear-gradient(90deg,rgba(237,97,70,1) 0%,rgba(232,123,102,1) 100%)", color: '#fff', border:'solid 1px rgba(237,97,70,1)'} : 
                                                {}
                                            }
                                            onClick={() => {
                                                if(this.state.isForeign){
                                                    OverSeaStore.getOverPageList()
                                                } else {
                                                    HotDataStore.getHotTypeList()
                                                }
                                                
                                                    this.setState({
                                                        activeIndex: -1
                                                })
                                            }}
                                        >
                                            推荐
                                        </div>
                                {
                                    hotTypesUser.map((item, index) => {
                                        return <div 
                                            className="keywords keywords_active"
                                            key={`topic_keyword${index}`}
                                            style={
                                                this.state.activeIndex == index ?
                                                {background:"linear-gradient(90deg,rgba(237,97,70,1) 0%,rgba(232,123,102,1) 100%)", color: '#fff', border:'solid 1px rgba(237,97,70,1)'} : 
                                                {}
                                            }
                                            onClick={() => {
                                                if(this.state.isForeign){
                                                    // console.log('asdasd')
                                                    OverSeaStore.getOverPageList('1', item)
                                                } else {
                                                    HotDataStore.getHotTypeList('1', item)
                                                }
                                                this.setState({
                                                    activeIndex: index
                                                })
                                            }}
                                        >
                                            {item}
                                        </div>
                                    })
                                }
                                <div
                                    style={{
                                        width:'30px',
                                        display:'flex',
                                        alignItems:'center',justifyContent:'center',
                                    }}
                                >
                                       <TypeAllBoxCom/>
                                </div>
                                </div>
                                <div 
                                   className="keyword_select_option_main"
                                >
                                     <SelectOptionCom
                                        propClick={(index) => {
                                            if(index == 1){
                                                let data = []
                                                if(this.state.isForeign){
                                                    data = seaDataList
                                                } else {
                                                    data = hotTypeList
                                                }
                                                // console.log(data)
                                                this.filterDataNum(data)
                                                this.setState({
                                                    isFiltering: true
                                                })
                                            } else if(index == 0){
                                                this.setState({
                                                    filterVulcan: [],
                                                    isFiltering: false
                                                })
                                            }
                                            
                                        }}
                                     />
                                </div>
                            </div>
                            {/* 主要内容 */}
                            <div className="commend_content">
                                {
                                    this.state.isForeign ?
                                    (
                                        seaDataList && seaDataList.length == 0 ?
                                        <div
                                            style={{
                                                width: '100%',
                                                textAlign: 'center',
                                                fontSize: '14px',
                                                color: '#aaa',
                                                marginTop: '20px'
                                            }}
                                        >
                                            无相关内容...
                                        </div> : ''
                                    ) :
                                    (
                                        hotTypeList && hotTypeList.length == 0 ?
                                        <div
                                            style={{
                                                width: '100%',
                                                textAlign: 'center',
                                                fontSize: '14px',
                                                color: '#aaa',
                                                marginTop: '20px'
                                            }}
                                        >
                                            无相关内容...
                                        </div> : ''
                                    )
                                }
                                {
                                    (
                                        this.state.filterVulcan.length > 0 ? 
                                        this.state.filterVulcan : 
                                        (
                                            this.state.isForeign ?
                                            (
                                                seaDataList && seaDataList.length > 0 ?
                                                seaDataList :
                                                []
                                            ):
                                            hotTypeList 
                                        )
                                    ).map((item, index) => {
                                            return <div className="comment_content_box" key={`content${index}`}>
                                                <LeftRightBoxCom
                                                    source={item.author}
                                                    src={item.tagPic || 'https://www.vulcandata.cn/_image_1_1/2.jpg'}
                                                    content={item.content || '无内容'}
                                                    time={HandleDate(item.createTime) || '无'}
                                                    title={item.title || '无标题'}
                                                    vulcanHot={item.vulcaHot || '1245'}
                                                    propClick={() => {
                                                        if(userStore.isLogin()){
                                                            ArticleStore.activeTitle = item.title
                                                            ArticleStore.articleSaveStatus = false
                                                            ArticleStore.saveNum = 0
                                                            this.props.history.push('/editor.html')
                                                            HotDataStore.clickHotTitle(item.title)
                                                        } else {
                                                            userStore.isShowModal = true
                                                        }
                                                        
                                                    }}
                                                />
                                            </div> 
                                    })
                                }
                                
                            </div>
                        </div>
                        <div className="type_commend_page_num">
                            <PageNumberBoxCom 
                                isForeign={this.state.isForeign}
                                propClick={(pageNum) => {
                                    if(this.state.isForeign){
                                        OverSeaStore.getOverPageList(pageNum, OverSeaStore.typeName)
                                    } else {
                                        HotDataStore.getHotTypeList(pageNum,HotDataStore.hotTypeFilterName)
                                    }
                                   
                                    // console.log(HotDataStore.hotTypeFilterName)
                                }}
                                allPage={!this.state.isForeign ? hotTypePageAll : overseaAllPage}
                            />
                        </div>
                        </div>
                    </div>
                    {/* 7*24h */}
                    <div 
                        className="main_7_24_news"
                        style={
                            userStore.isLogin() ?
                            {}:
                            {display:'none'}
                        }
                    >
                        <ImgTxtTitleCom imgTitle={ImageSet.news24} imgTxt="24h财经资讯"/>
                        <div className="main_7_24_news_select">
                            <div 
                                className="selects_show"
                                onClick={() => {
                                    this.setState({
                                        isShowNews24Select: !this.state.isShowNews24Select
                                    })
                                }}
                            >
                               {this.state.news24SelectTxt}
                                <img src={ImageSet.ArrowDown}/>
                            </div>
                            <div 
                                className="selects_options"
                                style={
                                    this.state.isShowNews24Select ?
                                    {} :
                                    {display:'none'}
                                }
                            >
                                {
                                    news_24_typesData &&
                                    news_24_typesData.length > 0 &&
                                    news_24_typesData.map((item, index) => {
                                        return <div
                                            key={`news_24_type${index}`}
                                            className={
                                                this.state.news24SelectTxt == item.name ?
                                                'news_24_type news_24_type_active' :
                                                'news_24_type'
                                            }
                                            onClick={() => {
                                                this.setState({
                                                    isShowNews24Select: false,
                                                    news24SelectTxt: item.name
                                                })
                                                HotDataStore.news_24_newTypeData(item)
                                                this.scrollNews24Dom.scrollTop = 0
                                            }}
                                        >
                                            {item.name || '无'}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="main_7_24_news_main">
                            <div 
                                className="news_main_info"
                                ref={e => this.scrollNews24Dom = e}
                            >
                                {
                                    news_24_list &&
                                    news_24_list.length > 0 &&
                                    news_24_list.map((item, index) => {
                                        return <div
                                            className="news_24_list"
                                            key={`news_24_list${index}`}
                                        >
                                            <div className="news_24_list_top">
                                                <div className="top_fixed_btn">
                                                    <img src={ImageSet.circle}/>
                                                </div>
                                                <div className="top_time">
                                                    {GetHourMinute(item.createDate) || '00:00'}
                                                </div>
                                            </div>
                                            <div className="news_24_list_bottom">     
                                            {item.content || '无'}
                                            </div>
                                        </div>
                                    })
                                }
                                <div className="news_24_more_btn">
                                    <div
                                        onClick={() => {
                                            HotDataStore.news_24_moreData()
                                        }}
                                    >点击加载更多</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 友情链接 */}
                <div className="page_other_links">
                     {/* <CooperationLinksCom/> */}

                </div>


                <div className="page_vulcan_bottom">
                   <VulcanBottomCom/>
                </div>
            </div>
        )
    }
    componentDidMount(){
        this.init()
    }
    
    onScroll() {
        const { clientHeight, scrollHeight, scrollTop } = this.scrollNews24Dom;
        
    }
    init(){
        const {HotDataStore,OverSeaStore} = this.props.store
        HotDataStore.getHotList()
        HotDataStore.getHotTypeList()
        HotDataStore.news_24_loadTypes()
    }
    filterDataNum(data){
        const _that = this
        if(data.length == 0){
            return
        } else {
           data = data.sort((a, b) => {
               return  b.vulcaHot - a.vulcaHot
           })
           _that.setState({
               filterVulcan: data
           })
        }
        
    }
}
export default Index