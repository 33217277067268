import React from 'react';
import {observer, inject} from 'mobx-react';
import ImageSet from '../../utils/image'
import './index.scss'
import {TimelineMax} from './TimelineMax.js'
import TweenLite, { TweenPlugin, Ease, Power0, Power1, Power2, Power3, Power4, Linear } from "./TweenLite.js";
@inject("store")
@observer
class Index extends React.Component{
    state = {
        viewBoxWidth : 0,
        viewBoxHeight : 0
    }
    r1= 200
    r2= 240
    str1= 28
    arc1= {
        startAngle: 10,
        endAngle: 240
    }
    arc2= {
        startAngle: 190,
        endAngle: 420
    }
    // 计算属性
    pathArc1 = this.describeArc(this.state.viewBoxWidth/2, this.state.viewBoxHeight/2, this.r1, this.arc1.startAngle, this.arc1.endAngle)
   

    pathArc2 = this.describeArc(this.state.viewBoxWidth/2, this.state.viewBoxHeight/2, this.r2, this.arc2.startAngle, this.arc2.endAngle)
    outerRad = this.state.viewBoxWidth / 1.4

    componentDidMount(){
        this.setViewbox()
        this.animateAll()
        
        
    }
    render() {
        return(
            <div className="home_top_canvas_main">
                <div className="screen-first">
                    {/* 背景图片 */}
                    {/* <div id="bg" className="bg"></div> */}

                    {/* 动画 */}
                   
                    <svg 
                        id="frontMaskSvg" 
                        className="front"  
                        preserveAspectRatio="none"
                        viewBox={`0 0 400 400`}
                    >

                        <defs>
                            <mask id="frontMask" x="0" y="0" width="100%" height="100%">

                                <rect x="0" y="0" width="100%" height="100%" fill="#000"/>
                                <path 
                                    id="arc1"  
                                    fill="none" 
                                    stroke="#000" 
                                    d={this.pathArc1}
                                    strokeWidth={this.str1}
                                />
                                <path 
                                    id="arc2"  
                                    fill="none" 
                                    stroke="#000" 
                                    strokeWidth="18" 
                                    d={this.pathArc2}
                                />
                                
                                <circle 
                                    id="c2" 
                                    r="214" 
                                    fill="#000"
                                    // cx={this.state.viewBoxWidth/2}
                                    // cy={this.state.viewBoxHeight/2}
                                    cx="200"
                                    cy="400"
                                />
                                <circle 
                                    id="c1" 
                                    r="186" 
                                    fill="#fff"
                                    // cx={this.state.viewBoxWidth/2}
                                    // cy={this.state.viewBoxHeight/2}
                                    cx="200"
                                    cy="400"
                                />

                            </mask>
                        </defs>

                        <rect mask="url(#frontMask)" x='0' y='0' width="100%" height="100%" fill="#fff"/>

                    </svg>
                        
                </div>
            </div>
        )
    }
    // 动画
    setViewbox() {// --ok
        this.setState({
            viewBoxWidth : Math.floor( document.documentElement.clientWidth),
            viewBoxHeight : Math.floor(document.documentElement.clientHeight)
        })
    }
    
    setViewboxDebounced() {
        return this.debounce(this.setViewbox, 200)
    }
    
    animateAll() {
        let vm = this
        const tl = new TimelineMax()
        
        let arc1point = {
            start: vm.arc1.endAngle,
            finish: 360 + vm.arc1.startAngle - .01
            // -0.01 is to not close the arc - it desappears other way
        }
        
        let arc2point = {
            start: vm.arc2.endAngle,
            finish: vm.arc2.startAngle
        }
        console.log(arc1point)
        console.log(arc2point)
        console.log(tl)
        tl.set(['#c1', '#c2'], { autoAlpha: 0 })
        
        .set('#frontMaskSvg', {autoAlpha: 1})
        
        .set({}, {}, 3) // fake pause before playing
        
        .add('startMotion')
        
        .to(arc1point, 1, {
            start: arc1point.finish,
            ease: Power4.easeInOut,
            onUpdate() {
                vm.arc1.endAngle = arc1point.start
            }
        }, 'startMotion')
        
        .to('#arc1', 0, { autoAlpha: 0 })
        .to('#c1', 0, { autoAlpha: 1 })
        
        .to(arc2point, 1, {
            start: arc2point.finish,
            ease: Power4.easeInOut,
            onUpdate() {
                vm.arc2.endAngle = arc2point.start
            }
        }, 'startMotion')
        .to('#arc2', 0, { autoAlpha: 0 })
        .to('#c2', 0, { autoAlpha: 1 })
        
        .add('outMotion')
        
        .to('#c1', .75, {
            ease: Power4.easeInOut,
            attr: {
                r: 0
            }
        }, 'outMotion')
        .to('#c2', .75, {
            ease: Power4.easeInOut,
            attr: {
                r: vm.outerRad
            }
        }, 'outMotion')
        
        .add('bgMotion')
        
        .fromTo('#title', 1, {
            x: 150
        }, {
            ease: Power2.easeInOut,
            x: 0,
            color: '#fff'
        }, 'outMotion')
        
        // turn off the whole svg
        .to('#frontMaskSvg', 0, { 
            autoAlpha: 0,
            onComplete() {
                console.log('结束绘制')
                // window.removeEventListener('resize', vm.setViewboxDebounced)
            }
        })
        
        .to('#bg', 12, {
            scale: 1,
            ease: Power1.easeOut
        }, 'bgMotion-=.5')
        
    }
    // 公用-绘制
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
      
        return {
          x: centerX + (radius * Math.cos(angleInRadians)),
          y: centerY + (radius * Math.sin(angleInRadians))
        };
      }
      
    describeArc(x, y, radius, startAngle, endAngle){
      
          var start = this.polarToCartesian(x, y, radius, endAngle);
          var end = this.polarToCartesian(x, y, radius, startAngle);
      
          var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
      
          var d = [
              "M", start.x, start.y, 
              "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
          ].join(" ");
      
          return d;       
      }
      
    debounce(func, wait, immediate) {
          var timeout
          return function() {
              var context = this, args = arguments
              var later = function() {
                  timeout = null
                  if (!immediate) func.apply(context, args)
              };
              var callNow = immediate && !timeout
              clearTimeout(timeout)
              timeout = setTimeout(later, wait)
              if (callNow) func.apply(context, args)
          }
    }
    
}
export default Index